import { roleModels } from './../../models/usersModels/role.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: "root"
})

export class rolesApiService {

  private base_url = environment.auth_url;
  httpOptions: any;

  constructor(private http: HttpClient, private storage: StorageService) {
    
  }

  private apiUrl = '';

  public get(id?: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    if(id !=  undefined){
      this.apiUrl  = this.base_url+"role?id="+id;
    }
    else{
      this.apiUrl = this.base_url+'role';
    }
    return this.http.get<any>(this.apiUrl, this.httpOptions);
  }

  public delete(id:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.auth_url+'role/delete?id='+id, this.httpOptions);
  }

  public status(id:number, status:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.auth_url+'role/inactive/'+id+'/'+status, this.httpOptions);
  }

  getFilter(data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(environment.auth_url+"role", this.httpOptions);
  }

  getRoleUser(userId: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.get<any>(environment.auth_url+"role?userId="+userId, this.httpOptions );
  }

  public post(action: string, RequestPayload: roleModels): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    this.apiUrl = this.base_url+'role?action='+action;
    return this.http.post(this.apiUrl, RequestPayload, this.httpOptions );
  }
}
