import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root"
})

export class snackBar {
  snackBarRef: any;

  constructor(private router: Router, private _snackBar: MatSnackBar) {}

  openSnackBar(mensaje: string, accion: string) {
    this.snackBarRef = this._snackBar.open(mensaje, accion, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center'
    });
}

}