import { causalsModels } from '../../models/productsModels/causals.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserDataService } from '../user-data.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: "root"
})

export class causalApiService {
  private base_url2 = environment.odc_url;
  private apiUrl: string ='';
  userId:string = '';
  gln_cadena : string = '';
  gln_proveedor : string = '';
  httpOptions: any;
  constructor(private http: HttpClient, private userData : UserDataService, private storage: StorageService) {
    this.userData.getData$()
    .subscribe((data:any)=>{
      this.userId = data.userId;
      this.gln_cadena = data.gln_cadena;
      this.gln_proveedor = data.gln_proveedor;
    });
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
  }

  getFilter(data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    if(this.gln_proveedor == null){
      
      return this.http.get<any>(environment.odc_url+"asignarCausal?cadenaId="+this.gln_cadena, this.httpOptions);
    }
    else{
      return this.http.get<any>(environment.odc_url+"asignarCausal?cadenaId="+this.gln_cadena+"&proveedorId="+this.gln_proveedor, this.httpOptions);
    }
  }
  getFilterCausal(data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(environment.gnl_url+"causal", this.httpOptions);
  }
  public getCausales(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.get<any>(environment.gnl_url+"causal", this.httpOptions);
  }

  public get(id?: number, data?:any) {
    this.apiUrl = environment.gnl_url+'causal';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {data : data}};
    if(id){
      this.apiUrl  = environment.gnl_url+"causal?id="+id;
    }
    return this.http.get<any>(
      `${this.apiUrl}`, this.httpOptions
    ).pipe(tap((res: any) => res ));;
  }

  getProduct(){
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    if(this.gln_proveedor == null){
      return this.http.get<any>(this.base_url2+"asignarCausal?cadenaId="+this.gln_cadena, this.httpOptions);
    }
    else{
      return this.http.get<any>(this.base_url2+"asignarCausal?cadenaId="+this.gln_cadena+"&proveedorId="+this.gln_proveedor, this.httpOptions);
    }
    
  }

  public post(RequestPayload: causalsModels, action: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    RequestPayload.usuarioId = this.userId;
    return this.http.post(environment.gnl_url+'causal?action='+action, RequestPayload, this.httpOptions);
  }
  public delete(id:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.gnl_url+'causal/delete?id='+id, this.httpOptions);
  }

  public status(id:number, status:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.gnl_url+'causal/inactive/'+id+'/'+status, this.httpOptions);
  }

  public save(RequestPayload: causalsModels): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
      RequestPayload.usuarioId = this.userId;
    return this.http.post(this.base_url2+"AsignarCausal", RequestPayload, this.httpOptions);

  }
  public postMasive(RequestPayload: any): Observable<any> {
    let userId = this.userId;
    this.apiUrl = environment.gnl_url;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.post<string>(this.apiUrl+'causal/PostMasive/'+userId, RequestPayload , this.httpOptions);
  }
  getAlertEarly(filtro: any, action: string): Observable<any> {
    let strUrl: string = '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : filtro}};
      if(action == 'asignarCausal'){
        strUrl = this.base_url2+action+"?cadenaId="+this.gln_cadena+"&proveedorId="+this.gln_proveedor;
      }
      else if(action == 'aprobarCausal'){
        strUrl = this.base_url2+action+"?cadenaId="+this.gln_cadena;
      }
    return this.http.get<any>(strUrl, this.httpOptions);
  }
}
