import { auditModels } from './../../../models/audit/audit.module';
import { auditoriaApiService } from './../../../services/Auditoria/auditoriaApiService.module';
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from './../../../dialog-spiner/dialog-spiner.component';
import { catalogueApiService } from './../../../services/Provider/catalogueApiService.module';
import { Component, OnInit } from '@angular/core';
import { snackBar } from 'src/app/Utils/snackBar';
import * as XLSX from 'xlsx';
import { UserDataService } from 'src/app/services/user-data.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.css']
})
export class CatalogueComponent implements OnInit {
  result: string = '';
  file: any;
  currentDate: Date = new Date();
  dataString!:any;
  nameFile: string = "";
  dialogSpiner!: DialogSpinerComponent;
  access:number = 0;

  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private snack: snackBar,
    private catalogueApiService: catalogueApiService,
    private auditoriaApiService: auditoriaApiService,
    private dialog: MatDialog, private userData:UserDataService,
    private router: Router,
    private notificationsApiService : notificationsApiService) {

      this.userData.getAccess$()
      .pipe( takeUntil(this.ngUnsubscribe) )
      .subscribe((data:any)=>{
        this.access = data;
      });

      if(!(this.access & 2048)){
        this.router.navigate(['/home']);
      }
      this.dialogSpiner = new DialogSpinerComponent(this.dialog);
   }

  ngOnInit(): void {
  }

  onFileChange(ev: any) {
    let workBook: any = null;
    let jsonData = null;
    const reader = new FileReader();
    this.result = "";
    //console.warn(ev.target.files[0]);
    if(ev.target.files.length > 0){
      this.file = ev.target.files[0];
      this.nameFile = this.file.name;
      
      if(this.nameFile.indexOf('Plantilla-CargueProductos') === 0){
        reader.onload = () => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          //this.dataString = JSON.stringify(jsonData);
          this.dataString = jsonData;
        }
        reader.readAsBinaryString(this.file);
      }else{
        
        this.nameFile = "";
        $("#fileCarga").val("");
        this.result = "error: El nombre del archivo no es admitido";
      }

    }
    else{
      this.nameFile = "";
      $("#fileCarga").val("");
      this.result = "error: debe seleccionar un archivo de formato tipo XLSX";
    }
    
  }

  send(){
    this.guardar(this.dataString);
  }

  guardar(json: any){

    //console.warn(this.file);
    if(this.nameFile == ""){

      Swal.fire(
        {
          icon: ('error'),
          title: 'Archivo Faltante',
          text:  'Debe seleccionar un archivo para cargar los productos al sistema',
          confirmButtonText: "OK",
          confirmButtonColor: "#00B398", 
        }
      );
      return;
    }

    Swal.fire({
      title: '¿Desea cargar masivamente estos productos al sistema?',
      // text: 'Una vez realizada esta accion no se podra revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aceptar',
      cancelButtonText: 'No, olvidalo',
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
    if (result.isConfirmed) {



    let auditModels: auditModels = { 
      archivoNombre: this.nameFile,
      tamanio: this.file.size,
      registros: 0,
      estado: '',
      cadenaId: 0,
      cadena: '',
      urls: '',
      logs: '',
      tipoCargue: '1',
      usuarioId: '',
      fechaArchivo: new Date(),
      fechaCreacion: new Date(),
      fechaModificacion: new Date()
    };
    let errMsg : string = '';
    this.dialogSpiner.openDialog();
    this.catalogueApiService.PostMasiveProduct(json, auditModels).subscribe((catalogueResult:any) =>{
     
      //console.warn(catalogueResult);

      Swal.fire(
        {
          icon: (catalogueResult.resultObject[0] ? 'success':'error'),
          title: (catalogueResult.resultObject[0] ? 'Registros Cargados!':'Algo no esta bien'),
          text: catalogueResult.resultObject[0],
          confirmButtonText: "OK",
          confirmButtonColor: "#00B398", 
        }
      )

      this.dialogSpiner.closeDialog();

      if(catalogueResult.resultObject.length > 0){
        this.result = catalogueResult.resultObject[0].toString();
        this.notificationsApiService.InsertarNotificaciones('Carga Masiva de Productos','El Archivo de Productos '+this.nameFile+' ha sido cargado correctamente, '+catalogueResult.resultObject[0], 'provider');

      }
      else{
        this.snack.openSnackBar('Ha ocurrido un error al guardar la carga masiva de productos.', 'OK!');
        this.notificationsApiService.InsertarNotificaciones('Error al cargar el archivo de Carga Masiva de Productos','El Archivo de Productos '+this.nameFile+' no ha sido cargado, '+catalogueResult.resultObject[0], 'provider');
      }

    },
    (error: HttpErrorResponse) => {
      if(error.status == 400){

        var errorArray = Object.values(error.error.errors);
          
        for (var key in errorArray) {
          //console.log("Ocurrieron los siguietes errores: " + errorArray[key]);
          errMsg += errorArray[key] + ', ';
        }

        Swal.fire(
          {
            icon: ('error'),
            title: 'Existe información errónea o Faltante',
            text:  errMsg+ ' por favor ajustarlo antes de volverlo a cargar',
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
          }
        );

        this.snack.openSnackBar('Existe información errónea o Faltante dentro del archivo, por favor ajustarlo antes de volverlo a cargar '+ errMsg, 'OK!');
      }else{
        this.snack.openSnackBar('Ha ocurrido un error al guardar la información.', 'OK!');
      }
      this.dialogSpiner.closeDialog();
      this.snack.snackBarRef.afterDismissed();
    });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        {
          icon: 'error',
          title: "Cancelado",
          text: "Elementos no cargado",
          confirmButtonText: "OK",
          confirmButtonColor: "#00B398", 
      })
    }
  });
    this.nameFile = "";
    $("#fileCarga").val("");
    this.dataString = "";
  }

}
