import { userModels } from './../../models/usersModels/user.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import * as bcrypt from 'bcryptjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';



@Injectable({
  providedIn: "root"
})

export class usersApiService {
  private base_url = '';
  private apiUrl =  '';
  httpOptions: any;

  constructor(private http: HttpClient, private storage: StorageService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
   }

  public get(id?: string, data?:any): Observable<any> {
    this.base_url = environment.auth_url;
    this.apiUrl = this.base_url + 'users';
    if (id != undefined) {
      this.apiUrl = this.apiUrl + "?id=" + id;
    } 
    else if(data != undefined){
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Token': this.storage.getInSession('api_token')
        }),
        params: data};
      return this.http.get<any>(this.apiUrl,this.httpOptions);
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.get<any>(this.apiUrl, this.httpOptions);
    
  }

  getFilter(data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(environment.auth_url+"users", this.httpOptions );
  }


  public post(action: any, RequestPayload: userModels): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    this.base_url = environment.auth_url;
    this.apiUrl = this.base_url + 'users?action='+action;
    console.log(action);
    return this.http.post<any>(this.apiUrl,  RequestPayload, this.httpOptions);
  }

  public changePassword(Token: string, password: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': Token
      })};
    this.apiUrl =  environment.auth_url + 'users/ChangePassword/'+password;
    return this.http.get<any>(this.apiUrl, this.httpOptions);
  }

  public delete(id:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.auth_url+'users/delete?id='+id, this.httpOptions);
  }

  public status(id:number, status:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.auth_url+'users/inactive/'+id+'/'+status, this.httpOptions);
  }
}
