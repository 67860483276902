import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from '../dialog-spiner/dialog-spiner.component';
import { notificationMdels } from '../models/Notifications/notification.module';
import { snackBar } from '../Utils/snackBar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  dialogSpiner!: DialogSpinerComponent;
  notificationCount: string = '';
  count: number = 0;
  @Input() data!: Array<any>;
  dataSource: any = [];

  notificationsViewLocal: any = [];
  constructor(private notificationsApiService: notificationsApiService,
    public dialog: MatDialog, private snack: snackBar, private router: Router) {
    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
  }

  ngOnInit(): void {
    setTimeout(() => {
    }, 5000);

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes && this.data) this.notificationView(changes.data.currentValue);
  }

  getNotification() {

  }

  onclickViewNotification() {
    this.router.navigate(['/notification' + this.router.url]);
  }

  notificationView(data: any) {
    if (data != undefined) {
      this.count = 0;
      this.dataSource = [];
      this.notificationsApiService.notificationsView.forEach((element: any) => {
        let notificationItem = {
          title: element.titulo,
          msj: element.descripcion,
          url: element.url
        };
        this.dataSource.push(notificationItem);
        this.count += 1;
        if (this.count >= 99) {
          this.notificationCount = '99+';
        }
        else {
          this.notificationCount = this.count.toString();
        }
      });
    }
  }
}
