<div class="container">
    <div class="row">
        <div class="col">
            <p></p>
            <mat-card>
                <mat-card-title>
                   {{title}} Causal<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
                </mat-card-title>
                <app-bread-crumb></app-bread-crumb>
            </mat-card>
        </div>
        <p></p>
    </div>
    <div class="row">
        <div class="col">
            <mat-card>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label for="">Causal<span class="text-danger">*</span></label>
                        <input maxlength="255" [disabled]="accion == 'view'" class="form-control" [disabled]="isDisabled" [(ngModel)]="this.causalsModels.causal" #causal="ngModel"  required pattern="^[0-9a-zA-ZÀ-ÿ\u00f1\u00d1 ,-]+$"/>
                        <span *ngIf="causal.invalid && (causal.dirty || causal.touched)" class="text-danger">
                            <span *ngIf="causal.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="causal.errors?.pattern">
                                * El Campo no tiene caracteres no validos solo letras, espacio en blanco y números permitos.
                            </span>
                        </span>
                    </div>
                    <div class="form-group col-6">
                        <label for="">Descripción</label>
                        <input maxlength="255" [disabled]="accion == 'view'" class="form-control" [disabled]="isDisabled" [(ngModel)]="this.causalsModels.descripcion" #descripcion="ngModel"  required pattern="^[0-9a-zA-ZÀ-ÿ\u00f1\u00d1 ,-]+$"/>
                        <span *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)" class="text-danger">
                            <!-- <span *ngIf="descripcion.errors?.required">
                                * el Campo es requerido.
                            </span> -->
                            <span *ngIf="descripcion.errors?.pattern">
                                * El Campo no tiene caracteres no validos solo letras, espacio en blanco y números permitos.
                            </span>
                        </span>
                    </div>
                    <div class="form-group col-3">
                        <label for="">Asignado por<span class="text-danger">*</span></label>
                        <select [disabled]="accion == 'view'" class="form-select form-control" #asignadaPor="ngModel" [disabled]="isDisabled" [(ngModel)]="this.causalsModels.asignadaPor" required>
                            <option selected value="">Seleccione</option>
                            <ng-container *ngFor="let fila of listTypeEntity">
                                <option [selected]="fila === this.causalsModels.asignadaPor" value="{{fila}}">{{fila}}</option>
                            </ng-container>
                        </select>
                        <span *ngIf="asignadaPor.invalid && (asignadaPor.dirty || asignadaPor.touched)" class="text-danger">
                            <span *ngIf="asignadaPor.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="asignadaPor.errors?.pattern">
                                * El Campo no tiene caracteres no validos.
                            </span>
                        </span>
                    </div>
                    <div class="form-group col-3">
                        <label for="">Responsable<span class="text-danger">*</span></label>
                        <select class="form-select form-control" #responsable="ngModel" [disabled]="isDisabled" [(ngModel)]="this.causalsModels.responsable" required>
                            <option selected value="">Seleccione</option>
                            <ng-container *ngFor="let fila of listTypeEntity">
                                <option [selected]="fila === this.causalsModels.responsable" value="{{fila}}">{{fila}}</option>
                            </ng-container>
                        </select>
                        <span *ngIf="responsable.invalid && (responsable.dirty || responsable.touched)" class="text-danger">
                            <span *ngIf="responsable.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="responsable.errors?.pattern">
                                * El Campo no tiene caracteres no validos.
                            </span>
                        </span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="">Impacto al Indicador</label>
                        <textarea maxlength="255" class="form-control" [disabled]="isDisabled" cols="30" rows="3" [(ngModel)]="this.causalsModels.impactoIndicador" #impactoIndicador="ngModel" pattern="^[0-9a-zA-ZÀ-ÿ\u00f1\u00d1 ,-]+$"></textarea>
                        <span *ngIf="impactoIndicador.invalid && (impactoIndicador.dirty || impactoIndicador.touched)" class="text-danger">
                            <span *ngIf="impactoIndicador.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="impactoIndicador.errors?.pattern">
                                * El Campo no tiene caracteres no validos, solo letras, espacios en blanco y números.
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <span *ngIf="!causal.errors?.required &&
                                !asignadaPor.errors?.required && !responsable.errors?.required && !causal.errors?.pattern && !descripcion.errors?.pattern && !impactoIndicador.errors?.pattern &&
                                !asignadaPor.errors?.pattern && !responsable.errors?.pattern && accion != 'view'; else elseTemplate">
                        <button mat-raised-button type="submit" (click)="guardar();" [disabled]="false"  class="btn-primary">Guardar</button>
                    </span>
                    <ng-template #elseTemplate>
                      <button type="button" class="btn btn-primary" [disabled]="true" >Guardar</button>
                    </ng-template>
                    <span class="text-muted mx-2">|</span>

                    <button type="button" routerLink="/causal" class="btn btn-default">Regresar</button>
                  </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
