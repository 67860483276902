import { categoryApiService } from './../../services/Category/categoryApiService.module';
import { Router } from '@angular/router';
import { columns } from 'src/app/models/table-column';
import { menuOptions } from './../../models/menu.interface';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { snackBar } from 'src/app/Utils/snackBar';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { UserDataService } from 'src/app/services/user-data.service';
import { Subject } from 'rxjs';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  access:number = 0;
  data: any;
  eventFilter: any;
  
    // tablacolumns!: any;
    dialogSpiner: DialogSpinerComponent;

    tableMenu: menuOptions[] = [
      {
        icon : 'change_circle',
        label: 'Estado',
        show: (this.obtenerPermisos() & 128 ? true:false),
        callback: (parametro:any, data?:any) => {
          // this.router.navigate(['/causal/'+parametro+'/view/'])
          Swal.fire({
            title: 'Desea cambiar el estado de esta Causal?',
            // text: 'Una vez realizada esta accion no se podra revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, cambiarle el estado',
            cancelButtonText: 'No, olvidalo',
            confirmButtonColor: "#00B398", 
            cancelButtonColor: "#FC4C02"
          }).then((result) => {
            if (result.isConfirmed) {
              let varEstado = 2;
              if(data.estado == 'inactivo'){
                varEstado = 1;
              }
              this.categoryApiService.status(parametro, (varEstado))
              .subscribe((resultData:any) =>{
  
                if(resultData.resultObject[0].value.succeeded){
                 
                    this.notificationsApiService.InsertarNotificaciones('Cambio de estado del Causal nombre: ' + data.causal, 'El causal ha cambiado de estado a '+ (varEstado == 1 ? 'activo' : 'inactivo'), 'causal');
                }
                else{
                  this.notificationsApiService.InsertarNotificaciones('Error en el Cambio de estado del Causal Id:' + parametro, 'Ha ocurrido un error al cambiar el estado del causal a '+ (varEstado == 1 ? 'activo' : 'inactivo'), 'causal') + 'y no fue actualizado';
                }
                Swal.fire(
                  {
                    icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                    title: (resultData.resultObject[0].value.succeeded ? 'Estado cambiado!':'Algo no esta bien'),
                    text: resultData.resultObject[0].value.msg,
                    confirmButtonText: "OK",
                    confirmButtonColor: "#00B398", 
                  }
                )
                this.filterAdv(this.eventFilter);
              });
  
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                {
                  icon: 'error',
                  title: "Cancelado",
                  text: "Elemento no actualizado",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
              })
            }
          });
       }
      },
      {
        icon : 'delete_forever',
        label: 'Eliminar',
        show: (this.obtenerPermisos() & 8 ? true:false),
        // disabled:true,
        callback: (parametro:any) => {
          // this.router.navigate(['/causal/'+parametro+'/view/']);
  
          Swal.fire({
            title: 'Esta seguro que desea eliminar este elemento?',
            text: 'Una vez realizada esta acción no se podra revertir',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, eliminalo',
            cancelButtonText: 'No, mantenlo',
            confirmButtonColor: "#00B398", 
            cancelButtonColor: "#FC4C02"
          }).then((result) => {
            if (result.isConfirmed) {
              this.categoryApiService.delete(parametro)
              .subscribe((resultData:any) =>{
  
                if(resultData.resultObject[0].value.succeeded){
                    this.notificationsApiService.InsertarNotificaciones('Causal Id:' + parametro + ' Eliminado', 'El causal ha sido eliminado ', 'causal');
                }
                else{
                  this.notificationsApiService.InsertarNotificaciones('Error al eliminar el Causal Id:' + parametro, 'Ha ocurrido un error, el causal no fue eliminado', 'causal');
                }
  
                Swal.fire(
                  {
                    icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                    title: (resultData.resultObject[0].value.succeeded ? 'Causal eliminada!':'Algo no esta bien'),
                    text: resultData.resultObject[0].value.msg,
                    confirmButtonText: "OK",
                    confirmButtonColor: "#00B398", 
                  }
                )
                this.filterAdv(this.eventFilter);
              });
  
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                {
                  icon: 'error',
                  title: "Cancelado",
                  text: "Causal no eliminada",
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
              })
            }
          });
        },
       }
    ];

    columns: columns<any>[] = [
      {
        columnDef: 'item',
        header: 'Número',
        type: 'label',
        class: 'col-sm-1',
        excludeFilter: true,
        cell: (element: any) => `${element.item}`
      },
      {
        columnDef: 'productoId',
        header: 'GTIN Producto',
        type: 'label',
        class: 'col-sm-2',
        show : true,
        cell: (element: any) => `${element.productoId}`
      },
      {
        columnDef: 'producto',
        header: 'Descripción GTIN del producto',
        type: 'label',
        class: 'col-sm-3',
        show : true,
        cell: (element: any) => `${element.producto}`
      },
      {
        columnDef: 'categoriaId',
        header: 'Código Categoría UNSPSC',
        type: 'label',
        class: 'col-sm-2',
        show : false,
        cell: (element: any) => `${element.categoriaId}`
      },
      {
        columnDef: 'categoria',
        header: 'Descripción Categoría UNSPSC',
        type: 'label',
        class: 'col-sm-3',
        show : true,
        cell: (element: any) => `${element.categoria}`
      },
      {
        columnDef: 'estado',
        header: 'Estado',
        type: 'status',
        class: 'col-sm-2',
        show : true,
        cell: (element: any) => `${element.estado}`
      },
     
      // {
      //   columnDef: 'icon',
      //   header: 'icono de fontIcon',
      //   type: 'mat-icon',
      //   class: 'col-sm-1 p-2',
      //   cell: (element: any) => `${element.icon}`
      // }
      {
        columnDef: 'menu',
        header: 'Acciónes',
        type: 'menu',
        class: 'col-sm-2',
        excludeFilter: true,
        menu: this.tableMenu,
        cell: (element: any) => `${element.menu}`
      }
    ];

      titletable: string = 'Categorías';

  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private router: Router,
    private snack: snackBar, public dialog: MatDialog, private categoryApiService: categoryApiService, private userData:UserDataService,
    private notificationsApiService: notificationsApiService) { 
      this.obtenerPermisos();
      this.dialogSpiner = new DialogSpinerComponent(this.dialog);
    }

    obtenerPermisos(){
      this.userData.getAccess$()
      .subscribe((data:any)=>{
      this.access = data;
      });
      return this.access;
    }

  ngOnInit(): void {
  Swal.fire(
      {
        icon: 'info',
        title: 'Filtrar Registros!',
        text: 'La ventana no mostrara registros al inicio o carga inicial, solo se muestra la información asociada al filtro que seleccione y aplique',
        confirmButtonText: "OK",
        confirmButtonColor: "#00B398", 
      });

  }
  getCategoria(){
    let count = 1;
    
    this.dialogSpiner.openDialog();

    this.categoryApiService.get().subscribe(resultData =>{
      this.data = [...resultData.resultObject];
      this.data.forEach((element:data_element) => {
        element.item = count++
        element.menu = element.productoId;
      });
      this.dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {

        this.dialogSpiner.closeDialog();
      });
    });
  }

  shouldBeBoldRow = (blodRow: any) => {
    return true;
  }

  filterAdv(event:any){
    this.eventFilter = event;
    this.dialogSpiner.openDialog();
    event = JSON.stringify(event);
    let count = 1;
    this.categoryApiService.getFilter(event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
        this.data = [...resultData.resultObject];
          this.data.forEach((element:data_element) => {
            element.item = count++;
            element.menu = element.productoId;
          });
          this.dialogSpiner.closeDialog();
      }else{
        this.data.splice(0, this.data.length);
      }
    });
  }

}

export interface data_element {
  item: number,
  productoId: bigint,
  producto: string,
  categoriaId : string,
  categoria: string,
  menu:any
}