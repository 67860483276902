import { Component, Injectable, Input, OnInit } from '@angular/core';
import { columns } from '../models/table-column';

@Component({
  selector: 'app-filter-avance',
  templateUrl: './filter-avance.component.html',
  styleUrls: ['./filter-avance.component.css']
})

@Injectable({
  providedIn: "root"
})

export class FilterAvanceComponent implements OnInit {

  @Input() columns!: columns<any>[] ;

  constructor() { 
  }

  ngOnInit(): void {
    console.log(this.columns)
  }

}
