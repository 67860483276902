import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from '../models/table-column.interface';
import { } from '@angular/common';

@Component({
  selector: 'app-js-table',
  templateUrl: './js-table.component.html',
  styleUrls: ['./js-table.component.css']
})
export class JSTableComponent implements OnInit {


  dataSourceTable!: MatTableDataSource<any>;

  @Input() data!: Array<any>;

  @Input() columnsData!: TableColumn<any>[];

  @Input() selection = new SelectionModel<any>(true, []);

  @Input() columns!: TableColumn<any>[];

  @Output() onItemClick = new EventEmitter<any>();

  @Input() tableMenu?: Array<any>;

  @Input() shouldBeBoldRow?: any;

  tableTitle: string = "";

  searchCtrl = new FormControl();

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    if (this.dataSourceTable !== undefined) this.dataSourceTable!.sort = sort;
  }

  constructor() {
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  getFilterColumns() {
    return this.columns.filter(currentColumn => !currentColumn.options?.excludeFromFilter);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTable.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceTable.paginator) {
      this.dataSourceTable.paginator.firstPage();
    }
  }

  ngOnInit() {
    console.log(this.columns);
    this.dataSourceTable = new MatTableDataSource();
    this.columns = this.columnsData;

    if (this.data) this.dataSourceTable.data = this.data;

    this.dataSourceTable.paginator = this.paginator;

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.dataSourceTable) this.dataSourceTable.data = changes.data.currentValue;
  }

  getShowValidator(menu: any, customer: any) {
    if (menu.showValidator) {
      let isDisabled = menu.showValidator(customer);
      return isDisabled;
    }

    return true;
  }

  getEnabledValidator(menu: any, customer: any) {
    if (menu.enabledValidator) {
      let isDisabled = !menu.enabledValidator(customer);
      return isDisabled;
    }

    return false;
  }

  getDataByColumn(column: any, row: any) {

    let data = row[column.property];

    if (data === undefined) return '';

    if (column.options) {
      if (column.options.slice) {
        if (data.length > column.options.slice) {
          data = data.substr(0, column.options.slice) + "...";
        }
      }
    }

    return data;
  }

  getColumnStyle(column: any, row: any) {
    let customStyle = this.shouldBeBoldRow?.(row) ? { 'font-weight': 700 } : {};
    let finalStyle = Object.assign(customStyle, column.style);
    return finalStyle;
  }

  onItemClickEvt(row: any) {
    this.onItemClick.emit(row);
  }

  onFilterChange(value: string) {
    if (!this.dataSourceTable) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSourceTable.filter = value;
  }

  toggleColumnVisibility(column: any, event: any) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  ngOnDestroy() {
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceTable!.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceTable!.data.forEach(row => this.selection.select(row));
  }

}
