import { environment } from './../../../environments/environment';
import { roleModels } from '../../models/usersModels/role.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { UserDataService } from '../user-data.service';

@Injectable({
  providedIn: "root"
})

export class claimsApiService {
  httpOptions: any;
  userId:string = '';
  roleId:string = '';

  private base_url = environment.auth_url;
  constructor(private http: HttpClient, private storage: StorageService,  private userData : UserDataService) {
    this.userData.getData$()
      .subscribe((data:any)=>{
        this.userId = data.userId;
        this.roleId = data.roleId;
      });
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
      
  }

  private apiUrl = this.base_url+'claims';

  public get(id?: string): Observable<any> {

    if(id !=  undefined){
      this.apiUrl  = this.base_url+"claims?id="+id;
    }
    return this.http.get<any>(this.apiUrl, this.httpOptions);
  }

  public post(RequestPayload: roleModels): Observable<any> {
    return this.http.post(this.apiUrl, RequestPayload, this.httpOptions);
  }

  public ObtenerNumeroPermisoModulo(modulo: string){
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'token': this.storage.getInSession('api_token')
      })};
    return this.http.get<any>(this.base_url+"claims/GetNumberPermission/"+this.userId+"/"+this.roleId+"/"+modulo, this.httpOptions)
  }
}
