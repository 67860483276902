<button type="button" class="btn btn-ligth dropdown-toggle " [ngClass]="{'app-dropdown-none': notificationCount==''}" [attr.data-content]="notificationCount" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <i class="far fa-bell"></i>
</button>
<div class="dropdown-menu">
  <div class="container-scroll-app">
    <a class="dropdown-item border-bottom" *ngFor="let item of dataSource" (click)="onclickViewNotification()">
      <h4 class="h5 mb-1 font-weight-bold">
        {{item.title}} 
      </h4>
      <p class="mt-0  w-100">
        {{item.msj}}
      </p>
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="dot"></span>

        <small class="text-info">
          <a routerLink="{{item.url}}" >Ir Módulo</a>
        </small>
      </div>
    </a>
  </div>
  <a class="dropdown-item" (click)="onclickViewNotification()">
    Ver Notificaciones
  </a>
</div>
