import { categoryApiService } from './../../services/Category/categoryApiService.module';
import { UserDataService } from './../../services/user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { locationApiService } from './../../services/Provider/locationApiService.module';
import { Router } from '@angular/router';
import { snackBar } from './../../Utils/snackBar';
import { DialogSpinerComponent } from './../../dialog-spiner/dialog-spiner.component';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-category-cargar-masiva',
  templateUrl: './category-cargar-masiva.component.html',
  styleUrls: ['./category-cargar-masiva.component.css']
})
export class CategoryCargarMasivaComponent implements OnInit {

  result: string = '';
  file: any;
  currentDate: Date = new Date();
  dataString:string = '';
  nameFile: string = "";
  dialogSpiner!: DialogSpinerComponent;
  access:number = 0;

  constructor(private snack: snackBar, private categoryApiService: categoryApiService, private dialog: MatDialog, private userData:UserDataService, private router: Router, private notificationsApiService : notificationsApiService) {
    this.userData.getAccess$()
      .subscribe((data:any)=>{
        this.access = data;
        
      });
      if(!(this.access & 16)){
         this.router.navigate(['/home']);
      }
    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
 }

  ngOnInit(): void {
  }

  onFileChange(ev: any) {
    let workBook: any = null;
    let jsonData = null;
    const reader = new FileReader();
    this.result = "";

    // console.log('archivo:'+ev.target.files.length);
    if(ev.target.files.length > 0){
      this.file = ev.target.files[0];
      this.nameFile = this.file.name;
      let ext = this.file.name.slice((this.file.name.lastIndexOf(".") - 1 >>> 0) + 2);
      
      //console.warn(ext);

      if(this.nameFile.indexOf('Plantilla-CargueCategorias') === 0){

        reader.onload = () => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          this.dataString = JSON.stringify(jsonData);
          // this.guardar(dataString);

        }
        reader.readAsBinaryString(this.file);
      }
      else if(ext != 'xlsx'){
        $("#fileCarga").val("");
        this.nameFile = "";
        this.result = "error: debe seleccionar un archivo de formato tipo XLSX";
      }
      else{
        $("#fileCarga").val("");
        this.nameFile = "";
        this.result = "error: El nombre del archivo no es admitido";
      }

    }
    else{
      this.result = "error: debe seleccionar un archivo de formato tipo XLSX";
    }

  }

  send(){
    this.guardar(this.dataString);
  }

  guardar(json: any){
    Swal.fire({
      title: '¿Desea cargar masivamente estas categorías al sistema?',
      // text: 'Una vez realizada esta accion no se podra revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aceptar',
      cancelButtonText: 'No, olvidalo',
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {

      this.dialogSpiner.openDialog();
      this.categoryApiService.PostMasiveProduct(json).subscribe((locationgueResult:any) =>{
      // console.warn(locationgueResult.resultObject[0]);
      //   this.result = locationgueResult.resultObject[0];
        Swal.fire(
          {
            icon: (locationgueResult.resultObject[0] ? 'success':'error'),
            title: (locationgueResult.resultObject[0] ? 'Registro de cagetorías!':'Algo no esta bien'),
            text: locationgueResult.resultObject[0],
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
          }
        )
        if(locationgueResult.resultObject[0] != null){
          this.notificationsApiService.InsertarNotificaciones('Carga Masiva de Categorias','El Archivo de Categorias '+this.nameFile+' ha sido cargado correctamente:'+locationgueResult.resultObject[0], 'category');
        }
        else{
          this.snack.openSnackBar('Ha ocurrido un error al guardar la carga masiva de categoria.', 'OK!');
          this.notificationsApiService.InsertarNotificaciones('Error al cargar el archivo de Carga Masiva de Categorias','El Archivo de Categorias '+this.nameFile+' no ha sido cargado:'+locationgueResult.resultObject[0], 'category');
        }
        // if(causalResult){
        //   this.result = causalResult;
        // }
        // else{
        //   this.snack.openSnackBar('Ha ocurrido un error al guardar el usuario, favor verificar.', 'OK!');
        // }
        this.dialogSpiner.closeDialog();

      },
      (error: HttpErrorResponse) => {
        let errMsg : string = '';
        if(error.status == 400){
      
          var errorArray = Object.values(error.error.errors);
          
          for (var key in errorArray) {
            //console.log("Ocurrieron los siguietes errores: " + errorArray[key]);
            errMsg += errorArray[key] + ', ';
          }

          Swal.fire(
            {
              icon: ('error'),
              title: 'Existe información errónea o Faltante',
              text:  errMsg+ ' por favor ajustarlo antes de volverlo a cargar',
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );

          this.snack.openSnackBar('Existe información errónea o Faltante dentro del archivo, por favor ajustarlo antes de volverlo a cargar '+ errMsg, 'OK!');
        }else{
          this.snack.openSnackBar('Ha ocurrido un error al guardar la información.', 'OK!');
        }
        this.snack.snackBarRef.afterDismissed();
        this.dialogSpiner.closeDialog();
        
        this.notificationsApiService.InsertarNotificaciones('Error al cargar el archivo de Carga Masiva de categorías','El Archivo de Causales '+this.nameFile+' no ha sido cargado, '+errMsg + ' favor verificar la información del archivo.', 'categorías');
      });
    
    }
    else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        {
          icon: 'error',
          title: "Cancelado",
          text: "Elemento no cargado",
          confirmButtonText: "OK",
          confirmButtonColor: "#00B398", 
      })
    }
    });
    this.dataString = "";
     $("#fileCarga").val("");
     this.nameFile = "";
  }
}
