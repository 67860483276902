import { userModels } from './../../../models/usersModels/user.module';
import { roleClaimsApiService } from './../../../services/users/roleClaimsApiService.module';
import { userRolesApiService } from './../../../services/users/userRolesApiService.module';
import { roleModels } from './../../../models/usersModels/role.module';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { rolesApiService } from 'src/app/services/users/rolesApiService.module';
import { snackBar } from '../../../Utils/snackBar';
import { userRoleModels } from 'src/app/models/usersModels/userRole.module';
import { claimsRoleModels } from 'src/app/models/usersModels/claimsRole.module';
import { formatDate } from '@angular/common';
import { columns } from 'src/app/models/table-column';
import { menuOptions } from 'src/app/models/menu.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { UserDataService } from 'src/app/services/user-data.service';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import Swal from'sweetalert2';

@Component({
  selector: 'app-role-claims',
  templateUrl: './role-claims.component.html',
  styleUrls: ['./role-claims.component.css']
})
export class RoleClaimsComponent implements OnInit {

 dataUsers : Array<any> =[];
 dataRol : any;
 dataClaims : Array<any> =[];
 index: number = 0;
 titleTableUser: string = 'Usuarios';
 titleTableClaims: string = 'Permisos';
 rolesModels: roleModels[] = [];
 access:number = 0;

 userRoleModels : userRoleModels = {
  userId: '',
  roleId: ''
 };

 claimsRoleModels : claimsRoleModels = {
  id: 0,
  roleId: '',
  claimType: '',
  claimValue: ''
 };

 rolId: string = '';
 rolName: string = '';
 rolDescription : string = '';
 tableUserId =  "tableUser";
 tableClaimsId =  "tableClaims";
 itemSelectedUser: any = [];
 itemSelectedClaims: any = [];
 filterEventUser: any;
 filterEventClaims: any;

 dialogSpiner!: DialogSpinerComponent;

  tableMenuUsers: menuOptions[] = [
    {
      icon : 'check_box',
      label: 'Asignar',
      show: (this.obtenerPermisos() & 256 ? true:false),
      callback: (userId: string) => { this.assignUser(userId) }
    },
    {
      icon : 'indeterminate_check_box',
      label: 'Desasignar',
      show: (this.obtenerPermisos() & 512 ? true:false),
      callback: (userId: string) => { this.notAssignUser(userId) }
    }
  ];

  columnsUsers: columns<any>[] = [
    {
      columnDef: 'item',
      header: 'Número',
      type: 'label',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.item}`
    },
    {
      columnDef: 'check',
      header: 'Seleccione',
      type: 'check',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.check}`
    },
    {
      columnDef: 'userId',
      header: 'id del usuario',
      type: 'label',
      class: 'd-none col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.userId}`
    },
    {
      columnDef: 'roleId',
      header: 'id del rol',
      type: 'label',
      class: 'd-none col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.roleId}`
    },
    {
      columnDef: 'nombreApellido',
      header: 'Nombre del Usuario',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.nombreApellido}`
    },
    {
      columnDef: 'email',
      header: 'Correo',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.email}`
    },
    {
      columnDef: 'empresa',
      header: 'Razón Social',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.empresa}`
    },
    {
      columnDef: 'phoneNumber',
      header: 'Teléfono',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.phoneNumber}`
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      type: 'html',
      class: 'col-sm-2',
      cell: (element: any) => `${element.estado != null ? '<i class="far fa-check-circle fa-2x text-success"></i>': ''}`
    },
    {
      columnDef: 'menu',
      header: 'Acciones',
      type: 'menu',
      class: 'col-sm-2',
      excludeFilter: true,
      menu: this.tableMenuUsers,
      cell: (element: any) => `${element.menu}`
    }
  ];

  tableMenuClaims: menuOptions[] = [
    {
      icon : 'check_box',
      label: 'Asignar',
      show: (this.obtenerPermisos() & 256 ? true:false),
      callback: (claimsId:string) => { this.assignClaims(claimsId) }
    },
    {
      icon : 'indeterminate_check_box',
      label: 'Desasignar',
      show: (this.obtenerPermisos() & 512 ? true:false),
      callback: (claimsId:string) => { this.notAssignClaims(claimsId) }
    }
  ];

  columnsClaims: columns<any>[] = [
    {
      columnDef: 'item',
      header: 'Número',
      type: 'label',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.item}`
    },
    {
      columnDef: 'check',
      header: 'Seleccione',
      type: 'check',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.check}`
    },
    {
      columnDef: 'claimsId',
      header: 'id del permiso',
      type: 'label',
      class: 'd-none col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.claimsId}`
    },
    {
      columnDef: 'roleId',
      header: 'id del rol',
      type: 'label',
      class: 'd-none col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.roleId}`
    },
    {
      columnDef: 'nombre',
      header: 'Permiso',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.nombre}`
    },
    {
      columnDef: 'claimsController',
      header: 'Módulo',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.claimsController}`
    },
    {
      columnDef: 'descriptionClaims',
      header: 'Descripción del Permiso',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.descriptionClaims}`
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      type: 'html',
      class: 'col-sm-2',
      cell: (element: any) => `${element.estado != null ? '<i class="far fa-check-circle fa-2x text-success"></i>': ''}`
    },
    {
      columnDef: 'menu',
      header: 'Acciones',
      type: 'menu',
      class: 'col-sm-2',
      excludeFilter: true,
      menu: this.tableMenuClaims,
      cell: (element: any) => `${element.menu}`
    }
  ];

  constructor(private ActivatedRoute: ActivatedRoute,
     private userRolesApiService: userRolesApiService,
     private roleClaimsApiService: roleClaimsApiService,
     private rolesApiService: rolesApiService,
     private snack: snackBar, private router: Router, public dialog: MatDialog, private userData:UserDataService, private notificationsApiService : notificationsApiService
     ) {
      this.obtenerPermisos();
      }

  ngOnInit(): void {
    this.getRol();
    this.getUsers();
    this.getClaims();
  }
  
  obtenerPermisos(){
    this.userData.getAccess$()
    .subscribe((data:any)=>{
    this.access = data;
    });
    return this.access;
  }

  getUsers(){
    this.dialogSpiner.openDialog();
    let  countUser: number = 1;
    this.userRolesApiService.get(this.rolId).subscribe(resultDataUserRole =>{
      this.dataUsers = [...resultDataUserRole.resultObject];

      this.dataUsers.forEach((element:data_element_user) => {
        element.item = countUser++;
        element.menu = element.userId;
        element.estado = element.roleId;
      });
      this.dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
      });
    });

  }
  getClaims(){
    this.dialogSpiner.openDialog();
    let countClaims = 1;
    this.roleClaimsApiService.get(this.rolId).subscribe(resultDataClaims =>{
      //console.warn(resultDataClaims);
      this.dataClaims = [...resultDataClaims.resultObject];
      this.dataClaims.forEach((element:data_element_claim) => {
        element.item = countClaims++;
        element.menu =element.claimsId;
        element.estado = element.roleId;
      });
      this.dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        console.log('::::::::::Error carga de permisos');
      });
    });

  }

  getRol(){

    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
    this.dialogSpiner.openDialog();
    this.rolId =  this.ActivatedRoute.snapshot.params.id.toString();
    // console.log('rolidsend:'+this.ActivatedRoute.snapshot.params.id.toString());
    this.rolesApiService.get(this.ActivatedRoute.snapshot.params.id.toString()).subscribe(resultDataRol =>{
      this.rolesModels = resultDataRol.resultObject;
      this.rolName = this.rolesModels[0].name;
      this.rolDescription = this.rolesModels[0].normalizedName;
      this.dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
      });
    });

  }
  assignUser(userId: string){

    Swal.fire({
      title: '¿Desea asignar el usuario al rol de '+this.rolName+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, asginarlo',
      cancelButtonText: 'No, olvidalo',
      buttonsStyling: true,
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.dialogSpiner.openDialog();
        this.userRoleModels = {
          roleId : this.rolId,
          userId : userId
        }
    
        this.userRolesApiService.post(this.userRoleModels).subscribe(resultData =>{
          this.dialogSpiner.closeDialog();
          //console.warn(resultData.resultObject);
          this.getUsers();
          Swal.fire(
            {
              icon: (resultData.resultObject[0] ? 'success':'error'),
              title: (resultData.resultObject[0] ? 'Usuario asginado!':'Algo no esta bien'),
              text:  (resultData.resultObject[0] ? 'El usuario fue asignado al rol con éxito':'Error al asignar el usuario al rol'),
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );

          if(resultData){
            this.notificationsApiService.InsertarNotificaciones('El usuario ', 'ha sido asignado al Rol '+this.rolName, 'role');
          }
          else{
            this.notificationsApiService.InsertarNotificaciones('Error al asignar el usuario ', 'No ha sido asignado al Rol '+this.rolName, 'role');
          }
    
        },
        error => {
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
          });
        });
         
          

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Usuario no asignado",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        }
        )
      }
    });

  }
  notAssignUser(userId: string){

    Swal.fire({
      title: '¿Desea desasignar el usuario al rol de '+this.rolName+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, desasignar',
      cancelButtonText: 'No, olvidalo',
      buttonsStyling: true,
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.dialogSpiner.openDialog();
        //console.log(this.rolId + ' - ' +userId )
        this.userRolesApiService.delete(this.rolId, userId).subscribe(resultData =>{
          this.dialogSpiner.closeDialog();
          this.getUsers();
          Swal.fire(
            {
              icon: (resultData.resultObject[0] ? 'success':'error'),
              title: (resultData.resultObject[0] ? 'Usuario desasignado!':'Algo no esta bien'),
              text: (resultData.resultObject[0] ? 'El usuario fue desasignado al rol con éxito':'Error al desasignar el usuario al rol'),
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );

          if(resultData.resultObject[0]){
            this.notificationsApiService.InsertarNotificaciones('El usuario ', 'ha sido desasignado del Rol '+this.rolName, 'role');
          }
          else{
            this.notificationsApiService.InsertarNotificaciones('Error al desasignar el usuario', 'no ha sido desasignado del Rol '+this.rolName, 'role');
          }
    
        },
        error => {
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
          });
        });
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Usuario no desasignado",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        }
        )
      }
    });

   

  }
  assignClaims(claimsId:string){

    Swal.fire({
      title: '¿Desea asignar el permiso al rol de '+this.rolName+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, asginarlo',
      cancelButtonText: 'No, olvidalo',
      buttonsStyling: true,
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.claimsRoleModels = {
          id : 0,
          roleId: this.rolId,
          claimType: claimsId,
          claimValue: "1"
        }
    
        this.roleClaimsApiService.post(this.claimsRoleModels).subscribe(resultData =>{
          this.getClaims();
          Swal.fire(
            {
              icon: (resultData.resultObject[0] ? 'success':'error'),
              title: (resultData.resultObject[0] ? 'Permiso asignado!':'Algo no esta bien'),
              text: (resultData.resultObject[0] ? 'El permiso fue asignado al rol con éxito':'Error al asignar el permiso al rol'),
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );
          if(resultData.resultObject[0]){
            
            this.notificationsApiService.InsertarNotificaciones('El Permiso '+this.claimsRoleModels.claimValue, 'ha sido asignado al Rol '+this.rolName, 'role');
          }
          else{
            this.notificationsApiService.InsertarNotificaciones('Error al asignar el Permiso '+this.claimsRoleModels.claimValue, 'No ha sido asignado al Rol '+this.rolName, 'role');
          }
          
        },
        error => {
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            console.log('::::::::::Error en la asignación del usuario al rol');
          });
        });
          

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Permiso no asignado",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        }
        )
      }
    });

    

  }
  notAssignClaims(claimsId:string){

    Swal.fire({
      title: '¿Desea desasignar el permiso al rol de '+this.rolName+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, desasignar',
      cancelButtonText: 'No, olvidalo',
      buttonsStyling: true,
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.roleClaimsApiService.delete(this.rolId, claimsId).subscribe(resultData =>{
          this.getClaims();
          Swal.fire(
            {
              icon: (resultData.resultObject[0] ? 'success':'error'),
              title: (resultData.resultObject[0] ? 'Permiso desasignado!':'Algo no esta bien'),
              text: (resultData.resultObject[0] ? 'El permiso fue desasignado al rol con éxito':'Error al desasignar el permiso al rol'),
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );
          if(resultData.resultObject[0]){
           
            this.notificationsApiService.InsertarNotificaciones('El Permiso '+this.claimsRoleModels.claimValue, 'ha sido desasignado del Rol '+this.rolName, 'role');
          }
          else{
            this.notificationsApiService.InsertarNotificaciones('Error al desasignar el Permiso '+this.claimsRoleModels.claimValue, 'No ha sido desasignado del Rol '+this.rolName, 'role');
          }
        },
        error => {
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            console.log('::::::::::Error en la asignación del usuario al rol');
          });
        });
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Permiso no desasignado",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        }
        )
      }
    });
  }

  filterAdvUser(event:any){
    this.filterEventUser = event;
    event = JSON.stringify(event);
    let count = 1;
    //console.warn(event);
    this.userRolesApiService.getFilter(this.rolId, event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
        this.dataUsers = [...resultData.resultObject];
        this.dataUsers.forEach((element:data_element_user) => {
          element.item = count++;
          element.menu = element.userId;
          element.estado = element.roleId;
        });
      }else{
        this.dataUsers.splice(0, this.dataUsers.length);
      }
      //console.warn(this.dataUsers);
    });
  }

  filterAdvRole(event:any){
    this.filterEventUser = event;
    event = JSON.stringify(event);
    let count = 1;
    this.roleClaimsApiService.getFilter(this.rolId, event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
        this.dataClaims = [...resultData.resultObject];
          this.dataClaims.forEach((element:data_element_claim) => {
            element.item = count++;
            element.menu =element.claimsId;
            element.estado = element.roleId;
          });
      }else{
        this.dataClaims.splice(0, this.dataClaims.length);
      }
    });
  }

  itemsSelectedUser(event:any){
    this.itemSelectedUser = event;
    //console.warn(this.itemSelectedUser.length);
  }
  itemsSelectedClaims(event:any){
    this.itemSelectedClaims = event;
    //console.warn(this.itemSelectedClaims.length);
  }

  assginSimultUser(){

    Swal.fire({
      title: '¿Desea asignar simultaneamente los usuarios seleccionados al rol de '+this.rolName+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, asginarlos',
      cancelButtonText: 'No, olvidalo',
      buttonsStyling: true,
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
        
        try{

          if(this.itemSelectedUser.length == 0){
            Swal.fire(
              {
                icon: 'error',
                title: "Usuarios no seleccionados",
                text: "Favor seleccione los usuarios",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            }
            )
            return;
          }

          this.itemSelectedUser.forEach((element: any) => {

            this.dialogSpiner.openDialog();
            this.userRoleModels = {
              roleId : this.rolId,
              userId : element.userId
            }
  
            this.userRolesApiService.post(this.userRoleModels).subscribe(resultData =>{
              this.dialogSpiner.closeDialog();
              //console.warn(resultData.resultObject);
              if(this.filterEventUser != undefined){
                this.filterAdvUser(this.filterEventUser);
              }
              else{
                this.getUsers();
              }
            
            },
            error => {
              this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
              this.snack.snackBarRef.afterDismissed().subscribe(() => {
                this.dialogSpiner.closeDialog();
              });
            });
          });
          this.notificationsApiService.InsertarNotificaciones('Los usuarios ', 'han sido asignados simultaneamente al Rol '+this.rolName, 'role');

         
          this.itemSelectedUser.splice(0, this.itemSelectedUser.length);
          Swal.fire(
            {
              icon: ('success'),
              title: 'Usuarios Asignados!',
              text: 'los usuarios fueron asignados al rol con éxito',
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );
        }
        catch(ex){
          console.warn(ex);
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
            this.notificationsApiService.InsertarNotificaciones('Error al asignar los usuarios', 'No han sidos asignado simultaneamente al Rol '+this.rolName, 'role');
          });
        }

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Usuarios no asignados",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        }
        )
      }
    });

   
    
  }
  assginSimultClaims(){

    Swal.fire({
      title: '¿Desea asignar simultaneamente los permisos seleccionados al rol de '+this.rolName+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, asginarlos',
      cancelButtonText: 'No, olvidalo',
      buttonsStyling: true,
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
        
        try{
          if(this.itemSelectedClaims.length == 0){
            Swal.fire(
              {
                icon: 'error',
                title: "Permisos no seleccionados",
                text: "Favor seleccione los permisos",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            }
            )
            return;
          }
          this.itemSelectedClaims.forEach((element: any) => {

            this.dialogSpiner.openDialog();
            this.claimsRoleModels = {
              id : 0,
              roleId: this.rolId,
              claimType: element.claimsId,
              claimValue: "1"
            }
  
            this.roleClaimsApiService.post(this.claimsRoleModels).subscribe(resultData =>{
              
            if(this.filterEventClaims != undefined){
              this.filterAdvRole(this.filterEventClaims);
            }
            else{
              this.getClaims();
            }

            },
            error => {
              this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
              this.snack.snackBarRef.afterDismissed().subscribe(() => {
                console.log('::::::::::Error en la asignación del usuario al rol');
              });
            });
          });        
                
          this.notificationsApiService.InsertarNotificaciones('Los Permisos '+this.claimsRoleModels.claimValue, 'ha sido asignado simultaneamente al Rol '+this.rolName, 'role');
         
          this.itemSelectedClaims.splice(0, this.itemSelectedClaims.length);
          Swal.fire(
            {
              icon: ('success'),
              title: 'Permisos asignado!',
              text: 'los permisos fueron asignados al rol con éxito',
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );
        }
        catch(ex){
          console.warn(ex);
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
                    
            this.notificationsApiService.InsertarNotificaciones('Error al asignar los permisos '+this.claimsRoleModels.claimValue, 'No han sido asignados simultaneamente al Rol '+this.rolName, 'role');

          });
        }

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Usuario no asignado",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        }
        )
      }
    });
 
  }
  desassginSimultUser(){

    Swal.fire({
      title: '¿Desea desasignar simultaneamente los usuarios seleccionados al rol de '+this.rolName+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, desasginarlos',
      cancelButtonText: 'No, olvidalo',
      buttonsStyling: true,
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
        
        try{
         
          if(this.itemSelectedUser.length == 0){
            Swal.fire(
              {
                icon: 'error',
                title: "Usuarios no seleccionados",
                text: "Favor seleccione los usuarios",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            }
            )
            return;
          }

          this.itemSelectedUser.forEach((element: any) => {

            this.dialogSpiner.openDialog();

            this.userRolesApiService.delete(this.rolId, element.userId).subscribe(resultData =>{
              this.dialogSpiner.closeDialog();
              //console.warn(resultData.resultObject);
              if(this.filterEventUser != undefined){
                this.filterAdvUser(this.filterEventUser);
              }
              else{
                this.getUsers();
              }
            
            },
            error => {
              this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
              this.snack.snackBarRef.afterDismissed().subscribe(() => {
                this.dialogSpiner.closeDialog();
              });
            });
          });

          this.notificationsApiService.InsertarNotificaciones('Los usuarios ', 'han sido dsasignados simultaneamente al Rol '+this.rolName, 'role');
          
         
          this.itemSelectedUser.splice(0, this.itemSelectedUser.length);
          Swal.fire(
            {
              icon: ('success'),
              title: 'Usuarios Asignados!',
              text: 'los usuarios fueron desasignados al rol con éxito',
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );
        }
        catch(ex){
          console.warn(ex);
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
            this.notificationsApiService.InsertarNotificaciones('Error al desasignar los usuarios', 'No han sidos asignado simultaneamente al Rol '+this.rolName, 'role');
          });
        }

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Usuarios no desasignados",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        }
        )
      }
    });

   
    
  }
  desassginSimultClaims(){

    Swal.fire({
      title: '¿Desea desasignar simultaneamente los permisos seleccionados al rol de '+this.rolName+'?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, desasginarlos',
      cancelButtonText: 'No, olvidalo',
      buttonsStyling: true,
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
        
        try{
          if(this.itemSelectedClaims.length == 0){
            Swal.fire(
              {
                icon: 'error',
                title: "Permisos no seleccionados",
                text: "Favor seleccione los permisos",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            }
            )
            return;
          }
          this.itemSelectedClaims.forEach((element: any) => {

            this.dialogSpiner.openDialog();
            this.claimsRoleModels = {
              id : 0,
              roleId: this.rolId,
              claimType: element.claimsId,
              claimValue: "1"
            }
  
            this.roleClaimsApiService.delete(this.rolId, element.claimsId).subscribe(resultData =>{
              if(this.filterEventClaims != undefined){
                this.filterAdvRole(this.filterEventClaims);
              }
              else{
                this.getClaims();
              }
            },
            error => {
              this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
              this.snack.snackBarRef.afterDismissed().subscribe(() => {
                console.log('::::::::::Error en la desasignación del permiso al rol');
              });
            });
          });        
                
          this.notificationsApiService.InsertarNotificaciones('Los Permisos '+this.claimsRoleModels.claimValue, 'ha sido desasignado simultaneamente al Rol '+this.rolName, 'role');
          
          this.itemSelectedClaims.splice(0, this.itemSelectedClaims.length);

          Swal.fire(
            {
              icon: ('success'),
              title: 'Permisos asignado!',
              text: 'los permisos fueron desasignados al rol con éxito',
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );
        }
        catch(ex){
          console.warn(ex);
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
                    
            this.notificationsApiService.InsertarNotificaciones('Error al desasignar los permisos '+this.claimsRoleModels.claimValue, 'No han sido desasignados simultaneamente al Rol '+this.rolName, 'role');

          });
        }

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Usuario no desasignado",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        }
        )
      }
    });
 
  }

}



export interface data_element_user {
  item: number,
  userId: string,
  roleId: string,
  userName: string,
  email: string,
  empresa: string,
  phoneNumber: string,
  estado: string,
  menu:any,
  id?: number
}

export interface data_element_claim {
  item: number,
  claimsId: string,
  roleId: string,
  nombre: string,
  claimsController: string,
  descriptionClaims: string,
  estado: string,
  menu:any,
  id?: number
}
