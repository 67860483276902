<div class="container-fluid">
	<div class="row">
		<div class="col-md-6 col-lg-6 p-0">
			<div class="ladoa">
				<div class="row m-0 vh-100 justify-content-center align-item-center">
					<div id="" class="col-auto p-md-2 p-5 bg-login-mobile text-center ">
						<form (ngSubmit)="login()" [formGroup]="LogInForm" name="LogInForm" class="p-3 rounded" >
							<fieldset>
								<div class="row">
									<div class="col-12">
										<div class="float-center  d-block d-sm-none"><img class="img-fluid logo-app" src="../../assets/LOGYCA CORPORATIVO.png"/></div>
										<img src="assets/images/user-icon.svg" alt="icon login">
									</div>
									<div class="col-12">
										<h1>Restablecer Contraseña</h1>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-12">
										<p class="d-flex justify-content-start">Correo electrónico <span class="text-warning ml-1">*</span> </p><input class="form-control" type="email" id="Email" name="Email"  formControlName="Email" [readonly]="loadPage"  [ngClass]="{ 'is-invalid': isValid().Email.dirty && isValid().Email.errors }" class="form-control" />
										<span *ngIf="isValid().Email.errors && isValid().Email.dirty" class="text-danger">
											Email es requerido.
									</span>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<button class="btn btn-primary col" type="submit" [disabled]="LogInForm.invalid || loadPage" >Restablecer</button>
									</div>
									<div class="col-12 mt-2">
										<a class="small-text d-flex justify-content-end" routerLink="/login">Cancelar e Iniciar sesión</a>
									</div>
								</div>
							</fieldset>

              <div class="w-100d-flex justify-content-end mt-3">
                <a class="small-text" href="#">¿No tienes cuenta todavía? comunicate con el administrador</a>
              </div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6 col-lg-6 d-none d-md-block p-0" >
			<div class="ladob bg-login" >
				<div class="float-right"><img width="250px" src="../../assets/LOGYCA CORPORATIVO_blanco.png"/>
				</div>
			</div>
		</div>
	</div>
</div>
