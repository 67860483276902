<p></p>
<div class="container">
  <div class="row">
    <div class="col">
        <p></p>
        <mat-card>
            <mat-card-title>
              Listados<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
            </mat-card-title>
            <app-bread-crumb></app-bread-crumb>
        </mat-card>
    </div>
  </div>
  <!-- (ngSubmit)="submit()" [formGroup]="causalForm" -->
  <form  >

  <div class="row">
    <div class="col p-3">
      <mat-card>
        <!-- (eventFilter)="filterAdv($event)" (eventRowSelected)="itemsSelected($event)" -->
        <app-datatable [tableId]="tableProductId" (eventFilter)="filterAdvProduct($event)" [title]="titletableProduct" [data]="dataProduct" [columns]="columnsProduct"  ></app-datatable>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col p-3">
      <mat-card>
        <!-- (eventFilter)="filterAdv($event)" (eventRowSelected)="itemsSelected($event)" -->
        <app-datatable [tableId]="tableLocationId" (eventFilter)="filterAdvLocation($event)" [title]="titletableLocation" [data]="dataLocation" [columns]="columnsLocation"  ></app-datatable>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col text-right">
        <!-- <button mat-raised-button type="submit" [disabled]="causalForm.invalid" class="btn-primary">Guardar</button><span class="mx-2 text-muted">|</span> -->
        <button type="button" routerLink="/causal" class="btn btn-default">Regresar</button>
    </div>
  </div>
  </form>
</div>

