<header class="header-app" id="header-app">
  <div class="header_toggle-app"> <i class='fas fa-bars' id="header-toggle-app"></i></div>
  <app-notification [data]="data"></app-notification>
</header>
<div class="l-navbar-app" id="nav-bar-app" (click)="openSideBar()">
  <app-sidenavbar></app-sidenavbar>
</div>
<div class="height-100 bg-light pt-5 pb-5">
  <router-outlet></router-outlet>
</div>

