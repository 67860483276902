<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">    
    <div class="card overflow-auto" fxFlex="100" >
      <div class="bg-primary-500 px-6 h-14 border-b sticky left-0 text-primary-contrast-500" fxLayout="row" fxLayoutAlign="start center">
        <h3 class="oferentes m-0 mr-2 w-100 text-primary-contrast-500 mr-4">{{tableTitle}}</h3>
        <div class="bg-card rounded-full border px-4 text-black h-10"
             fxFlex="400px"
             fxFlex.lt-md="auto"
             fxHide.xs
             fxLayout="row"
             fxLayoutAlign="start center">
                 <mat-form-field appearance="standard">
                  <mat-label>Filter</mat-label>
                  <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar" #input>
              
                </mat-form-field>
        </div>
  
        <span fxFlex></span>
  
        <button class="ml-4" fxFlex="none" fxHide.gt-xs mat-icon-button type="button">
        </button>
  
        <button [matMenuTriggerFor]="columnFilterMenu"
                class="ml-4"
                fxFlex="none"
                mat-icon-button
                matTooltip="Filtrar Columnas"
                type="button">
        </button>        
      </div>
  
      <table class="w-full" [dataSource]="dataSourceTable" mat-table matSort>
        
        <ng-container matColumnDef="checkbox">
          <th *matHeaderCellDef mat-header-cell>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="true && isAllSelected()"
                          [indeterminate]="true && !isAllSelected()"
                          color="primary">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" class="w-10" mat-cell>
            <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                          (click)="$event.stopPropagation()"
                          [checked]="selection.isSelected(row)"
                          color="primary">
            </mat-checkbox>
          </td>
        </ng-container>

        

        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase relative" mat-header-cell mat-sort-header> 
              <div class="helpDiv" *ngIf="column.helpText">
                <div class="helpSign">
                  <span [innerHTML]="column.helpText"></span>
                </div>
              </div>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" (click)="onItemClickEvt(row)"  [ngClass]="column.cssClasses" [ngStyle]="getColumnStyle(column,row)" mat-cell>
              {{ getDataByColumn(column,row) }}
            </td>
          </ng-container>

            <ng-container *ngIf="column.type === 'icon'" [matColumnDef]="column.property">
              <th *matHeaderCellDef class="uppercase relative" mat-header-cell mat-sort-header> 
                <div class="helpDiv" *ngIf="column.helpText">
                  <div class="helpSign">
                    <span [innerHTML]="column.helpText"></span>
                  </div>
                </div>
                {{ column.label }}
              </th>
              <td *matCellDef="let row" (click)="onItemClickEvt(row)"  [ngClass]="column.cssClasses" [ngStyle]="getColumnStyle(column,row)" mat-cell>

               
              </td>
            </ng-container>

          <ng-container *ngIf="column.type === 'starred'" [matColumnDef]="column.property">
            <ng-container *ngIf="column.property === 'lowest'">
              <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ column.label }}</th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" class="w-10" mat-cell>
                <ng-container *ngIf="row[column.property]">Menor precio</ng-container>
              </td>
            </ng-container>
          </ng-container>
          
          <ng-container *ngIf="column.property === 'actions'" matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
              <button *ngIf="!column.buttonText" (click)="$event.stopPropagation()"
                      [matMenuTriggerData]="{ customer: row }"
                      [matMenuTriggerFor]="actionsMenu"
                      mat-icon-button
                      type="button">
              </button>
              <button *ngIf="column.buttonText" (click)="column.doAction(row)"
                      mat-flat-button
                      color="primary"
                      class="text-contrast-white w-2x1"
                      type="button">
                  {{column.buttonText}}
              </button>
            </td>
          </ng-container>
        </ng-container>



        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns;"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row></tr>
      </table>
    </div>
</div>
<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of getFilterColumns()"
            class="checkbox-item mat-menu-item">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
        {{ column.label }}
        </mat-checkbox>
    </button>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-customer="customer" matMenuContent>
    <span *ngFor="let menu of tableMenu">
      <button (click)="menu.callback(customer)" 
        *ngIf="getShowValidator(menu,customer)" 
        [disabled]="getEnabledValidator(menu,customer)" mat-menu-item
      >
        <span>{{menu.label}}</span>
      </button>   
    </span>
  </ng-template>
</mat-menu>  
<mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
