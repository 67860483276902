import { TableSend } from './../models/table-send';
import { columns, itemFilter } from 'src/app/models/table-column';
import { OnInit, Component, ViewChild, Input, OnDestroy, AfterViewInit, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import 'rxjs/add/observable/of';
import { DynamicComponentDirective } from '../dynamic-component.directive';
import { FormBuilder, FormControl, NgControl } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit, AfterViewInit, OnDestroy {


  dataSource!: MatTableDataSource<any>;

  @Input() data!: Array<any>;

  @Input() columns!: columns<any>[];

  @Output() eventFilter = new EventEmitter<any>();

  @Output() eventRowSelected = new EventEmitter<any>();

  rowCheckSelected: object[] = [];

  filterAvanceList!: itemFilter<any>[];

  @Input() title!: string;

  @Input() tableId!: string;

  newFilter: number = 0;

  newFilterArray: any = [];

  itemFilterAvance!: itemFilter<any>;

  disable: boolean = true;


  displayedColumns: any;

  cont: number = 0;

  filterForm = this.formBuilder.group({});

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  @ViewChild(DynamicComponentDirective)
  dynamicHost!: DynamicComponentDirective;

  dynamicComponent: any;
  interval: any;

  constructor(private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    if (this.data) this.dataSource.data = this.data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.filterForm.addControl('condition', this.formBuilder.control(''));
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.dataSource) this.dataSource.data = changes.data.currentValue;
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  createFilterItem(accion: string) {
    if (accion == 'suma') {
      this.newFilter += 1;
    }
    else if (accion == 'resta') {
      this.newFilter -= 1;
    }
    this.newFilterArray = Array(this.newFilter).fill(this.newFilter).map((x, i) => i);
    this.createGroup();

    return this.newFilter;
  }

  eliminarElemento(id: any, num: number) {
    let elementDelete = document.getElementById(id);
    if (!elementDelete) {
    } else {
      let padre = elementDelete.parentNode;
      padre!.removeChild(elementDelete);
      this.createFilterItem('resta');
      this.deleteGroup(num);
      this.filter();
    }
  }

  typeInputFilter(event: any, index: any) {
    let inputfilter = document.getElementById("inputTypeFilter_" + index);
    let selected = document.getElementById("selectedFilter_" + index);
    let type = event.target.selectedOptions[0].getAttribute("typeInput");
    let options: any = null;
    if (selected) {
      options = selected.getElementsByTagName('option');
    }
    for (const key in options) {
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        const element = options[key];
        element.removeAttribute("disabled", "true")
      }
    }

    switch (type) {
      case "number":
        if (inputfilter) {
          inputfilter.setAttribute("type", "number");
        }
        break;

      case "label":
      case "text":
      case "progress":
      case "html":
      case "image":
      case "badge":
      case "checkbox":
      case "button":
      case "starred":
      case "icon":
        if (inputfilter) {
          inputfilter.setAttribute("type", "text");
        }
        options[9].setAttribute("disabled", "true");
        options[10].setAttribute("disabled", "true");
        break;

      case "date":
        if (inputfilter) {
          inputfilter.setAttribute("type", "date");
        }
        options[1].setAttribute("disabled", "true");
        options[2].setAttribute("disabled", "true");
        options[5].setAttribute("disabled", "true");
        options[6].setAttribute("disabled", "true");
        options[7].setAttribute("disabled", "true");
        options[8].setAttribute("disabled", "true");
        break;

      case "phone":
        if (inputfilter) {
          inputfilter.setAttribute("type", "tel");
        }
        options[1].setAttribute("disabled", "true");
        options[2].setAttribute("disabled", "true");
        options[5].setAttribute("disabled", "true");
        options[6].setAttribute("disabled", "true");
        options[7].setAttribute("disabled", "true");
        options[8].setAttribute("disabled", "true");

        break;
    }
  }


  onSubmit(event: any): void {

    this.filter();
  }

  filter() {
    let data = this.filterForm.value;
    let send: any = {
      items: [],
      condition: ""
    };

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key];
        let keySend: any = key.substr(key.length - 1);

        if (key != "condition") {
          if (!send.items[keySend]) {
            send.items[keySend] = {};
          }
          send.items[keySend][key.slice(0, -1)] = element;
        } else {
          send.condition = element;
        }
      }
    }

    this.eventFilter.emit(send);
  }

  hideShowColumn(column: columns<any>, e: any) {
    this.columns.forEach(element => {
      if (element.header === column.header) {
        element.show = e.currentTarget.checked;
      }
    });
  }

  rowSelected(data: any, event: any, index: any) {
    if (event.currentTarget.checked) {
      data.indexTemp = index;
      this.rowCheckSelected.push(data);
    } else {
      let itemTemp = JSON.parse(JSON.stringify(this.rowCheckSelected));
      this.rowCheckSelected = [];
      for (const key in itemTemp) {
        if (Object.prototype.hasOwnProperty.call(itemTemp, key)) {
          const element = itemTemp[key];
          if (index != element.indexTemp) {
            this.rowCheckSelected.push(element);
          }
        }
      }
    }
    this.eventRowSelected.emit(this.rowCheckSelected);
  }

  createGroup(): void {
    this.newFilterArray.forEach((control: any) => {
      this.filterForm.addControl('column' + control, this.formBuilder.control(''));
      this.filterForm.addControl('criteria' + control, this.formBuilder.control(''));
      this.filterForm.addControl('value' + control, this.formBuilder.control(''));
    });

  }
  deleteGroup(num: number): void {
    this.filterForm.removeControl('column' + num);
    this.filterForm.removeControl('criteria' + num);
    this.filterForm.removeControl('value' + num);

  }
}

