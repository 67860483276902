import { StorageService } from './../services/storage.service';
import { UserDataService } from './../services/user-data.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SidenavbarComponent implements OnInit {
  userName:string = '';
  userRole:string = '';

  itemsMenu:any = [];
  constructor(private router: Router, private userData:UserDataService, private storage:StorageService) {
    this.itemsMenu = this.storage.getInSession('user_menu');
  }

  logout(){
    this.userData.logOut();
  }

  ngOnInit(): void {
    this.userData.getData$()
    .subscribe((data:any)=>{
      this.userName = data.userName;
      this.userRole = data.rolName
    });
    $(function() {

      'use strict';
      $('.js-menu-toggle').click(function(e) {

        var $this = $(this);
        if ( $('body').hasClass('show-sidebar') ) {
          $('body').removeClass('show-sidebar');
          $this.removeClass('active');
        } else {
          $('body').addClass('show-sidebar');
          $this.addClass('active');
        }
        e.preventDefault();

      });

      $(document).mouseup(function(e:any) {
        var container = $(".sidebar");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ( $('body').hasClass('show-sidebar') ) {
            $('body').removeClass('show-sidebar');
            $('body').find('.js-menu-toggle').removeClass('active');
          }
        }
      });
    });
  }
  btnClick(){ this.router.navigate(['/login']); };
}
