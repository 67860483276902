import { userModels } from '../../models/usersModels/user.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import * as bcrypt from 'bcryptjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { UserDataService } from '../user-data.service';

@Injectable({
  providedIn: "root"
})

export class networkApiService {
  private base_url = '';
  private apiUrl =  '';
  httpOptions: any;
  gln_cadena : number = 0;
  gln_proveedor : number = 0;

  constructor(private http: HttpClient, private storage: StorageService, private userData : UserDataService) {
    this.userData.getData$()
    .subscribe((data:any)=>{
      this.gln_cadena = data.gln_cadena;
      this.gln_proveedor = data.gln_proveedor;
    });
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
   }

  public getNetWork(id?: number): Observable<any> {
    this.base_url = environment.netWork_url;
    this.apiUrl = this.base_url + 'cadenas';
    if (id != undefined) {
      this.apiUrl = this.apiUrl + "?id=" + id;
      return this.http.get<any>(this.apiUrl,this.httpOptions);
    } 
    else{
      return this.http.get<any>(this.apiUrl, this.httpOptions);
    }
  }
  public getProvider(): Observable<any> {
    this.base_url = environment.netWork_url;
    this.apiUrl = this.base_url + 'cadenas';
    if (this.gln_proveedor != undefined && this.gln_cadena == undefined) {
      this.apiUrl = this.apiUrl + "?id=" + this.gln_proveedor;
      return this.http.get<any>(this.apiUrl,this.httpOptions);
    } 
    else if(this.gln_proveedor != undefined && this.gln_cadena != undefined){
      this.apiUrl = this.apiUrl + "?id=" + this.gln_proveedor + "&cadenaId=" + this.gln_cadena;
      return this.http.get<any>(this.apiUrl,this.httpOptions);
    }
    return this.http.get<any>(this.apiUrl, this.httpOptions);
    
  }

}