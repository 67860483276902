import { claimsRoleModels } from '../../models/usersModels/claimsRole.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { UserDataService } from '../user-data.service';

@Injectable({
  providedIn: "root"
})

export class roleClaimsApiService {
  httpOptions: any;
  userId: string = '';
  private base_url = environment.auth_url;
  constructor(private http: HttpClient, private userData : UserDataService, private storage: StorageService) {

    this.userData.getData$()
    .subscribe((data:any)=>{
      this.userId = data.userId;
    });
    
  }

  private apiUrl = '';

  public get(roleId:string, id?: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    if(id !=  undefined){
      this.apiUrl  = this.base_url+"roleclaims?roleId="+roleId+'&id='+id;
    }
    else{
      this.apiUrl  = this.base_url+"roleclaims?roleId="+roleId;
    }
    return this.http.get<any>(this.apiUrl, this.httpOptions);
  }
  getFilter(roleId: string, data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(environment.auth_url+"roleclaims?roleId="+roleId, this.httpOptions);
  }

  public post(RequestPayload: claimsRoleModels): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    this.apiUrl = this.base_url+'roleclaims?userId='+this.userId;
    return this.http.post(this.apiUrl, RequestPayload, this.httpOptions);
  }
  public delete( roleId: string, claimsId: string ): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    this.apiUrl = this.base_url+'roleclaims';
    return this.http.delete(this.apiUrl+'/?roleId='+roleId+'&claimsId='+claimsId + '&userId='+this.userId, this.httpOptions);
  }
}
