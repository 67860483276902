import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { reportsApiService } from 'src/app/services/Reports/reportsApiService.module';
import { snackBar } from 'src/app/Utils/snackBar';
import * as pbi from 'powerbi-client';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDataService } from 'src/app/services/user-data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { models } from 'powerbi-client';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-informes-general',
  templateUrl: './informes-general.component.html',
  styleUrls: ['./informes-general.component.css']
})
export class InformesGeneralComponent implements OnInit {
  data: any;
  screenHeight!: number;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  access: number = 0;
  title: string = '';
  private ReportPowerBiGeneralCadena ='';
  private ReportPowerBiConciliacionCadena ='';
  private ReportPowerBiBenchMarkingCadena ='';
  private ReportPowerBiBenchMarkingProveedores ='';
  private ReportPowerBiGeneralProveedoresPremium ='';
  private ReportPowerBiConciliacionProveedoresPremium  ='';
  private ReportPowerBiConciliacionProveedoresFreemium  ='';
  private ReportPowerBiGeneralProveedoresFreemium ='';


  @ViewChild('reportContainer', { static: true }) reportContainer!: ElementRef;

  constructor(public dialog: MatDialog, public reportsApiService: reportsApiService,
    private snack: snackBar, private route: ActivatedRoute,
    private router: Router, private userData: UserDataService) {
    console.warn('Prueba');
    this.userData.getAccess$()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.access = data;
      });


  }

  ngOnInit(): void {
    this.route.params
      .subscribe((params: any) => {
        if (params.id == 'gc') {
          if (!(this.access & 16384)) {
            this.title = 'Reporte General de Cadena';
            this.screenHeight = (window.screen.height);
            this.getTokenReport(environment.PowerBiGeneralCadena);
          }
        } else if (params.id == 'cc') {
          if (!(this.access & 8192)) {
            this.title = 'Reporte Conciliación de Cadena';
            this.screenHeight = (window.screen.height);
            this.getTokenReport(environment.PowerBiConciliacionCadena);
          }
        } else if (params.id == 'bc') {
          if (!(this.access & 32768)) {
            this.title = 'Reporte BenchMarking de Cadena';
            this.screenHeight = (window.screen.height);
            this.getTokenReport(environment.PowerBiBenchMarkingCadena);
          }

        } else if (params.id == 'bp') {
          if (!(this.access & 65536)) {
            this.title = 'Reporte BenchMarking de Proveedores';
            this.screenHeight = (window.screen.height);
            this.getTokenReport(environment.PowerBiBenchMarkingProveedores);
          }

        } else if (params.id == 'gpp') {
          if (!(this.access & 1048576)) {
            this.title = 'Reporte General de Proveedores Premium';
            this.screenHeight = (window.screen.height);
            this.getTokenReport(environment.PowerBiGeneralProveedoresPremium);
          }

        } else if (params.id == 'cpp') {
          if (!(this.access & 262144)) {
            this.title = 'Reporte Conciliación de Proveedores Premium';
            this.screenHeight = (window.screen.height);
            this.getTokenReport(environment.PowerBiConciliacionProveedoresFreemium);
          }

        } else if (params.id == 'cpf') {
          if (!(this.access & 131072)) {
            this.title = 'Reporte Conciliación de Proveedores Freemium';
            this.screenHeight = (window.screen.height);
            this.getTokenReport(environment.PowerBiConciliacionProveedoresFreemium);
          }

        } else if (params.id == 'gpf') {
          if (!(this.access & 524288)) {
            this.title = 'Reporte General de Proveedores Freemium';
            this.screenHeight = (window.screen.height);
            this.getTokenReport(environment.PowerBiGeneralProveedoresFreemium
              );
          }
        }
      });
  }

  showReport() {
    let embedUrl = this.data.rptUrl;
    let embedReportId = this.data.rptId;
    let config = {
      type: 'report',
      accessToken: this.data.embedToken.token,
      tokenType: models.TokenType.Embed,
      embedUrl: embedUrl,
      id: embedReportId,
      settings: {}
    };
    let reportContainer = this.reportContainer.nativeElement;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    powerbi.reset(reportContainer);
    let report = powerbi.embed(reportContainer, config);
    report.off("loaded");
    report.on("loaded", () => {
    });
    report.on("error", () => {
    });
  }

  getTokenReport(reportId: string) {
    let count = 1;
    let dialogSpiner: DialogSpinerComponent = new DialogSpinerComponent(this.dialog);
    dialogSpiner.openDialog();

    this.reportsApiService.get(reportId)
      .subscribe(resultData => {
        this.data = resultData;
        console.warn(this.data);
        this.showReport();
        dialogSpiner.closeDialog();
      },
        error => {
          this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            dialogSpiner.closeDialog();
          });
        });
  }
}




