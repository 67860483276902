<label>{{title}}</label>
<div class="row">
  <div class="col-12">
    <div id="help" class="float-right">
      <button class="btn btn-primary" type="button" data-toggle="collapse" attr.data-target="#collapse{{tableId}}" aria-expanded="false" aria-controls="collapse">
        <i class="fas fa-filter" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="collapse mt-5" id="collapse{{tableId}}">
      <div class="mat-elevation-z8 p-2">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home{{tableId}}" role="tab" aria-controls="home" aria-selected="true">Filtro <i class="fas fa-ellipsis-v"></i></a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile{{tableId}}" role="tab" aria-controls="profile" aria-selected="false">Filtro Avanzado <i class="fas fa-ellipsis-v"></i></a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact{{tableId}}" role="tab" aria-controls="contact" aria-selected="false">Columnas <i class="fas fa-ellipsis-v"></i></a>
          </li>
        </ul>
        <div class="tab-content " id="myTabContent">
          <div class="tab-pane fade show active" id="home{{tableId}}" role="tabpanel" aria-labelledby="home-tab">
            <mat-form-field appearance="standard">
              <mat-label>Filtrar</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar" #input>
          </mat-form-field>
          </div>
          <div class="tab-pane fade" id="profile{{tableId}}" role="tabpanel" aria-labelledby="home-tab">
            <div class="row">
              <div class="col-1">
                <p>Agregar:</p>
                <button class='btn btn-md' type="button" style='background-color:transparent;' (click)="createFilterItem('suma')">
                  <div style='text-align:center;'><i class="fas fa-plus"></i></div>
                </button>
              </div>
              <div class="col-2">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
              <form [formGroup]="filterForm" (ngSubmit)="onSubmit($event)">
                <p>Condición:</p>
                <select  class="form-control form-control-sm col-2" formControlName="condition">
                  <option selected value=""></option>
                  <option selected value="And">Todos</option>
                  <option value="Or">Cualquiera</option>
                </select>
                <ng-container  *ngFor="let items of newFilterArray; let i = index">
                  <div id="divFilter{{items}}" class="mat-elevation-z2" style="padding: 0.5em;">
                    <div class="row">
                    <div class="col-md-4">
                      <!-- -->
                    <h6>Columna:</h6>
                    <select class="form-control form-control-sm" (change)="typeInputFilter($event, i)" formControlName="column{{items}}">
                      <ng-container *ngFor="let item of columns" >
                        <option *ngIf="!item.excludeFilter && item.show == true" [attr.typeInput]="item.type"  value="{{item.columnDef}}">{{ item.header }}</option>
                      </ng-container>
                    </select>
                    </div>
                    <div class="col-md-4">
                    <h6>Criterio:</h6>
                    <select  class="form-control form-control-sm" formControlName="criteria{{items}}" id="selectedFilter_{{i}}" >
                      <option value="=">Igual que</option>
                      <option value="like">Contiene</option>
                      <option value="not like">No contiene</option>
                      <option value="is null">Es nulo</option>
                      <option value="is not null">No es nulo</option>
                      <option value="like '0%'">Empiece por</option>
                      <option value="not like '0%'">No empiece por</option>
                      <option value="like '%0'">Termine por</option>
                      <option value="not like '%0'">No termina por</option>
                      <option value=">=">Desde</option>
                      <option value="<=">Hasta</option>
                    </select>
                    </div>
                    <div class="col-md-3">
                    <h6>Valor:</h6><input class="form-control form-control-sm" id="inputTypeFilter_{{i}}" type="text" value = "" formControlName="value{{items}}"/>
                    </div>
                    <div class="col-md-1">
                    <h6>Acción:</h6><button id="btnDeleteFilter{{items}}" class="form-control form-control-sm" (click)="eliminarElemento('divFilter'+items, items)"><i class="material-icons">delete_forever</i></button>
                    </div>
                    </div>
                  </div>
                </ng-container>
                <button class="form-control form-control-sm col-md-2 float-right btn btn-primary mt-3" type="submit" id="submitFilter">Filtrar</button>
              </form>
            </div>
          </div>
          </div>
          <div class="tab-pane fade" id="contact{{tableId}}" role="tabpanel" aria-labelledby="contact-tab">
            <p>Columnas:</p>
              <ng-container  *ngFor="let column of columns" [cdkColumnDef]="column.columnDef">
                <ng-container *ngIf="!column.excludeFilter">
                  <div class="form-group form-check">
                    <input type="checkbox" (change)="hideShowColumn(column, $event)" [disabled]="column.disabled" [checked]="column.show === false ? 0 : 1" class="form-check-input">
                    <label class="form-check-label form-control-sm">{{ column.header }}</label>
                  </div>
                </ng-container>
              </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p></p>
<div class="mat-elevation-z8">
<section class="table-responsive">
  <table id="{{tableId}}" class="table table-condensed table-striped" mat-table [dataSource]="dataSource" matSort>
    <ng-container  *ngFor="let column of columns" [cdkColumnDef]="column.columnDef">
      <ng-container  *ngIf="column.type === 'mat-icon'">
        <mat-header-cell class="{{column.show === false ?  'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
        <mat-cell  *cdkCellDef="let row" class="{{column.show === false ?  'd-none' : column.class }}">
        <mat-icon aria-hidden="false">{{ column.cell(row) }}</mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'font-icon'">
        <mat-header-cell class="{{column.show === false ?  'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="{{column.show === false ?  'd-none' : column.class }}">
          <i class="{{column.cell(row)}}"></i>
        </mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'text'">
        <mat-header-cell class="{{column.show === false ?  'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="{{column.show === false ?  'd-none' : column.class }}">
          <input  [disabled]="column.disabled" class="column.show === false ?  'd-none' : form-control form-control-sm" type="text" placeholder="{{ column.header }}" value="{{ column.cell(row) }}"/>
        </mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'select'">
        <mat-header-cell class="{{column.show === false ?  'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="{{column.show === false ?  'd-none' : column.class }}">
          <select [disabled]="column.disabled" class="column.show === false ?  'd-none' : form-control form-control-sm">
            <option  *ngFor="let item of column.data" [selected]="item.value === column.cell(row)" value="{{item.value}}">{{ item.text}}</option>
           </select>
        </mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'check'">
        <mat-header-cell class="{{ column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row; let i = index" class="{{ column.show === false ?  'd-none' : column.class }}">
          <div class="column.show === false ?  'd-none' : form-group form-check" >
            <input type="checkbox" [disabled]="column.disabled" [checked]="column.cell(row) === '1'" class="form-check-input" (change)="rowSelected(row, $event, i)" >
            <label class="form-check-label" *cdkHeaderCellDef>{{ column.header }}</label>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'menu'">
        <mat-header-cell class="{{ column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="{{ column.class }}">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-template let-customer="customer" matMenuContent>
              <ng-container  *ngFor="let menuItem of column.menu"  >
                  <button mat-menu-item [disabled]="menuItem.disabled" *ngIf="menuItem.show == true" (click)="menuItem.callback(column.cell(row), row)" >
                    <mat-icon>{{menuItem.icon}}</mat-icon>
                    <span>{{menuItem.label}}</span>
                  </button>
            </ng-container>
          </ng-template>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'html'">
        <mat-header-cell class="{{ column.show === false ?  'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row" class="{{ column.show === false ?  'd-none' : column.class }}">
          <div [innerHTML]="column.cell(row)"></div>
        </mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'label'">
          <mat-header-cell  class="{{ column.show === false ? 'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="{{ column.show === false ?  'd-none' : column.class }}">{{ column.cell(row) =='null'  ? '' : column.cell(row).toLowerCase() }}</mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'date'">
          <mat-header-cell  class="{{ column.show === false ? 'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="{{ column.show === false ?  'd-none' : column.class }}">{{ column.cell(row) }}</mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'number'">
          <mat-header-cell  class="{{ column.show === false ? 'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="{{ column.show === false ?  'd-none' : column.class }}">{{ column.cell(row) }}</mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'phone'">
          <mat-header-cell  class="{{ column.show === false ? 'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="{{ column.show === false ?  'd-none' : column.class }}">{{ column.cell(row) }}</mat-cell>
      </ng-container>
      <ng-container  *ngIf="column.type === 'status'">
          <mat-header-cell  class="{{ column.show === false ? 'd-none' : column.class }}" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row" class="{{ column.show === false ?  'd-none' : column.class }}"> <span class="badge bg-{{ column.cell(row) == 'activo' || column.cell(row) == 'aprobado' ? 'success': column.cell(row) == 'pendiente'? 'warning' : 'secondary' }}">{{ column.cell(row) }}</span></mat-cell>
      </ng-container>
    </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
</section>
</div>
