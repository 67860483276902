import { map } from 'rxjs/operators';
import { causalApiService } from 'src/app/services/Products/causalApiService.module';
import { claimsApiService } from './../../services/users/claimsApiService.module';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {
  data: any;
  interval: any;
  subscribeTimer: any;
  access: number = 0;
  dataCheckODCAlert: string = '';

  constructor(private notificationsApiService: notificationsApiService,
    private claimsApiService: claimsApiService,
    private request: causalApiService
  ) {

    this.notificationsApiService.obtenerNotificacionesTop10();
    this.obtenerPermisos();

    this.CheckODCAlert();
  }

  CheckODCAlert() {
    var dict: any = [];
    const today = new Date();
    today.setDate(today.getDate() + 5);
    this.request.getAlertEarly('{"items":[{"column":"fechaMaximaEntrega","criteria":"=","value":"' + formatDate(today, 'yyyy-MM-dd', 'en-US') + '"}, {"column":"fechaCausal","criteria":"is null","value":""}],"condition":"And"}', 'asignarCausal')
      .subscribe((data: any) => {

        if (data.resultObject.length > 0) {

          let odc: string = '';
          let cont: number = 0;
          data.resultObject.forEach((element: any) => {
            cont++;

            if (dict[element.orden]) {
              dict[element.orden].push({ 'item': cont, 'ODC': element.orden, 'GTIN': element.productoId, 'Product': element.producto });
            }
            else {
              dict[element.orden] = [];
              dict[element.orden].push({ 'item': cont, 'ODC': element.orden, 'GTIN': element.productoId, 'Product': element.producto });
            }

          });

          Object.keys(dict).forEach((element: any) => {

            Object.values(dict[element]).forEach((item: any) => {

              this.dataCheckODCAlert += ' GLN Producto: ' + item.GTIN + ' Nombre Producto: ' + item.Product + ', ';


            });
            var n = this.dataCheckODCAlert.lastIndexOf(",");
            this.dataCheckODCAlert = this.dataCheckODCAlert.substring(0, n);

            this.notificationsApiService.InsertarNotificaciones('Ordene de Compra No.: ' + element + ' por Vencerse en 5 Dias', this.dataCheckODCAlert, 'product/asigns');
          });
        }

      });
    const today2 = new Date();
    today2.setDate(today2.getDate());
    this.request.getAlertEarly('{"items":[ {"column":"causalId","criteria":"is not null","value":""},{"column":"fechaCausal","criteria":"=","value":"' + formatDate(today2, 'yyyy-MM-dd', 'en-US') + '"}],"condition":"And"}', 'aprobarCausal')
      .subscribe((data: any) => {

        if (data.resultObject.length > 0) {

          let cont: number = 0;
          data.resultObject.forEach((element: any) => {
            cont++;

            if (dict[element.orden]) {
              dict[element.orden].push({ 'item': cont, 'ODC': element.orden, 'GTIN': element.productoId, 'Product': element.producto });
            }
            else {
              dict[element.orden] = [];
              dict[element.orden].push({ 'item': cont, 'ODC': element.orden, 'GTIN': element.productoId, 'Product': element.producto });
            }

          });

          Object.keys(dict).forEach((element: any) => {

            Object.values(dict[element]).forEach((item: any) => {

              this.dataCheckODCAlert += ' GLN Producto: ' + item.GTIN + ' Nombre Producto: ' + item.Product + ', ';


            });
            var n = this.dataCheckODCAlert.lastIndexOf(",");
            this.dataCheckODCAlert = this.dataCheckODCAlert.substring(0, n);

            this.notificationsApiService.InsertarNotificaciones('Ordene de Compra No.: ' + element + ' con causal asignados pendiente por su evaluación', this.dataCheckODCAlert, 'product/approvals');
          });
        }

      });
  }

  obtenerPermisos() {
    this.claimsApiService.ObtenerNumeroPermisoModulo('Notificaciones')
      .subscribe((data: any) => {
        this.access = data.resultObject[0].value.numberPermission;

        if ((this.access & 4)) {
          this.oberserableTimer();
        }
      });
  }

  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {

      this.data = this.notificationsApiService.notificationsView;
      this.subscribeTimer = val;
    });
  }

  startTimer() {
    this.interval = setInterval(() => {

    }, 1000)
  }
  ngOnInit(): void {

    const showNavbar = (toggleId: any, navId: any, bodyId: any, headerId: any) => {
      const toggle = document.getElementById(toggleId),
        nav = document.getElementById(navId),
        bodypd = document.getElementById(bodyId),
        headerpd = document.getElementById(headerId)

      if (toggle && nav && bodypd && headerpd) {
        toggle.addEventListener('click', () => {
          nav.classList.toggle('show-app')
          toggle.classList.toggle('fa-times')
          bodypd.classList.toggle('body-pd-app')
          headerpd.classList.toggle('body-pd-app')
        })
      }
    }

    showNavbar('header-toggle-app', 'nav-bar-app', 'body-pd-app', 'header-app')

    const linkColor = document.querySelectorAll('.nav_link-app')

    function colorLink(this: any) {
      if (linkColor) {
        linkColor.forEach(l => l.classList.remove('active-app'))
        this.classList.add('active-app')
      }
    }
    linkColor.forEach(l => l.addEventListener('click', colorLink))

    $("#header-toggle-app").on("click", function () {
      $(".collapse").removeClass("show");
    });
  }

  openSideBar() {
    if (!$("#nav-bar-app").hasClass("show-app")) {
      $("#header-toggle-app").click();
    }
  }



}
