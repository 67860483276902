import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserDataService } from '../user-data.service';

@Injectable({
  providedIn: "root"
})

export class reportsApiService {
  private apiUrl =  '';
  private UrlPowerBI =  '';
  public notificationsView :any = [];
  userId:string = '';

  constructor(private http: HttpClient, private userData : UserDataService) {
    this.userData.getData$()
    .subscribe((data:any)=>{
      this.userId = data.userId;
    });
   }

  public get(reportId: string): Observable<any> {
    this.apiUrl = environment.reportToken;
    this.UrlPowerBI = environment.PowerBiUrl;
    console.warn(this.apiUrl + this.UrlPowerBI +reportId);
    return this.http.get<any>(this.apiUrl + this.UrlPowerBI + reportId);
  }
}
