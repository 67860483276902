import { UserDataService } from './../../../services/user-data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { roleModels } from '../../../models/usersModels/role.module';
import { rolesApiService } from 'src/app/services/users/rolesApiService.module';
import { snackBar } from 'src/app/Utils/snackBar';
import { Guid } from 'guid-typescript';
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.css']
})
export class RoleCreateComponent implements OnInit {
  accion:string = "view";
  title: string = '';
  rolId: string = '';
  dialogSpiner!: DialogSpinerComponent;
   _roleModels: roleModels = {
    id: '',
    name: '',
    normalizedName: '',
    concurrencyStamp: '',
    create: new Date,
    update: new Date
  };
  access:number = 0;

  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  constructor(private rolesApiService: rolesApiService,
    private router: Router,
    private snack: snackBar,
    private ActivatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private userData:UserDataService,
    private notificationsApiService : notificationsApiService) {
      this.userData.getAccess$()
      .pipe( takeUntil(this.ngUnsubscribe) )
      .subscribe((data:any)=>{
        this.access = data;
      });
      this.ActivatedRoute.params
      .subscribe((params:any) => {
        if(params.accion){
          this.accion = params.accion;
        }
        if(params.accion == 'edit'){
          if(!(this.access & 2)){
            this.router.navigate(['/home']);
          }
        }else if(params.accion == 'view'){
          if(!(this.access & 4)){
            this.router.navigate(['/home']);
          }
        }else if(params.accion == 'create'){
          if(!(this.access & 1)){
            this.router.navigate(['/home']);
          }
        }else{
          this.router.navigate(['/home']);
        }
        if(params.id && (params.accion == 'edit' || params.accion == 'view')){
          this.rolId = params.id;
          this.getRol();
          this.title = this.accion == 'edit'? 'Editar' : ' Ver ';
        }
        else{
          this.title = 'Crear ';
        }
      });
    }

  ngOnInit(): void {

  }

  guardar(){
      if(this.accion == 'create'){
        this._roleModels.normalizedName == null ? '' : this._roleModels.normalizedName;
        this._roleModels.id = Guid.create().toString();
        this._roleModels.create = new Date();
      }

      this._roleModels.concurrencyStamp = new Date().toString();

      this._roleModels.update = new Date();
      let Titulo : string = '';
      Titulo = this.accion == 'create' ?  ' creado ' : ' editado ';
      Swal.fire({
        title: 'Desea '+(this.accion == 'create' ?  ' crear ' : ' editar ')+' este rol?',
        // text: 'Una vez realizada esta accion no se podra revertir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, aceptar',
        cancelButtonText: 'No, olvidalo',
        confirmButtonColor: "#00B398", 
        cancelButtonColor: "#FC4C02"
      }).then((result) => {
        if (result.isConfirmed) {
          //console.warn(this._roleModels);
          this.rolesApiService.post(this.accion, this._roleModels).subscribe(rolesResult =>{
            //console.log('insert role Result:'+ rolesResult);
            

            Swal.fire(
              {
                icon: (rolesResult.resultObject[0] ? 'success':'error'),
                title: (rolesResult.resultObject[0] ? 'Registro '+Titulo+'!':'Algo no esta bien'),
                text: rolesResult.resultObject[0] ? 'Registro '+Titulo+' con éxito':'Ha ocurrido un error al momento de '+Titulo +' el registro.',
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
              }
            )

            if(rolesResult.resultObject[0]){

              this.notificationsApiService.InsertarNotificaciones('Rol '+this._roleModels.name+ ' '+Titulo, 'El rol '+this._roleModels.name+' ha sido '+Titulo+' Correctamente', 'role');
              this.router.navigate(['/role']);
            }
            else{
              this.snack.openSnackBar('Ha ocurrido un error al crear el rol, favor verificar.', 'OK!');
              this.notificationsApiService.InsertarNotificaciones('Error al '+Titulo+' el rol '+this._roleModels.name, 'Ha ocurrido un error al momento de '+Titulo+' el rol '+this._roleModels.name, 'role');
              this.router.navigate(['/role']);
            }
          },
          error => {
            this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'OK!');
            // this.snack.snackBarRef.afterDismissed().subscribe(() => {
            //   console.log('::::::::::The snack-bar was dismissed'); // KYBC.Borrar.
            // });
          });

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            {
              icon: 'error',
              title: "Cancelado",
              text: "Elemento no "+Titulo,
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
          })
        }
      });

      
  }
  getRol(){

    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
    this.dialogSpiner.openDialog();
    //this.rolId =  this.ActivatedRoute.snapshot.params.id.toString();
    // console.log('rolidsend:'+this.ActivatedRoute.snapshot.params.id.toString());
    this.rolesApiService.get(this.rolId).subscribe(resultDataRol =>{
      this._roleModels.id = this.rolId;
      this._roleModels.name = resultDataRol.resultObject[0].name;
      this._roleModels.normalizedName = resultDataRol.resultObject[0].normalizedName;
      this.dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
      });
    });

  }
}


