
<div class="container">
    <div class="row">
        <div class="col">
            <p></p>
            <mat-card>
                <mat-card-title>
                    {{title}} Usuario <div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
                </mat-card-title>
                <app-bread-crumb></app-bread-crumb>
            </mat-card>
        </div>
        <p></p>
    </div>
    <div class="row">
        <div class="col">
            <mat-card>
              <!-- <form>

              </form> -->
                <div class="form-row">
                    <div class="form-group col">
                        <label for="">Nombre y Apellido <span class="text-danger">*</span></label>
                        <input maxlength="255" [disabled]="accion == 'view'" class="form-control" type="text" [(ngModel)]="this._userModels.nombreApellido" #nombreApellido="ngModel" required  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"/>
                        <span *ngIf="nombreApellido.invalid && (nombreApellido.dirty || nombreApellido.touched)" class="text-danger">
                            <span *ngIf="nombreApellido.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="nombreApellido.errors?.pattern">
                                * Caracteres no Permitidos, ingrese solo letras.
                            </span>
                        </span>
                    </div>
                    <div class="form-group col">
                        <label for="">Correo / Usuario <span class="text-danger">*</span></label>
                        <input maxlength="255" [disabled]="accion == 'view'" class="form-control" [(ngModel)]="this._userModels.email" #email="ngModel" required pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" type="email" />
                        <span *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                            <span *ngIf="email.errors?.required">
                                * El Campo es requerido.
                            </span>
                            <span *ngIf="email.errors?.pattern">
                                * El Correo no tiene el formato correcto, ejemplo: info@logyca.co.
                            </span>
                        </span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label for="">Contraseña <span *ngIf="required" class="text-danger">*</span></label>
                        <input [disabled]="accion == 'view'" maxlength="10" class="form-control" [(ngModel)]="this._userModels.passwordHash" id="contraseña" type="password" #passwordHash="ngModel" required="{{required}}" pattern="^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{5,10}$"/>
                        <span *ngIf="passwordHash.invalid && (passwordHash.dirty || passwordHash.touched)" class="text-danger">
                            <span *ngIf="passwordHash.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="passwordHash.errors?.pattern">
                                * La Contraseña no tiene el formato correcto, Dígitos, minúsculas y mayúsculas.
                            </span>
                        </span>
                    </div>
                    <div class="form-group col">
                        <label for="">Confirmar Contraseña <span *ngIf="required" class="text-danger">*</span></label>
                        <input [disabled]="accion == 'view'" maxlength="10" class="form-control" [(ngModel)]="passwordHashConfirm" #passwordConfirm="ngModel" type="password" required="{{required}}" pattern="^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{5,10}$"/>
                        <span *ngIf="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)" class="text-danger">
                            <span *ngIf="passwordConfirm.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="passwordConfirm.errors?.pattern">
                                * La Contraseña no tiene el formato correcto, Dígitos, minúsculas y mayúsculas.
                            </span>
                        </span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <!-- <label for="">Razón Social <span class="text-danger">*</span></label>
                        <input [disabled]="accion == 'view'" class="form-control" [(ngModel)]="this._userModels.empresa" #empresa="ngModel" required/> -->
                        
                        <label for="">GLN Cadena - Razón Social <span class="text-danger">*</span></label>
                       
                        <select [disabled]="accion == 'view'" id="cadena" #empresa="ngModel" required class="form-control empresa" [(ngModel)]="this._userModels.gln_cadena" (change)="onChange($event)" >
                            <option *ngFor="let item of listCadenas" value="{{item.id}}">{{ 
                                item.id }} - {{ item.cadena }}</option>
                        </select>

                        <span *ngIf="empresa.invalid && (empresa.dirty || empresa.touched)" class="text-danger">
                            <span *ngIf="empresa.errors?.required">
                                * el Campo es requerido.
                            </span>
                        </span>
                    </div>
                    <div class="form-group col">
                        <label for="">Teléfono </label>
                        <input [disabled]="accion == 'view'" class="form-control" [(ngModel)]="this._userModels.phoneNumber" type="number" min="9999999" max="999999999" #phoneNumber="ngModel"/>
                        <span *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)" class="text-danger">
                            <span *ngIf="phoneNumber.errors?.pattern">
                                * El Número de teléfono no tiene el formato correcto,ejemplo: 57300000, 57300000.
                            </span>
                        </span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label for="">Nit <span class="text-danger">*</span></label>
                        <input [disabled]="accion == 'view'" class="form-control" [(ngModel)]="this._userModels.nit" #nit="ngModel" required pattern="(^[0-9]{8,9}[-]+[0-9]{1})"/>
                        <span *ngIf="nit.invalid && (nit.dirty || nit.touched)" class="text-danger">
                            <span *ngIf="nit.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="nit.errors?.pattern">
                                * El Nit no tiene el formato correcto, ejemplo: 100000000-1, con una longitud de 8 números mínimo máximo 9 seguido de un '-' y un dígito numérico de verificación.
                            </span>
                        </span>
                    </div>
                    <!-- <div class="form-group col">
                        <label for="">GLN Cadena <span class="text-danger">*</span></label>
                        <span></span>
                        <input [disabled]="accion == 'view'" type="number" class="form-control" [(ngModel)]="this._userModels.gln_cadena" #gln_cadena="ngModel" required pattern="^[0-9]+$"/>
                        <span *ngIf="gln_cadena.invalid && (gln_cadena.dirty || gln_cadena.touched)" class="text-danger">
                            <span *ngIf="gln_cadena.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="gln_cadena.errors?.pattern">
                                * El GLN de la Cadena no tiene el formato correcto, ejemplo: 16516464.
                            </span>
                        </span> 
                    </div>-->
                    <div class="form-group col">
                        <label for="">GLN Proveedor</label>
                        <input [disabled]="accion == 'view'" type="number" class="form-control" [(ngModel)]="this._userModels.gln_proveedor" #gln_proveedor="ngModel" pattern="^[0-9]+$"/>
                        <span *ngIf="gln_proveedor.invalid && (gln_proveedor.dirty || gln_proveedor.touched)" class="text-danger">
                            <!-- <span *ngIf="gln_proveedor.errors?.required">
                                * el Campo es requerido.
                            </span> -->
                            <span *ngIf="gln_proveedor.errors?.pattern">
                                * El GLN del Proveedor no tiene el formato correcto, ejemplo: 16516464.
                            </span>
                        </span>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label for="">Dirección</label>
                        <textarea maxlength="500" [disabled]="accion == 'view'" class="form-control" [(ngModel)]="this._userModels.direccion" id="" cols="30" rows="3"></textarea>

                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label for="" class="text-muted">Roles donde el Usuario esta Asginado</label>
                        <table mat-table [dataSource]="data" class="mat-elevation-z8">
                            <!-- Position Column -->
                            <ng-container matColumnDef="item">
                              <th mat-header-cell *matHeaderCellDef class="col-xs-1"> No. </th>
                              <td mat-cell *matCellDef="let element"> {{element.item}} </td>
                            </ng-container>
                          
                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef class="col-xs-2"> Nombre del Rol </th>
                              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>
    
                            <!-- Name Column -->
                            <ng-container matColumnDef="normalizedName">
                                <th mat-header-cell *matHeaderCellDef> Descripción </th>
                                <td mat-cell *matCellDef="let element"> {{element.normalizedName}} </td>
                              </ng-container>
                                      
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>
                    </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <span *ngIf="!nombreApellido.errors?.required && !email.errors?.required &&
                    !passwordHash.errors?.required && !passwordConfirm.errors?.required &&
                    !empresa.errors?.required && !nit.errors?.required && !nombreApellido.errors?.pattern && !email.errors?.pattern && !passwordHash.errors?.pattern && !passwordConfirm.errors?.pattern && !empresa.errors?.pattern && !nit.errors?.pattern && !gln_proveedor.errors?.pattern && accion != 'view'; else elseTemplate">
                        <button [disabled]="false" mat-raised-button type="submit" (click)="guardar();" class="btn-primary" >Guardar</button>
                    </span>
                    <ng-template #elseTemplate>
                      <button mat-raised-button type="submit" [disabled]="true" class="btn-primary" >Guardar</button>
                    </ng-template>
                              <span class="text-muted mx-2" >|</span>
                    <button type="button" routerLink="/user" class="btn btn-default">Regresar</button>
                  </div>
                </div>

            </mat-card>
        </div>
    </div>
</div>
