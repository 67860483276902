<div class="container">
    <div class="row">
        <div class="col p-2">
            <mat-card class="">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title>
                        Asignación de Usuario y Permisos<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
                        </mat-card-title>
                        <app-bread-crumb></app-bread-crumb>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Nombre del Rol</label>
                            <span class="form-control">{{rolName}}</span>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="">Descripción</label>
                            <span style="height:110px;" class="form-control" >{{rolDescription}}</span>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col p-2">
            <mat-card class="">
                <!-- <mat-card-subtitle>Asignar Usuarios al Rol</mat-card-subtitle> -->
                <app-datatable [tableId]="tableUserId" [data]="dataUsers" [columns]="columnsUsers" [title]="titleTableUser" (eventFilter)="filterAdvUser($event)" (eventRowSelected)="itemsSelectedUser($event)" ></app-datatable>
                <br/>
            <div class="text-muted text-right">
                <button type="button" (click)="assginSimultUser()" class="btn btn-primary">Asginar</button>
                <span>|</span>
                <button type="button" (click)="desassginSimultUser()" class="btn btn-danger">Desasignar</button>
            </div>
            </mat-card>
            
        </div>
    </div>
    <div class="row">
        <div class="col p-2">
            <mat-card class="">
                <app-datatable [tableId]="tableClaimsId" [data]="dataClaims" [columns]="columnsClaims" [title]="titleTableClaims" (eventFilter)="filterAdvRole($event)" (eventRowSelected)="itemsSelectedClaims($event)"></app-datatable>
                <br/>
            <div class="text-muted text-right">
                <button type="button" (click)="assginSimultClaims()" class="btn btn-primary">Asginar</button>
                <span>|</span>
                <button type="button" (click)="desassginSimultClaims()" class="btn btn-danger">Desasignar</button>
            </div>
            </mat-card>
            
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="text-muted text-right">
                <button type="button" routerLink="/role" class="btn btn-default">Regresar</button>
            </div>
        </div>
    </div>
</div>


