import { UserDataService } from './../../../services/user-data.service';
import { snackBar } from './../../../Utils/snackBar';
import { Router } from '@angular/router';
import { causalApiService } from 'src/app/services/Products/causalApiService.module';
import { causalsModels } from '../../../models/productsModels/causals.module';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ConstantsApp } from 'src/app/Utils/ConstantsApp';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-causal-create',
  templateUrl: './causal-create.component.html',
  styleUrls: ['./causal-create.component.css']
})
export class CausalCreateComponent implements OnInit {

  causalsModels: causalsModels ={
    id: 0,
    causal: '',
    descripcion: '',
    impactoIndicador: '',
    asignadaPor: '',
    responsable: '',
    usuarioId: '',
    fechaCreacion: new Date()

  };

  accion: string = '';
  title: string = '';
  causalId: number = 0;
  isDisabled: string = '';
  listTypeEntity: any = ConstantsApp.ENTITY_TYPE_NAMES;
  dialogSpiner!: DialogSpinerComponent;
  access:number = 0;
  protected ngUnsubscribe:Subject<void> = new Subject<void>();

  constructor(public causalApiService: causalApiService,
     public router: Router,
     public snack: snackBar,
     private ActivatedRoute: ActivatedRoute,
     public dialog: MatDialog, private userData:UserDataService,
     private notificationsApiService: notificationsApiService) {
      this.userData.getAccess$()
      .pipe( takeUntil(this.ngUnsubscribe) )
      .subscribe((data:any)=>{
        this.access = data;
      });
      this.dialogSpiner = new DialogSpinerComponent(this.dialog);
      this.causalId = this.ActivatedRoute.snapshot.params.id.toString();
      this.accion = this.ActivatedRoute.snapshot.params.accion.toString();
      if(this.accion == 'edit'){
        if(!(this.access & 2)){
          this.router.navigate(['/home']);
        }
      }
      else if(this.accion == 'view'){
        if(!(this.access & 4)){
          this.router.navigate(['/home']);
        }
      }
      else if(this.accion == 'create'){
        if(!(this.access & 1)){
          this.router.navigate(['/home']);
        }
      }
      else{
        this.router.navigate(['/home']);
      }
        if(this.accion == 'view')
        {

          this.title = 'Ver';
          this.isDisabled = 'true';
          this.obtener(this.causalId);
        }
        else if(this.accion == 'edit'){
          this.title = 'Editar';
          this.isDisabled = 'false';
          this.obtener(this.causalId);
        }
        else if(this.accion == 'create'){
          this.title = 'Crear';
          this.isDisabled = 'false';
        }


    }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  guardar(){

    let Titulo = this.accion == 'create' ? ' creado ' : ' editado ';
    Swal.fire({
      title: 'Desea '+(this.accion == 'create' ?  ' crear ' : ' editar ')+' este causal?',
      text: 'Esto afectara a todos los registros relacionados con el causal, ¿esta seguro de continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aceptar',
      cancelButtonText: 'No, olvidalo',
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
      //console.warn(sessionStorage.getItem('userId'));
      //this.causalsModels.usuarioId = sessionStorage.getItem('userId') || 'NoUserYet';
      this.causalsModels.descripcion = this.causalsModels.descripcion == null ? '' : this.causalsModels.descripcion;
      if(this.accion != 'view'){
        this.dialogSpiner.openDialog();
        this.causalApiService.post(this.causalsModels, this.accion).subscribe(causalResult =>{
          Swal.fire(
            {
              icon: (causalResult.resultObject[0].value.succeeded ? 'success':'error'),
              title: (causalResult.resultObject[0].value.succeeded ? 'Registro '+Titulo+'!':'Algo no esta bien'),
              text: causalResult.resultObject[0].value.msg,
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          )

          if(causalResult.resultObject[0].value.succeeded){
            this.dialogSpiner.closeDialog();

            this.notificationsApiService.InsertarNotificaciones('Causal '+this.causalsModels.causal+' '+Titulo, 'El causal '+this.causalsModels.causal+' ha sido '+Titulo+' Correctamente', 'causal');
            this.router.navigate(['/causal']);
          }
          else{
            this.snack.openSnackBar('Ha ocurrido un error al guardar el causal, favor verificar.', 'OK!');
            this.dialogSpiner.closeDialog();
            this.notificationsApiService.InsertarNotificaciones('Error al '+Titulo+' el causal'+this.causalsModels.causal, 'Ha ocurrido un error al momento de '+Titulo+' el causal '+this.causalsModels.causal, 'causal');
          }
        },
        error => {
          this.snack.openSnackBar('Ha ocurrido un error al guardar la información.', 'OK!');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
            //console.log('::::::::::Error crear causal'); // KYBC.Borrar.
          });
        });
      }

      } else if (result.dismiss === Swal.DismissReason.cancel)
      {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Elemento no "+Titulo,
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        })
      }
    });

  }
    




  obtener(id: number){
    //this.causalsModels.usuarioId = sessionStorage.getItem('userId') || 'NoUserYet';
    this.dialogSpiner.openDialog();
    this.causalApiService.get(id).subscribe(causalResult =>{
      console.log(causalResult);
      if(causalResult.resultObject[0].id){

        //this.causalsModels = causalResult[0];
        this.causalsModels.id = causalResult.resultObject[0].id;
        this.causalsModels.causal = causalResult.resultObject[0].causal;
        this.causalsModels.descripcion = causalResult.resultObject[0].descripcion == null ? '' :causalResult.resultObject[0].descripcion;
        this.causalsModels.impactoIndicador = causalResult.resultObject[0].impactoIndicador;
        this.causalsModels.responsable = causalResult.resultObject[0].responsable;
        this.causalsModels.asignadaPor = causalResult.resultObject[0].asignadaPor;
        this.causalsModels.usuarioId = causalResult.resultObject[0].usuarioId;


        this.dialogSpiner.closeDialog();
        //this.router.navigate(['/causal']);

      }
      else{
        this.snack.openSnackBar('Ha ocurrido un error al obtener el causal, favor verificar.', 'OK!');
        this.dialogSpiner.closeDialog();
      }
    },
    error => {
      this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'OK!');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        //console.log('::::::::::Error obtener causal'); // KYBC.Borrar.
        this.dialogSpiner.closeDialog();
      });
    });
  }

}
