import { AuditoriaComponent } from './view/auditoria/auditoria/auditoria.component';
import { AuditoriaViewComponent } from './view/auditoria/auditoria-view.component';
import { CategoryCargarMasivaComponent } from './view/category-cargar-masiva/category-cargar-masiva.component';
import { NotificationListComponent } from './view/notification-list/notification-list.component';
import { InconstrutionComponent } from './view/inconstrution/inconstrution.component';
import { CategoryCreateComponent } from './view/category-create/category-create.component';
import { CategoryComponent } from './view/category/category.component';
import { NotFoundComponent } from './view/not-found/not-found.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AccessGuard } from './guards/access.guard';
import { AuthGuard } from './guards/auth.guard';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { LocationComponent } from './view/provider/location/location.component';
import { ProviderComponent } from './view/provider/provider/provider.component';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './view/Product/product/product.component';
import { RoleViewComponent } from './view/role/role-view/role-view.component';
import { RoleCreateComponent } from './view/role/role-create/role-create.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { userViewComponent } from './view/user/usersView/userView.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserCreateComponent } from './view/user/user-create.component';
import { RoleClaimsComponent } from './view/role/role-claims/role-claims.component';
import { RoleUsersComponent } from './view/role/role-users/role-users.component';
import { CausalCreateComponent } from './view/causal/causal-create/causal-create.component';
import { CausalViewComponent } from './view/causal/causal-view/causal-view.component';
import { UserComponent } from './view/user/user/user.component';
import { RoleComponent } from './view/role/role/role.component';
import { CausalComponent } from './view/causal/causal/causal.component';
import { CargaMasivaComponent } from './view/causal/carga-masiva/carga-masiva.component';
import { ProductViewComponent } from './view/Product/product-view/product-view.component';
import { ProductCausalapprovalComponent } from './view/Product/product-causalapproval/product-causalapproval.component';
import { CatalogueComponent } from './view/provider/catalogue/catalogue.component';
import { AuditoriaVerComponent } from './view/auditoria/auditoria-ver/auditoria-ver.component';
import { InformesGeneralComponent } from './view/informes-general/informes-general.component';
import { InformesConciliacionComponent } from './view/informes-conciliacion/informes-conciliacion.component';
import { CatalogueViewComponent } from './view/provider/catalogue-view/catalogue-view.component';
import { UserChangePassComponent } from './view/user/user-change-pass/user-change-pass.component';

const routes: Routes = [

  {path: '', redirectTo: 'login', pathMatch: 'full'},

  {path: 'login', component: LoginComponent, data: { breadcrumb: 'Acceso'}, canActivate: [AuthGuard]},
  {path: 'password-reset', component: PasswordResetComponent, data: { breadcrumb: 'Acceso'}, canActivate: [AuthGuard]},
  {path: 'passwordchange/:token', component: UserChangePassComponent, data: { breadcrumb: 'Acceso'}, canActivate: [AuthGuard]},
  // {path: 'home', component: AppComponent, data: { breadcrumb: 'Inicio'}},

  // {
  //   path: '**',
  //   redirectTo: '/login',
  //   pathMatch: 'full'
  // },
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   // pathMatch: 'full'
  // },
  {
    path: '',
    component: AppLayoutComponent,
    data: { breadcrumb: 'Acceso'},
    children: [
      {path: 'construction', component: InconstrutionComponent},
      {path: 'home', component: HomeComponent, data: { breadcrumb: 'Inicio'}},
      {path: 'audit', component: AuditoriaComponent, data: { breadcrumb: 'Auditoria', module: 'Auditoria'},
      canActivate: [AccessGuard],
      children:[
        {path: '', component: AuditoriaViewComponent, data: { breadcrumb: 'Lista de Auditoria' }},
        {path: ':id', component: AuditoriaVerComponent, data: { breadcrumb: 'Auditoria' }},
      ]},
      {path: 'user', component: UserComponent, data: { breadcrumb: 'Usuarios', module: 'Usuarios'},
      canActivate: [AccessGuard],
      children:[
        {path: '', component: userViewComponent, data: { breadcrumb: 'Lista de Usuario' }},
        {path: ':id/:accion', component: UserCreateComponent, data: { breadcrumb: 'Usuario' }},
      ]},
      {path: 'role', component: RoleComponent, data: { breadcrumb: 'Roles', module: 'Seguridad'},
      canActivate: [AccessGuard],
      children:[
        {path: '', component: RoleViewComponent, data: { breadcrumb: 'Lista de Roles'}},
        {path: ':id/:accion', component: RoleCreateComponent, data: { breadcrumb: 'Rol'}},
        {path: 'rol/assign/:id', component: RoleClaimsComponent, data: { breadcrumb: 'Asignar Usuarios y Permisos'}},
      ]},
      {path: 'causal', component: CausalComponent, data: { breadcrumb: 'Causales', module: 'Causal'},
      canActivate: [AccessGuard],
      children:[
        {path: '', component: CausalViewComponent, data: { breadcrumb: 'Lista de Causales', module: 'Causales'}},
        {path: ':id/:accion', component: CausalCreateComponent, data: { breadcrumb: 'Causal'}},
        {path: 'carga', component: CargaMasivaComponent, data: { breadcrumb: 'Carga Masiva de Causales'}},
      ]},
      {path: 'notification/:pagina', component: NotificationListComponent, data: { breadcrumb: 'Notificaciones', module: 'Notificaciones'}},
      {path: 'notification/:pagina/:subpagina', component: NotificationListComponent, data: { breadcrumb: 'Notificaciones', module: 'Notificaciones'}},
      {path: 'notification/:pagina/:id/:accion', component: NotificationListComponent, data: { breadcrumb: 'Notificaciones',  module: 'Notificaciones'}},
      {path: 'product', component: ProductComponent, data: { breadcrumb: 'Productos', module: 'Productos'},
      canActivate: [AccessGuard],
      children:[
        {path: 'asigns', component: ProductViewComponent, data: { breadcrumb: 'Asignación de Causal a Producto'}},
        {path: 'approvals', component: ProductCausalapprovalComponent, data: { breadcrumb: 'Aprobación de Causal en Producto'}},
      ]},
      {path: 'provider', component: ProviderComponent, data: { breadcrumb: 'Proveedor', module: 'Proveedor'},
      canActivate: [AccessGuard],
      children:[
        {path: '', component: CatalogueViewComponent, data: { breadcrumb: 'Listado de Productos'}},
        // {path: 'locationView', component: LocationViewComponent, data: { breadcrumb: 'Listado de Localizaciones'}},
        {path: 'catalogue', component: CatalogueComponent, data: { breadcrumb: 'Carga Masiva de Productos'}},
        {path: 'location', component: LocationComponent,  data: { breadcrumb: 'Carga Masiva de Localizaciones'}},
      ]},
      {path: 'category', component: ProviderComponent, data: { breadcrumb: 'Categoria', module: 'Categoria'},
      canActivate: [AccessGuard],
      children:[
        {path: '', component: CategoryComponent, data: { breadcrumb: 'Categoria'}},
        {path: ':id/:accion', component: CategoryCreateComponent, data: { breadcrumb: 'Crear / Editar'}},
        {path: 'cargaCategory', component: CategoryCargarMasivaComponent, data: { breadcrumb: 'Carga masiva'}},
      ]},
      // {path: 'report', component: HomeComponent, data: { breadcrumb: 'Reportes', module: 'Reportes'},
      // canActivate: [AccessGuard],
      // children:[
      //   {path: 'generalnetwork/:id', component: InformesGeneralComponent, data: { breadcrumb: 'Reportes'}},
      // ]},
      {path: 'reports/generalnetwork/:id', component: InformesGeneralComponent, data: { breadcrumb: 'Reporte General',  module: 'Reporte General'}},
      // {path: 'reports/generalnetwork:id', component: InformesGeneralComponent, data: { breadcrumb: 'Reporte de Conciliación de Cadena',  module: 'Reporte de Conciliación de Cadena'}},
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
     {
       enableTracing: false,
       useHash: true,
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
