import { userModels } from './../../models/usersModels/user.module';
import { usersApiService } from './../../services/users/usersApiService.module';
import { auditoriaApiService } from '../../services/Auditoria/auditoriaApiService.module';
import { columns } from '../../models/table-column';
import { MatDialog } from '@angular/material/dialog';
import { snackBar } from '../../Utils/snackBar';
import { Router } from '@angular/router';
import { menuOptions } from '../../models/menu.interface';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { Subject } from 'rxjs';
import { UserDataService } from 'src/app/services/user-data.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-auditoria-view',
  templateUrl: './auditoria-view.component.html',
  styleUrls: ['./auditoria-view.component.css']
})
export class AuditoriaViewComponent implements OnInit {

  data:any  = [];
  titletable: string = 'Listado de Auditorias';
  access:number = 0;
  users: userModels[] = [];
  

  tableMenu: menuOptions[] = [
    {
      icon : 'manage_search',
      label: 'Ver',
      show:  this.obtenerPermisos() & 4 ? true : false,
      callback: (parametro:any) => { this.router.navigate(['/audit/'+parametro]) }
    }
  ];

  columns: columns<any>[] = [
    {
      columnDef: 'item',
      header: 'Número',
      type: 'label',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.item}`
    },
    {
      columnDef: 'id',
      header: 'Id',
      type: 'label',
      class: 'd-none',
      excludeFilter: true,
      show: false,
      cell: (element: any) => `${element.id}`
    },
    {
      columnDef: 'archivoNombre',
      header: 'Archivo',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.archivoNombre}`
    },
    {
      columnDef: 'tamanio',
      header: 'Tamaño',
      type: 'label',
      class: 'col-sm-1',
      show: false,
      cell: (element: any) => `${element.tamanio}`
    },
    {
      columnDef: 'registros',
      header: 'Registros',
      type: 'label',
      class: 'col-sm-1',
      show: true,
      cell: (element: any) => `${element.registros}`
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      type: 'label',
      class: 'col-sm-1',
      show: true,
      cell: (element: any) => `${element.estado}`
    },
    {
      columnDef: 'cadenaId',
      header: 'GLN Cadena',
      type: 'label',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${element.cadenaId}`
    },
    {
      columnDef: 'cadena',
      header: 'Cadena',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.cadena == null ? '' : element.cadena}`
    },
    {
      columnDef: 'urls',
      header: 'Urls',
      type: 'label',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${element.urls}`
    },
    {
      columnDef: 'logs',
      header: 'Logs',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.logs}`
    },
    {
      columnDef: 'tipoCargue',
      header: 'Tipo Auditoria',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.tipoCargue}`
    },
    {
      columnDef: 'usuarioId',
      header: 'Usuario Modificación',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.usuarioId}`
    },
    {
      columnDef: 'fechaArchivo',
      header: 'fecha del Archivo',
      type: 'date',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${formatDate(element.fechaArchivo,'dd-MM-yyyy','en-US')}`
    },
    {
      columnDef: 'fechaCreacion',
      header: 'Fecha de creación',
      type: 'date',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${formatDate(element.fechaCreacion,'dd-MM-yyyy','en-US')}`
    },
    {
      columnDef: 'fechaModificacion',
      header: 'Fecha Modificación',
      type: 'date',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${formatDate(element.fechaModificacion,'dd-MM-yyyy','en-US')}`
    },
    {
      columnDef: 'menu',
      header: 'Acciones',
      type: 'menu',
      class: 'col-sm-2',
      excludeFilter: true,
      menu: this.tableMenu,
      cell: (element: any) => `${element.menu}`
    }
  ];
  
  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private router: Router,
    private snack: snackBar, public dialog: MatDialog, private auditoriaApiService: auditoriaApiService, private userData:UserDataService, private usersApiService: usersApiService) {
       this.obtenerPermisos();
       this. obtenerUsuario();
        
     }


     obtenerUsuario(){
      this.usersApiService.get().subscribe((resultData: any) =>{
        this.users = [...resultData.resultObject];
        //console.warn(this.users);
        });
    }

    obtenerPermisos(){
      this.userData.getAccess$()
      .subscribe((data:any)=>{
      this.access = data;
      });
      return this.access;
    }

  ngOnInit(): void {
    if(!(this.access & 4)){
      this.router.navigate(['/home']);
    }
    let count = 1;
    let dialogSpiner: DialogSpinerComponent = new DialogSpinerComponent(this.dialog);
    dialogSpiner.openDialog();

    this.auditoriaApiService.get().subscribe(resultData =>{
      this.data = [...resultData.resultObject];
      
      this.data.forEach((element:data_element) => {
        element.item = count++
        element.menu = element.id;
        //console.warn(element.usuarioId);
        var userResult = this.users.filter((item: any) => item.id == element.usuarioId);
        if(userResult[0] !== undefined){
          element.usuarioId = userResult[0].userName;
        }
        else if(userResult[0] == null && element.usuarioId !== null){
          element.usuarioId = 'Usuario Eliminado';
        }
        else{
          element.usuarioId = 'cargue LayOut'
        }
      
      });
      console.warn(this.data);
      dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {

        dialogSpiner.closeDialog();
      });
    });
  }

  filterAdv(event:any){
    event = JSON.stringify(event);
    let count = 1;
    this.auditoriaApiService.getFilter(event)
    .subscribe(resultData =>{
      this.data = [...resultData.resultObject];
        this.data.forEach((element:data_element) => {
          element.item = count++;
          element.menu = element.id;
        });
        
    });
  }

}



export interface data_element {
  item: number,
  id: string,
  archivoNombre: string,
  tamanio: string,
  registros: string,
  estado: string,
  cadenaId: string,
  cadena: string,
  urls: string,
  logs: string,
  tipoCargue: string,
  usuarioId: string,
  fechaArchivo: Date,
  fechaCreacion: Date,
  fechaModificacion: Date,
  menu:any
}

