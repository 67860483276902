import { claimsApiService } from './../../services/users/claimsApiService.module';
import { menuOptions } from './../../models/menu.interface';
import { columns } from './../../models/table-column';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { snackBar } from 'src/app/Utils/snackBar';
import { formatDate } from '@angular/common';
import { UserDataService } from 'src/app/services/user-data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {
  dialogSpiner!: DialogSpinerComponent;
  data: data_element[] = [];
  access:number = 0;

  tableMenu: menuOptions[] = [
    {
      icon : 'drafts',
      label: 'Marcar como Leida',
      show: true,
      callback: (parametro:any) => {
        // this.router.navigate(['/causal/'+parametro+'/view/'])

        Swal.fire({
          title: 'Desea marcar como leida la notificación',
          // text: 'Una vez realizada esta accion no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, Marcarla',
          cancelButtonText: 'No, olvidalo',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result) => {
          if (result.isConfirmed) {
           
            this.notificationsApiService.PostRead(parametro)
            .subscribe((resultData:any) =>{
              //console.warn(resultData);
              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Se ha marcado como leida la notificación!':'Algo no esta bien'),
                  text:  (resultData.resultObject[0].value.succeeded ? 'Se ha marcado como leida la notificación en el sistema con éxito':'ha ocurrido un error inesperado'),
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              )
              this.getNotifications();

            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              {
                icon: 'error',
                title: "Cancelado",
                text: "Elemento no actualizado",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            })
          }
        });
     }
    },
    {
      icon : 'delete_forever',
      label: 'Eliminar',
      show: true,
      callback: (parametro:any) => {
        // this.router.navigate(['/user/'+parametro+'/view/']);
        Swal.fire({
          title: 'Esta seguro que desea eliminar la notificación?',
          text: 'Una vez realizada esta accion no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminarla',
          cancelButtonText: 'No, mantenerla',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result:any) => {
          if (result.isConfirmed) {

            this.notificationsApiService.Delete(parametro)
            .subscribe((resultData:any) =>{
              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Se ha eliminado la notificación!':'Algo no esta bien'),
                  text: (resultData.resultObject[0].value.succeeded ? 'Se ha eliminado la notificación del sistema y no podra ser recuperada':'ha ocurrido un error inesperado'),
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              )
              this.getNotifications();
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              {
                icon: 'error',
                title: "Cancelado",
                text: "Elemento no actualizado",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            })
          }
        })
      },
     },
  ];

  columns: columns<any>[] = [
    {
      columnDef: 'item',
      header: 'Número',
      type: 'label',
      class: 'col-md-1',
      excludeFilter: true,
      cell: (element: any) => `${element.item}`
    },
    {
      columnDef: 'titulo',
      header: 'Titulo',
      type: 'label',
      class: 'col',
      show: true,
      cell: (element: any) => `${element.titulo}`
    },
    {
      columnDef: 'descripción',
      header: 'Descripción',
      type: 'label',
      class: 'col',
      show: true,
      cell: (element: any) => `${element.descripcion}`
    },
    {
      columnDef: 'url',
      header: 'módulo',
      type: 'html',
      class: 'col-md-1',
      show: true,
      cell: (element: any) => `${'<a href="/#/'+element.url+'">Ir módulo</a>'}`
    },
    {
      columnDef: 'fecha',
      header: 'Fecha de Creación',
      type: 'date',
      class: 'col-md-2',
      show: true,
      cell: (element: any) => `${formatDate(element.fecha,'dd-MM-yyyy','en-US')}`
    },
    {
      columnDef: 'estadoId',
      header: 'Estado',
      type: 'mat-icon',
      class: 'col-md-1',
      excludeFilter: true,
      cell: (element: any) => `${element.estadoId == 0 ? 'mail' : 'drafts'}`
    },
    {
      columnDef: 'menu',
      header: 'Acciones',
      type: 'menu',
      class: 'col-md-1',
      excludeFilter: true,
      menu: this.tableMenu,
      cell: (element: any) => `${element.menu}`
    }
  ];

  titletable: string = 'Notificaciones';

  protected ngUnsubscribe:Subject<void> = new Subject<void>();
constructor(private router: Router, private notificationsApiService: notificationsApiService,
  public dialog: MatDialog, private snack: snackBar, private ActivatedRoute: ActivatedRoute,
  private userData:UserDataService, private claimsApiService: claimsApiService) { 
    this.dialogSpiner = new DialogSpinerComponent(this.dialog)
    
  }

  obtenerPermisos(){
   this.claimsApiService.ObtenerNumeroPermisoModulo('Notificaciones')
        .subscribe((data:any)=>{
          //console.warn(data);
          this.access = data.resultObject[0].value.numberPermission;
          //return this.access;
          if(!(this.access & 4)){
            this.router.navigate(['/home']);
          }
        });
  }


  ngOnInit(): void {
    this.obtenerPermisos();
    //console.warn(this.access);
    
    
    this.getNotifications();

}
getNotifications(){
  this.dialogSpiner.openDialog();
    let count = 1;
   this.notificationsApiService.get(0, 0)
   .subscribe((data:any)=>{
     this.data = [...data.resultObject];
     this.data.forEach((element:data_element) => {
      element.item = count++
      element.menu = element.id;
    });   
    this.notificationsApiService.obtenerNotificacionesTop10();
     this.dialogSpiner.closeDialog();
   }, error => {
     this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
     this.snack.snackBarRef.afterDismissed().subscribe(() => {
      
       this.dialogSpiner.closeDialog();
     });
   });
  }

  backPage(){
    this.ActivatedRoute.params
    .subscribe((params:any) => {
      let url = '';
      if(params.pagina){
        url = params.pagina;
      }
      if(params.subpagina){
        url += '/' + params.subpagina;
      }
      if(params.id){
        url += '/' + params.id;
      }
      if(params.accion){
        url += '/' + params.accion;
      }
      if(url != ''){
        //console.warn('regresar a :'+url);
        this.router.navigate([url]);
      }
      else{
        this.router.navigate(['/home']);
      }
    });
  }

  filterAdv(event:any){
    event = JSON.stringify(event);
    let count = 1;
    this.notificationsApiService.getFilter(event)
    .subscribe((resultData: any) =>{
      console.warn(resultData.resultObject);
      this.data = [...resultData.resultObject];
        this.data.forEach((element:data_element) => {
          element.item = count++;
          element.menu = element.id;
        });
        
    });
  }
}

export interface data_element {
  item: number,
  id: number,
  titulo: String,
  descripcion: String,
  url: String,
  estadoId: number,
  fecha: String,
  menu:any
}
