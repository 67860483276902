import { UserDataService } from './../services/user-data.service';
import { StorageService } from './../services/storage.service';
import { resultDataLogin, userLoginResultModels } from './../models/usersModels/usersLoginResult.module';
import { userLoginModels } from './../models/usersModels/usersLogin.module';
import { userloginApiService } from '../services/users/loginApiService.module';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { snackBar } from '../Utils/snackBar';
import * as bcrypt from 'bcryptjs';
import { DialogSpinerComponent } from '../dialog-spiner/dialog-spiner.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  userLoginResultModels: userLoginResultModels = {succeeded: false,isLockedOut: false, isNotAllowed: false, requiresTwoFactor: false };

   messageLogin: string = '';
   snackBarRef: any;
   dialogSpiner!: DialogSpinerComponent;
   resultDataLogin!: resultDataLogin;
   LogInForm:FormGroup;
   loadPage:boolean = false;

  constructor(private userServices: userloginApiService,
     private router: Router, private snack: snackBar, public dialog: MatDialog, private fb: FormBuilder, private storage:StorageService, private userData:UserDataService) {
      this.LogInForm = this.fb.group({
        Email: ['', [
          Validators.required,
          Validators.email
        ]],
        Password: ['', [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(14),
        ]],
        RememberMe: [false, [
        ]]
      });

  }

    ngOnInit(): void {
      console.log(environment.EnviromentTest)
    }

    isValid() {
      return this.LogInForm.controls;
    }

    login(){
      this.dialogSpiner = new DialogSpinerComponent(this.dialog);
      this.dialogSpiner.openDialog();
      this.userServices.post(this.LogInForm.value).subscribe((usersLoginiResult:any) =>{
        this.resultDataLogin = usersLoginiResult;
        if(this.resultDataLogin.resultObject[0].value.succeeded == true){
          this.storage.setInSession('api_token', this.resultDataLogin.resultObject[0].value.token);
          this.storage.setInSession('user_info', this.resultDataLogin.resultObject[0].value.userInfo);
          this.storage.setInSession('user_menu', this.resultDataLogin.resultObject[0].value.itemsMenu);

          this.userData.setIsLoggedIn(true);
          this.userData.setData(this.resultDataLogin.resultObject[0].value.userInfo);
          setTimeout(()=>{
            this.router.navigate(['/home']);
          }, 500);

          this.dialogSpiner.closeDialog();
        }
        else{
          this.snack.openSnackBar('El correo o la contraseña no son validos, favor verificar.', 'Registrarme!');
          this.dialogSpiner.closeDialog();
        }
      },
      error => {
        this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'Más!');
        this.snack.snackBarRef.afterDismissed().subscribe(() => {
          this.dialogSpiner.closeDialog();
        });
      });
  }

}
