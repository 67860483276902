import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { reportsApiService } from 'src/app/services/Reports/reportsApiService.module';
import { snackBar } from 'src/app/Utils/snackBar';
import * as pbi from 'powerbi-client';  
@Component({
  selector: 'app-informes-conciliacion',
  templateUrl: './informes-conciliacion.component.html',
  styleUrls: ['./informes-conciliacion.component.css']
})
export class InformesConciliacionComponent implements OnInit {
  data: any;
  screenHeight! : number;  
  @ViewChild('reportContainer',{static: true})reportContainer!: ElementRef;
   
  constructor(public dialog: MatDialog, public reportsApiService: reportsApiService, 
    private snack: snackBar) { }

  ngOnInit(): void {
    
    this.screenHeight = (window.screen.height);  
      this.getTokenReport();

  }

  showReport() {  
    // Embed URL    
    let embedUrl = this.data.resultObject[0].rptUrl;  
    let embedReportId = this.data.resultObject[0].rptId;  
    let config = {  
        type: 'report',  
        accessToken: this.data.resultObject[0].embedToken,  
        embedUrl: embedUrl,  
        id: embedReportId,  
        settings: {}  
    };  
    let reportContainer = this.reportContainer.nativeElement;  
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);  
    let report = powerbi.embed(reportContainer, config);  
    report.off("loaded");  
    report.on("loaded", () => {  
        console.log("Loaded");  
    });  
    report.on("error", () => {  
        //this.getToken();  
    });  
  }  

  getTokenReport(){
    let count = 1;
    let dialogSpiner: DialogSpinerComponent = new DialogSpinerComponent(this.dialog);
    dialogSpiner.openDialog();

    this.reportsApiService.get('b80f1192-4c4e-4a78-85f2-3fbd548818c4')
    .subscribe(resultData =>{
    this.data = resultData;
      //console.warn(this.data);
      this.showReport(); 
      dialogSpiner.closeDialog();

    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        dialogSpiner.closeDialog();
      });
    });

  }
}
