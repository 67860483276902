import { userModels } from '../../models/usersModels/user.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import * as bcrypt from 'bcryptjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserDataService } from '../user-data.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: "root"
})

export class categoryApiService {
  private base_url = '';
  private apiUrl =  '';
  userId:string = '';
  httpOptions: any;
  constructor(private http: HttpClient, private userData : UserDataService, private storage: StorageService) {
    this.userData.getData$()
    .subscribe((data:any)=>{
      this.userId = data.userId;
    });
   }

  public get(id?: string, data?:any): Observable<any> {
    this.base_url = environment.gnl_url;
    this.apiUrl = this.base_url + 'Categoria';
    // console.log(id);
    if (id != undefined) {
      this.apiUrl = this.apiUrl + "?id=" + id;
    }
    else if(data != undefined){
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Token': this.storage.getInSession('api_token')
        }),
        params: {strFiltro : data}};
      return this.http.get<any>(this.apiUrl,{params: data});
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.get<any>(this.apiUrl, this.httpOptions);
  }
  getFilter(data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(environment.gnl_url+"Categoria", this.httpOptions);
  }

  public PostMasiveProduct(RequestPayload: any): Observable<any> {
    let userId = this.userId;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.post<string>(environment.gnl_url+'Categoria/PostMasiveCagetory/'+userId, RequestPayload , this.httpOptions);
  }

  public delete(id:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.gnl_url+'Categoria?id='+id, this.httpOptions);
  }

  public status(id:number, status:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.gnl_url+'Categoria/inactive/'+id+'/'+status, this.httpOptions);
  }
}
