import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from './../dialog-spiner/dialog-spiner.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserDataService } from '../services/user-data.service';
import { StorageService } from './../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  private base_url = environment.auth_url;
  dialogSpiner!: DialogSpinerComponent;
  constructor(private user:UserDataService, private router:Router, private http:HttpClient, public dialog: MatDialog, private storage:StorageService) {

    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let user:any = this.user.getDataValue();

    this.dialogSpiner.openDialog();
      return new Promise<boolean>((resolve, reject) => {
          const httpOptions = {
          headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Token': this.storage.getInSession('api_token')
        })};
        this.http.get(this.base_url+"claims/GetNumberPermission/"+user.userId+"/"+user.roleId+"/"+route.data.module, httpOptions)
        .subscribe((data:any)=>{
          this.user.setAccess(data.resultObject[0].value.numberPermission);
          resolve((data.resultObject[0].value.numberPermission & 4 ? true:false));
          if(!(data.resultObject[0].value.numberPermission & 4)){
            this.router.parseUrl('/login');
          }

      this.dialogSpiner.closeDialog();
        }, (error:any)=>{

            this.dialogSpiner.closeDialog();
        });
    });
  }

}
