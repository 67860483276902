<br/>
<div class="container">
    <div class="row">
        <div class="col-12">
            <p></p>
            <mat-card>
                <mat-card-title>
                   Auditoria<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
                </mat-card-title>
                <app-bread-crumb></app-bread-crumb>
            </mat-card>
        </div>
        <p></p>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-card>
                <div class="form-row col-12">
                    <div class="form-group col-md-7">
                        <label for="">Nombre del Archivo</label>
                       <span id="nombre" class="form-control"></span>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="">Tamaño</label>
                        <span id="tamanio" class="form-control "></span>
                    </div>
                    <div class="form-group col-md-1">
                        <label for="">Registros</label>
                        <span id="registros" class="form-control"></span>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="">Estado</label>
                        <span id="estado" class="form-control"></span>
                    </div>
                </div>
                <div class="form-row col-12">
                    <div class="form-group col-md-6">
                        <label for="">Cadena</label>
                        <span id="cadena" class="form-control"></span>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="">Tipo de Cargue</label>
                        <span id="tipoCargue" class="form-control"></span>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="">Usuario</label>
                        <span id="usuario" class="form-control"></span>
                    </div>
                </div>
                <div class="form-row col-12">
                    <div class="form-group col-md-12">
                        <label for="">Logs</label>
                        <textarea disabled id="logs" class="form-control"></textarea>
                    </div>
                </div>
                <div class="form-row col-12">
                   
                    <div class="form-group col-md-6">
                        <label for="">URL</label>
                        <span id="url" class="form-control"></span>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="">Fecha del Archivo</label>
                        <span id="fechaArchivo" class="form-control"></span>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="">Creación</label>
                        <span id="creacion" class="form-control"></span>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="">Modificación</label>
                        <span id="modificacion" class="form-control"></span>
                    </div>
                </div>
                <div class="col text-right">
                    <button type="button" routerLink="/audit" class="btn btn-default">Regresar</button>
                  </div>
            </mat-card>
        </div>
    </div>

</div>

