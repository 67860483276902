import { causalsModels } from '../../models/productsModels/causals.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { auditModels } from 'src/app/models/audit/audit.module';
import { UserDataService } from '../user-data.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: "root"
})
export class locationApiService {
  private base_url = environment.prov_url;
  httpOptions: any;
  private apiUrl: string = this.base_url+'CargaMasiva';
  userId:string = '';
  gln_cadena : number = 0;
  gln_proveedor : number = 0;
  constructor(private http: HttpClient, private userData : UserDataService, private storage: StorageService) {
    this.userData.getData$()
    .subscribe((data:any)=>{
      this.userId = data.userId;
      this.gln_cadena = data.gln_cadena;
      this.gln_proveedor = data.gln_proveedor;
    });
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
  }

  public get(id?: number): Observable<any> {
    
    if(id !=  undefined){
      this.apiUrl  = this.base_url+"CargaMasiva?id="+id, this.httpOptions;
    }
    return this.http.get<any>(this.apiUrl, this.httpOptions);
     
  }

  public post(RequestPayload: causalsModels, id: any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {id : id}};
    return this.http.post(this.apiUrl, RequestPayload, this.httpOptions);
  }

  public postMasiveLocation(RequestPayloadLoct: any, audit: auditModels): Observable<any> {
    let userId = this.userId;
    audit.cadenaId = this.gln_cadena;
    audit.usuarioId = userId;

    return this.http.post<string>(this.apiUrl+'/PostMasiveLocation/'+userId, {  RequestPayloadLoct, audit} , this.httpOptions);
  }

  public GetLocation(): Observable<any> {
    this.base_url = environment.prov_url+ 'CargaMasiva/GetLocationView';
    if(this.gln_proveedor != 0){
      return this.http.get<any>(this.base_url + "?gln_proveedor="+this.gln_proveedor, this.httpOptions);
    }
    else{
      return this.http.get<any>(this.base_url, this.httpOptions);
    }
  }

  getFilter(data:any): Observable<any> {
    this.base_url = environment.prov_url+ 'CargaMasiva/GetLocationView';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(this.base_url+"?gln_proveedor="+this.gln_proveedor, this.httpOptions );
  }

}
