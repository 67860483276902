import { Router } from '@angular/router';
import { UserDataService } from './../../../services/user-data.service';
import { DialogSpinerComponent } from './../../../dialog-spiner/dialog-spiner.component';
import { MatDialog } from '@angular/material/dialog';
//import { ListCausales } from './../../../models/productsModels/causalsArchive.module';
//import { causalsModels } from './../../../models/productsModels/causals.module';
import { Component, OnInit, Inject } from '@angular/core';
import * as XLSX from 'xlsx';
import { causalApiService } from 'src/app/services/Products/causalApiService.module';
import { snackBar } from 'src/app/Utils/snackBar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-carga-masiva',
  templateUrl: './carga-masiva.component.html',
  styleUrls: ['./carga-masiva.component.css']
})
export class CargaMasivaComponent implements OnInit {

  //willDownload = false;
  file: any;
  currentDate: Date = new Date();
  result: string = '';
  dataString:string = '';
  nameFile: string = "";
  dialogSpiner!: DialogSpinerComponent;
  access:number = 0;
  //causalsModels: causalsModels[] = [];

  //ListCausales: ListCausales [] = [];
  //valor: number = 0;
  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private snack: snackBar, private router: Router, private causalApiService: causalApiService, private dialog: MatDialog, private userData:UserDataService,
  private notificationsApiService : notificationsApiService) {

      this.userData.getAccess$()
      .pipe( takeUntil(this.ngUnsubscribe) )
      .subscribe((data:any)=>{
        this.access = data;
      });
      if(!(this.access & 16)){
        this.router.navigate(['/home']);
      }
     
    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
 }

  onFileChange(ev: any) {
    let workBook: any = null;
    let jsonData = null;
    const reader = new FileReader();
    this.result = "";
    // console.log('archivo:'+ev.target.files.length);
    if(ev.target.files.length > 0){
      this.file = ev.target.files[0];
      this.nameFile = this.file.name;

      if(this.nameFile.indexOf('Plantilla-CargueCausal') === 0){

        reader.onload = () => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          console.warn(jsonData);
          this.dataString = JSON.stringify(jsonData);
          
        }
        reader.readAsBinaryString(this.file);

      }else{
        
        this.nameFile = "";
        this.result = "error: El nombre del archivo no es admitido";
      }
    }
    else{
      this.result = "error: debe seleccionar un archivo de formato tipo XLSX";
    }

  }

  send(){
    this.guardar(this.dataString);
  }

  // setDownload(data: any) {
  //   this.willDownload = true;
  //   setTimeout(() => {
  //     const el = document.querySelector("#download");
  //     if(el != null){
  //       el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
  //       el.setAttribute("download", 'xlsxtojson.json');
  //     }
  //     else{
  //       console.log('output element download exist');
  //     }

  //   }, 1000)
  // }

  guardar(json: any){
    let Titulo = ' creado ';
    Swal.fire({
      title: '¿Desea cargar masivamente estos causales al sistema?',
      // text: 'Una vez realizada esta accion no se podra revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aceptar',
      cancelButtonText: 'No, olvidalo',
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
    if (result.isConfirmed) {
    let errMsg : string = '';
    this.dialogSpiner.openDialog();
      this.causalApiService.postMasive(json).subscribe((causalResult:any) =>{
        //console.warn(causalResult.resultObject[0]);
        Swal.fire(
          {
            icon: (causalResult.resultObject[0] ? 'success':'error'),
            title: (causalResult.resultObject[0] ? 'Registro '+Titulo+'!':'Algo no esta bien'),
            text: causalResult.resultObject[0],
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
          }
        )

        if(causalResult.resultObject.length > 0){
          this.result = causalResult.resultObject[0];
          $("#fileCarga").val("");
          this.notificationsApiService.InsertarNotificaciones('Carga Masiva de Causales','El Archivo de Causales '+this.nameFile+' ha sido cargado correctamente: '+this.result, 'causal');
          this.dialogSpiner.closeDialog();
          this.router.navigate(['/causal']);
        }
        else{
          $("#fileCarga").val("");
          this.snack.openSnackBar('Ha ocurrido un error con la información al guardar el usuario, favor verificar.', 'OK!');
          this.notificationsApiService.InsertarNotificaciones('Error al cargar el archivo de Carga Masiva de Causales','El Archivo de Causales '+this.nameFile+' no ha sido cargado: '+this.result, 'causal');
          this.dialogSpiner.closeDialog();
        }
      },
      (error: HttpErrorResponse) => {
        if(error.status == 400){
       
          var errorArray = Object.values(error.error.errors);
          
          for (var key in errorArray) {
            //console.log("Ocurrieron los siguietes errores: " + errorArray[key]);
            errMsg += errorArray[key] + ', ';
          }

          Swal.fire(
            {
              icon: ('error'),
              title: 'Existe información errónea o Faltante',
              text:  errMsg+ ' por favor ajustarlo antes de volverlo a cargar',
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          );

          this.snack.openSnackBar('Existe información errónea o Faltante dentro del archivo, por favor ajustarlo antes de volverlo a cargar '+ errMsg, 'OK!');
        }else{
          this.snack.openSnackBar('Ha ocurrido un error al guardar la información.', 'OK!');
        }
        this.snack.snackBarRef.afterDismissed();
        this.dialogSpiner.closeDialog();
        
        this.notificationsApiService.InsertarNotificaciones('Error al cargar el archivo de Carga Masiva de Causales','El Archivo de Causales '+this.nameFile+' no ha sido cargado, '+errMsg + ' favor verificar la información del archivo.', 'causal');
      });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Elemento no "+Titulo,
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        })
      }
    });
    this.dataString = "";
    $("#fileCarga").val("");
    this.nameFile = "";

  }

  ngOnInit(): void {

  }


}


