<div class="container">
  <div class="row">
    <div class="col">
        <p></p>
        <mat-card>
            <mat-card-title>
              Auditorias<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
            </mat-card-title>
            <app-bread-crumb></app-bread-crumb>
        </mat-card>
    </div>
    <p></p>
  </div>
  <div class="row">
    <div class="col">
      <mat-card>
        <app-datatable [title]="titletable" [data]="data" [columns]="columns" (eventFilter)="filterAdv($event)"></app-datatable>

      </mat-card>
    </div>
  </div>
</div>

