import { environment } from 'src/environments/environment';
import { emailModels } from './../../models/usersModels/mail.module';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { userLoginModels } from '../../models/usersModels/usersLogin.module';

@Injectable({
  providedIn: "root"
})

export class userloginApiService {

  private base_url = environment.auth_url;
  constructor(private http: HttpClient) { }

  private apiUrlLogin = this.base_url + "login";
  public post(RequestPayload: userLoginModels): Observable<any> {
    return this.http.post(this.apiUrlLogin, RequestPayload);
  }

  public get(email?: string): Observable<any> {
    
     this.base_url = environment.auth_url;
     let apiUrl = this.base_url+'login?email='+email;

    return this.http.get<any>(apiUrl);
  }

   public htmltoString(html: any): Observable<any> {
      
    return this.http.get(html, {responseType: 'text'})
       
  }

  public SendMail(RequestPayload: emailModels): Observable<any> {
    this.base_url = environment.mail_url;
    let params = new HttpParams();

    RequestPayload.claveUsuario = environment.claveUsuario,
    RequestPayload.nombreUsuario = environment.nombreUsuario,
    RequestPayload.correoOrigen = environment.correoOrigen,
    RequestPayload.esHtml = RequestPayload.esHtml,
    RequestPayload.id_Aplicacion = environment.id_Aplicacion,
    RequestPayload.numeroPuerto = environment.numeroPuerto,
    RequestPayload.servidorSmtp = environment.servidorSmtp,
    RequestPayload.usarSSL = environment.usarSSL

    params = params.append("asuntoCorreo", RequestPayload.asuntoCorreo);
    params = params.append("claveUsuario", RequestPayload.claveUsuario);
    params = params.append("nombreUsuario", RequestPayload.nombreUsuario);
    params = params.append("correoCopia", RequestPayload.correoCopia);
    params = params.append("cuerpoCorreo", RequestPayload.cuerpoCorreo);
    params = params.append("esHtml", RequestPayload.esHtml);
    params = params.append("correoDestino", RequestPayload.correoDestino);
    params = params.append("correoOrigen", RequestPayload.correoOrigen);
    params = params.append("id_Aplicacion", RequestPayload.id_Aplicacion);
    params = params.append("servidorSmtp", RequestPayload.servidorSmtp);
    params = params.append("numeroPuerto",RequestPayload.numeroPuerto);
    params = params.append("usarSSL", RequestPayload.usarSSL);

    // let httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json'
    //   }),
    //   params: params};
    //   console.log(params);
    return this.http.post(this.base_url, RequestPayload);
  }

}