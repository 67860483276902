import { emailModels } from './../models/usersModels/mail.module';
import { StorageService } from './../services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { snackBar } from './../Utils/snackBar';
import { Router } from '@angular/router';
import { userloginApiService } from './../services/users/loginApiService.module';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogSpinerComponent } from './../dialog-spiner/dialog-spiner.component';
import { userLoginResultModels, resultDataLogin } from './../models/usersModels/usersLoginResult.module';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  userLoginResultModels: userLoginResultModels = {succeeded: false,isLockedOut: false, isNotAllowed: false, requiresTwoFactor: false };

   messageLogin: string = '';
   snackBarRef: any;
   dialogSpiner!: DialogSpinerComponent;
   resultDataLogin!: resultDataLogin;
   LogInForm:FormGroup;
   loadPage:boolean = false;
   strHtml! : string;

  constructor(private userServices: userloginApiService,
     private router: Router, private snack: snackBar, public dialog: MatDialog, private fb: FormBuilder, private http: HttpClient) {
      this.LogInForm = this.fb.group({
        Email: ['', [
          Validators.required,
          Validators.email
        ]],
      });

      this.userServices.htmltoString('assets/Plantillas-Html/correo-recuperacion.html').subscribe((htmlResult:any) =>{
        this.strHtml = htmlResult;
      },
      error => {
        this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'OK!');
        this.snack.snackBarRef.afterDismissed().subscribe(() => {
          this.dialogSpiner.closeDialog();
        });
      });

  }

  ngOnInit(): void {

  }

  isValid() {
    return this.LogInForm.controls;
  }

  login(){
    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
    this.dialogSpiner.openDialog();
    
    
    this.userServices.get(this.LogInForm.controls['Email'].value).subscribe((tokenResult:any) =>{
              
     

      if(tokenResult.resultObject[0].value.succeeded == true){
  
        let url = "https://app-nivelserviciofrontend-dev.azurewebsites.net/#/passwordchange/"+tokenResult.resultObject[0].value.token;
        this.strHtml = btoa(unescape(encodeURIComponent(FormatString(this.strHtml, url))));
        console.log(this.strHtml);
        let email : emailModels = {
          asuntoCorreo : 'Cambio de contraseña',
          claveUsuario : 'AppLogyca2020*',
          nombreUsuario : 'aplicaciones@logyca.com',
          correoCopia : '',
          cuerpoCorreo :  this.strHtml,
          esHtml : true,
          correoDestino : this.LogInForm.controls['Email'].value,
          correoOrigen : 'aplicaciones@logyca.com',
          id_Aplicacion : 14,
          servidorSmtp : 'smtp.office365.com',
          numeroPuerto : 587,
          usarSSL : true
        };
        
        
        this.userServices.SendMail(email).subscribe((sendMailResult: any) => {
          this.dialogSpiner.closeDialog();
          this.snack.openSnackBar(tokenResult.resultObject[0].value.msg, 'OK!');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.router.navigate(['/login']);
          });
        }, 
        error => {
          this.snack.openSnackBar('Ha ocurrido un error al enviar el correo.', 'OK!');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
          });
        });
        
      }
      else{
        this.snack.openSnackBar(tokenResult.resultObject[0].value.msg, 'OK!');
        this.dialogSpiner.closeDialog(); 
      }
    },
    error => {
      this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'OK!');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
      });
    });
    
  }

}

export function FormatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}