import { userRoleModels } from './../../models/usersModels/userRole.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: "root"
})

export class userRolesApiService {
  httpOptions: any;
  private base_url = environment.auth_url;
  constructor(private http: HttpClient, private storage: StorageService) {
 
  }

  private apiUrl = '';

  public get(roleId?: string, id?: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    if(id !=  undefined){
      this.apiUrl  = this.base_url+"userrole?roleId="+roleId+'&Id='+id;
    }
    else if(roleId !=  undefined){
      this.apiUrl = this.base_url+'userrole?roleId='+roleId;
    }
    return this.http.get<any>(this.apiUrl, this.httpOptions);
  }

  public post(RequestPayload: userRoleModels): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    this.apiUrl = this.base_url+'userrole';
    return this.http.post(this.apiUrl, RequestPayload, this.httpOptions);
  }

  getFilter(roleId: string, data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(environment.auth_url+"userrole?roleId="+roleId, this.httpOptions);
  }


  public delete( roleId: string, userId: string ): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    this.apiUrl = this.base_url+'userrole';
    return this.http.delete(this.apiUrl+'/?roleId='+roleId+'&userId='+userId, this.httpOptions);
  }
}
