<nav class="nav-app">
  <div>
    <div class="nav_list-app" id="accordion">

      <div class="p-2 text-center mb-3 profile-info">
        <h4 class="font-weight-bold mb-0">
          {{ (userName.length>20)? (userName | slice:0:25)+'..':(userName) }}
        </h4>
        <p class="text-.muted small mt-0">
          {{ (userRole.length>25)? (userRole | slice:0:25)+'..':(userRole) }}
        </p>
      </div>

      <div class="app-content-menu">
        <div *ngFor="let item of itemsMenu; let i = index"  >
            <a href="{{'#collapseOne_'+i}}" data-toggle="collapse" aria-expanded="false" class="nav_link-app" >
              <i class="material-icons sidebaritemIcon text-secondary">
                {{item.icon}}
              </i>
              <span class="nav_name text-secondary">
                <strong>{{item.name}}</strong>
              </span>
            </a>
            <div class="collapse" id="{{'collapseOne_'+i}}"  data-parent="#accordion">
              <a [routerLink]="child.url" class="nav_link-app" *ngFor="let child of item.childs; let i = index" >
                
                <span class="nav_name" style="color: #9d9d9d">
                  <strong>{{child.name}}</strong>
                </span>
             
              </a>
             
            </div>
        </div>
      </div>

    </div>

    <div class="d-block">

        <a href="#" routerLink="/home">
          <img src="assets/LOGYCA CORPORATIVO.png" alt="Image" class="img-fluid logo-app"/>
        </a>
    </div>
  </div>
  <a href="#" (click)="logout()" class="nav_link-app nav-color-text" style="color: #9d9d9d"> <i class="fas fa-sign-out-alt" style='font-size:32px;color:red'></i> <span class="nav_name">Salir</span> </a>
</nav>

