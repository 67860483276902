import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserDataService } from './user-data.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiAprovarService {
  gln_cadena : string = '';
  private base_url = environment.odc_url;
  httpOptions: any;

  constructor(private http: HttpClient, private userData : UserDataService, private storage: StorageService) {
    this.userData.getData$()
    .subscribe((data:any)=>{
      this.gln_cadena = data.gln_cadena;
    });

  }

  get(){
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.get<any>(this.base_url+​'AprobarCausal?cadenaId='+this.gln_cadena, this.httpOptions);
  }
  getFilter(data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(environment.odc_url+"AprobarCausal?cadenaId="+this.gln_cadena, this.httpOptions);
  }
  post(data:any){
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.post(this.base_url+​'AprobarCausal', data, this.httpOptions);
  }
}
