<p></p>
<div class="container">


  <form (ngSubmit)="submit()" [formGroup]="causalForm" >
  <div class="row">
    <div class="col">
        <p></p>
        <mat-card>
            <mat-card-title>
              Listado de Productos de Orden de Compra para Asignar Causales<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
            </mat-card-title>
            <app-bread-crumb></app-bread-crumb>
            <div class="row">
              <div class="col-md-6 form-group">
                <label class="label">Causales<span class="text-danger">*</span></label>
                <app-search-drop-down [data]="selectCausales" (selectionChange)="selectionChanged($event)" [ngClass]="{ 'is-invalid': isValid().causalId.dirty && isValid().causalId.errors }"></app-search-drop-down>
             
                <label class="label">Responsable</label>
                <input class="form-control" [disabled]="true" value="{{responsable}}">
              </div>
              <div class="col-md-6 form-group"><label class="label">Observación</label><textarea rows="2" maxlength="255"  class="form-control" formControlName="observacion" [ngClass]="{ 'is-invalid': isValid().observacion.dirty && isValid().observacion.errors }" ></textarea></div>
            </div>
        </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col p-3">
      <mat-card>
        <label class="label">Seleccione los productos a conciliar con un causal<span class="text-danger">*</span></label><br/>
        <app-datatable [title]="titletable" [data]="data" [columns]="columns" (eventFilter)="filterAdv($event)" (eventRowSelected)="itemsSelected($event)" ></app-datatable>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col text-right">
        <button mat-raised-button type="submit" [disabled]="causalForm.invalid" class="btn-primary">Guardar</button><span class="mx-2 text-muted">|</span>
        <button type="button" routerLink="/home" class="btn btn-default">Regresar</button>
    </div>
  </div>
  </form>
</div>

