
    <mat-select class="form-control" (ngModelChange)="onChange($event)" [formControl]="dataCtrl" placeholder="Seleccione" #singleSelect>
        <mat-option>
        <ngx-mat-select-search [formControl]="dataFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let item of filteredData | async" [value]="item.value">
        {{item.name}}
        </mat-option>
    </mat-select>


