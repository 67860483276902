
<div class="container">
    <div class="row">
        <div class="col">
            <p></p>
            <mat-card>
                <mat-card-title>
                    Cambio de Contraseña <div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
                </mat-card-title>
                <app-bread-crumb></app-bread-crumb>
            </mat-card>
        </div>
        <p></p>
    </div>
    <div class="row">
        <div class="col">
            <mat-card>
              <!-- <form>

              </form> -->
                
                <div class="form-row">
                    <div class="form-group col">
                        <label for="">Contraseña <span class="text-danger">*</span></label>
                        <input maxlength="10" class="form-control" [(ngModel)]="this._userModels.passwordHash" id="contraseña" type="password" #passwordHash="ngModel" required pattern="^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{5,10}$"/>
                        <span *ngIf="passwordHash.invalid && (passwordHash.dirty || passwordHash.touched)" class="text-danger">
                            <span *ngIf="passwordHash.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="passwordHash.errors?.pattern">
                                * La Contraseña no tiene el formato correcto, Dígitos, minúsculas y mayúsculas.
                            </span>
                        </span>
                    </div>
                    <div class="form-group col">
                        <label for="">Confirmar Contraseña <span class="text-danger">*</span></label>
                        <input maxlength="10" class="form-control" [(ngModel)]="passwordHashConfirm" #passwordConfirm="ngModel" type="password" required pattern="^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{5,10}$"/>
                        <span *ngIf="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)" class="text-danger">
                            <span *ngIf="passwordConfirm.errors?.required">
                                * el Campo es requerido.
                            </span>
                            <span *ngIf="passwordConfirm.errors?.pattern">
                                * La Contraseña no tiene el formato correcto, Dígitos, minúsculas y mayúsculas.
                            </span>
                        </span>
                    </div>
                </div>
               
                <div class="row">
                  <div class="col text-right">
                    <span *ngIf="!passwordHash.errors?.required && !passwordConfirm.errors?.required && !passwordHash.errors?.pattern && !passwordConfirm.errors?.pattern; else elseTemplate">
                        <button [disabled]="false" mat-raised-button type="submit" (click)="guardar();" class="btn-primary" >Guardar</button>
                    </span>
                    <ng-template #elseTemplate>
                      <button mat-raised-button type="submit" [disabled]="true" class="btn-primary" >Guardar</button>
                    </ng-template>
                        <span class="text-muted mx-2" >|</span>
                    <button type="button" routerLink="/login" class="btn btn-default">Salir</button>
                  </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
