<div class="container">
    <div class="row">
      <div class="col">
          <p></p>
          <mat-card>
              <mat-card-title>
                {{title}}<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
              </mat-card-title>
              <app-bread-crumb></app-bread-crumb>
          </mat-card>
      </div>
      <p></p>
    </div>
    <div class="row">
      <div class="col">
        <mat-card>
          <div style=" width: 100%;" [ngStyle]="{ 'height': (screenHeight-150)+ 'px' }" #reportContainer></div> 
        </mat-card>
      </div>
    </div>
  </div>
  
