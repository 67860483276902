import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { UserDataService } from './user-data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  constructor(private user:UserDataService, private http:HttpClient, private storage:StorageService) { }

  Init() {
    return new Promise<void>((resolve, reject) => {
      let token     = this.storage.getInSession('api_token');
      let user_info = this.storage.getInSession('user_info');
      if(token){
        this.user.setIsLoggedIn(true);
        this.user.setData(user_info);
      }
      resolve();
    });
  }
}
