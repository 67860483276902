import { rolesApiService } from 'src/app/services/users/rolesApiService.module';
import { UserDataService } from './../../services/user-data.service';
import { userModels } from './../../models/usersModels/user.module';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { usersApiService } from 'src/app/services/users/usersApiService.module';
import { snackBar } from '../../Utils/snackBar';
import { Guid } from "guid-typescript";
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { networkApiService } from 'src/app/services/Network/networkApiService.module';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  displayedColumns: string[] = ['item', 'name', 'normalizedName'];
  data: data_element[] = [];
  accion:string = "view";
  passwordHashConfirm : string = '';
  title: string = '';
  dialogSpiner!: DialogSpinerComponent;
  required: boolean = false;
  _userModels : userModels = {
    id: '',
    userName:'',
    nombreApellido: '',
    normalizedUserName: '',
    email: '',
    normalizedEmail: '',
    emailConfirmed: true,
    passwordHash: '',
    securityStamp: '',
    concurrencyStamp: '',
    phoneNumber: '',
    phoneNumberConfirmed: true,
    twoFactorEnabled: false,
    lockoutEnd: new Date(),
    lockoutEnabled: true,
    accessFailedCount: 0,
    empresa: '',
    direccion: '',
    nit: '',
    gln_cadena: 0,
    gln_proveedor: 0
  };
  access:number = 0;
  listCadenas : any;

  listUserModels: userModels[] = [];

  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private usersApiService: usersApiService,
    private router: Router, private snack: snackBar,
     private route: ActivatedRoute,
     public dialog: MatDialog, private userData:UserDataService,
     public notificationsApiService : notificationsApiService,
     public rolesApiService: rolesApiService,
     public networkApiService : networkApiService
     ) {
      // this.userData.getAccess$()
      // .pipe( takeUntil(this.ngUnsubscribe) )
      // .subscribe((data:any)=>{
      //   this.access = data;
      // });
      this.userData.getAccess$()
      .pipe( takeUntil(this.ngUnsubscribe) )
      .subscribe((data:any)=>{
        this.access = data;
      });
      
    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
      this.route.params
      .subscribe((params:any) => {
        if(params.accion){
          this.accion = params.accion;
        }
        if(params.accion == 'edit'){
          if(!(this.access & 2)){
            this.router.navigate(['/home']);
          }
        }else if(params.accion == 'view'){
          if(!(this.access & 4)){
            this.router.navigate(['/home']);
          }
        }else if(params.accion == 'create'){
          if(!(this.access & 1)){
            this.router.navigate(['/home']);
          }
        }else{
          this.router.navigate(['/home']);
        }
        if(params.id && (params.accion == 'edit' || params.accion == 'view')){
          this.getUser(params);
          this.title = this.accion == 'edit'? 'Editar' : ' Ver ';
          // console.warn(this.accion);
          this.required = false;
        }
        else if(this.accion == 'create'){
          this.title = 'Crear ';
          this.required = true;
          // console.warn(this.accion);
        }
        this.getRoleUser(params);
      });
    }

  ngOnInit(): void {
    this.getNetWork();
    
  }

  getNetWork(){
    this.dialogSpiner.openDialog();
    this.networkApiService.getNetWork()
    .subscribe((data:any)=>{
      this.listCadenas = [...data.resultObject];
     
      //console.warn(this.listCadenas);
      this.dialogSpiner.closeDialog();
    }, error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onChange(event: any){

  }

  getUser(params: any){
    this.dialogSpiner.openDialog();
    this.usersApiService.get(params.id)
    .subscribe((data:any)=>{
      this._userModels = data.resultObject[0];

      
      this.dialogSpiner.closeDialog();
    }, error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
      });
    });
  }
  getRoleUser(params: any){
    //console.warn(params.id);
    let count = 1;
    this.dialogSpiner.openDialog();
    this.rolesApiService.getRoleUser(params.id)
    .subscribe((resultData:any)=>{
      this.data = [...resultData.resultObject];
        this.data.forEach((element:data_element) => {
          element.item = count++;
          element.name = element.name;
          element.normalizedName = element.normalizedName;
        });
      this.dialogSpiner.closeDialog();
    }, error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
      });
    });
  }

  guardar(){

    let Titulo = this.accion == 'create' ? ' creado ' : ' editado ';
    Swal.fire({
      title: 'Desea '+(this.accion == 'create' ?  ' crear ' : ' editar ')+' este usuario?',
      // text: 'Una vez realizada esta accion no se podra revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aceptar',
      cancelButtonText: 'No, olvidalo',
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
      this.dialogSpiner = new DialogSpinerComponent(this.dialog);
      this.dialogSpiner.openDialog();
      if(this.passwordHashConfirm != this._userModels.passwordHash){
        this.dialogSpiner.closeDialog();
        this.snack.openSnackBar('La contraseña no coincide, favor intentar de nuevo.', 'OK!');
      }
      else{
        //this.dialogSpiner.openDialog();
        if(this.accion == 'create'){
          this._userModels.id = Guid.create().toString();
        }
        //console.warn(this._userModels.phoneNumber);
        this._userModels.userName = this._userModels.email;
        this._userModels.normalizedEmail = this._userModels.email;
      
        //this._userModels.gln_cadena =  gln_cadena;
        this._userModels.empresa = $("#cadena option:selected" ).text();
        //console.warn(document.getElementById('cadena'));
        this._userModels.gln_cadena = $("#cadena option:selected" ).val() == undefined ? 0 : Number($("#cadena option:selected" ).val());
        
        
        this._userModels.phoneNumber = this._userModels.phoneNumber != null ? this._userModels.phoneNumber.toString(): '';
        this._userModels.securityStamp = Guid.create().toString();
        this._userModels.concurrencyStamp = new Date().toString();
        this._userModels.lockoutEnabled = false;
        //this._userModels.passwordHash = bcrypt.hashSync(this._userModels.passwordHash, 2);
        console.warn(this._userModels);
        this.usersApiService.post(this.accion, this._userModels).subscribe(usersResult =>{
        
          Swal.fire(
            {
              icon: (usersResult.resultObject[0] ? 'success':'error'),
              title: (usersResult.resultObject[0] ? 'Registro '+Titulo+'!':'Algo no esta bien'),
              text: usersResult.resultObject[0] ? 'Registro '+Titulo+' con éxito':'Ha ocurrido un error al momento de '+Titulo +' el registro.',
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
          )

          if(usersResult.resultObject[0]){
            this.dialogSpiner.closeDialog();
            this.notificationsApiService.InsertarNotificaciones('Usuario '+this._userModels.nombreApellido+' '+Titulo, 'El usuario ha sido '+Titulo+' Correctamente', 'user');
            this.router.navigate(['/user']);
          }
          else{
            this.notificationsApiService.InsertarNotificaciones('Error al '+Titulo+' el usuario ' +this._userModels.nombreApellido, 'Ha ocurrido un error al momento de '+Titulo+' el usuario '+this._userModels.nombreApellido, 'user');
            this.snack.openSnackBar('Ha ocurrido un error al crear el usuario, favor verificar.', 'OK!');
            this.dialogSpiner.closeDialog();
          }
          this.dialogSpiner.closeDialog();
        },
        error => {
          this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'OK!');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
          });

        });

      }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Elemento no "+Titulo,
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        })
      }
    });
  }
}

export interface data_element {
  item: number,
  name: string,
  normalizedName: string,
}
// export interface data_element_network {
//   key: number,
//   value: string,
// }