<p></p>
<div class="container">
  <div class="row">
    <div class="col">
        <p></p>
        <mat-card>
            <mat-card-title>
              Listado de Productos para Aprobar / Rechazar Causales<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
            </mat-card-title>
            <app-bread-crumb></app-bread-crumb>
           
        </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col p-3">
      <mat-card>
        <app-datatable [title]="titletable" [data]="data" (eventFilter)="filterAdv($event)" (eventRowSelected)="itemsSelected($event)" [columns]="columns"></app-datatable>
      </mat-card>
    </div>
  </div>

  <div class="row">
    <form (ngSubmit)="submit()" [formGroup]="causalForm" >
    <div class="row">
      <div class="col-md-12 form-group"><label class="label">Observación:</label><textarea rows="2" maxlength="255"  class="form-control" formControlName="observacion" [ngClass]="{ 'is-invalid': isValid().observacion.dirty && isValid().observacion.errors }" ></textarea></div>
    </div>
    <div class="col text-right">
        <button mat-raised-button type="submit" name="Aprobar" [disabled]="causalForm.invalid" class="btn-primary">Aprobar</button><span class="mx-2 text-muted">|</span>
        <button mat-raised-button name="Rechazar" type="submit"[disabled]="causalForm.invalid" class="btn-danger">Rechazar</button><span class="mx-2 text-muted">|</span>
        <button type="button" routerLink="/home" class="btn btn-default">Regresar</button>
    </div>
  </form>
  </div>
</div>

