<p></p>
<div class="container">
  <div class="row">
    <div class="col">
        <p></p>
        <mat-card>
            <mat-card-title>
                Listado de Usuarios<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
            </mat-card-title>
            <app-bread-crumb></app-bread-crumb>
        </mat-card>
    </div>
    <p></p>
  </div>
  <div class="row">
    <div class="col">
      <mat-card>
        <app-datatable
        [title]="titletable"
        [data]="data"
        [columns]="columns"
        (eventFilter)="filterAdv($event)"  >

        </app-datatable>
        <!-- <table class="table table-striped table-bordered table-sm row-border hover" id="datatableexample">
          <thead>
            <tr>
              <th>Número</th>
              <th>Nombre y Apellido</th>
              <th>Correo</th>
              <th>Empresa</th>
              <th>Teléfono</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let group of data">
                <td>{{index + 1}}</td>
                <td>{{group.userName}}</td>
                <td>{{group.email}}</td>
                <td>{{group.empresa}}</td>
                <td>{{group.phoneNumber}}</td>
                <td><a href="#">Ver</a><span>|</span><a href="#">Eliminar</a><span>|</span><a href="#">Inactivar</a></td>
            </tr>
          </tbody>
        </table> -->
      </mat-card>
    </div>
  </div>
</div>


