<div class="mat-elevation-z8">
    <section>
      <mat-form-field appearance="fill">
        <mat-label>Columna</mat-label>
        <mat-select>
          <mat-option *ngFor="let column of columns" value="{{ column.header }}">{{ column.header }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Condición</mat-label>
        <mat-select>
          <mat-option value="">Igual que</mat-option>
          <mat-option value="">Contiene</mat-option>
          <mat-option value="">Mayor que</mat-option>
          <mat-option value="">Menor que</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Valor</mat-label>
        <input matInput  placeholder="Filtrar" #input> 
      </mat-form-field>
      
        <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
          <mat-icon>delete_forever</mat-icon>
        </button>
    </section>
</div>
