import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { NotificationComponent } from './../../../notification/notification.component';
import { UserDataService } from './../../../services/user-data.service';
import { causalsModels } from './../../../models/productsModels/causals.module';
import { snackBar } from './../../../Utils/snackBar';
import { causalApiService } from '../../../services/Products/causalApiService.module';
import { Component, OnInit } from '@angular/core';
import { TableColumn } from 'src/app/models/table-column.interface';
import { columns } from 'src/app/models/table-column';
import {formatDate} from '@angular/common';
import { Router } from '@angular/router';
import { menuOptions } from 'src/app/models/menu.interface';
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-causal-view',
  templateUrl: './causal-view.component.html',
  styleUrls: ['./causal-view.component.css']
})

export class CausalViewComponent implements OnInit {
  data: Array<any> =[];
  access:number = 0;
  tableMenu: menuOptions[] = [
    {
      icon : 'edit',
      label: 'Editar',
      show: (this.obtenerPermisos() & 2 ? true:false),
      callback: (parametro:any) => { this.router.navigate(['/causal/'+parametro+'/edit/']) }
    },
    {
      icon : 'manage_search',
      label: 'Ver',
      show: (this.obtenerPermisos() & 4 ? true:false),
      callback: (parametro:any) => { this.router.navigate(['/causal/'+parametro+'/view/']) }
    },
    {
      icon : 'change_circle',
      label: 'Estado',
      show: (this.obtenerPermisos() & 128 ? true:false),
      callback: (parametro:any, data?:any) => {
        // this.router.navigate(['/causal/'+parametro+'/view/'])
        Swal.fire({
          title: 'Desea cambiar el estado de esta Causal?',
          // text: 'Una vez realizada esta accion no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, cambiarle el estado',
          cancelButtonText: 'No, olvidalo',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result) => {
          if (result.isConfirmed) {
            let varEstado = 2;
            if(data.estado == 'inactivo'){
              varEstado = 1;
            }
            this.causalApiService.status(parametro, (varEstado))
            .subscribe(resultData =>{

              if(resultData.resultObject[0].value.succeeded){
                this.getCausales();
                  this.notificationsApiService.InsertarNotificaciones('Cambio de estado del Causal nombre: ' + data.causal, 'El causal ha cambiado de estado a '+ (varEstado == 1 ? 'activo' : 'inactivo'), 'causal');
              }
              else{
                this.notificationsApiService.InsertarNotificaciones('Error en el Cambio de estado del Causal Id:' + parametro, 'Ha ocurrido un error al cambiar el estado del causal a '+ (varEstado == 1 ? 'activo' : 'inactivo'), 'causal') + 'y no fue actualizado';
              }
              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Estado cambiado!':'Algo no esta bien'),
                  text: resultData.resultObject[0].value.msg,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              )
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              {
                icon: 'error',
                title: "Cancelado",
                text: "Elemento no actualizado",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            })
          }
        });
     }
    },
    {
      icon : 'delete_forever',
      label: 'Eliminar',
      show: (this.obtenerPermisos() & 8 ? true:false),
      // disabled:true,
      callback: (parametro:any) => {
        // this.router.navigate(['/causal/'+parametro+'/view/']);

        Swal.fire({
          title: 'Esta seguro que desea eliminar este elemento?',
          text: 'Una vez realizada esta acción no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo',
          cancelButtonText: 'No, mantenlo',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result) => {
          if (result.isConfirmed) {
            this.causalApiService.delete(parametro)
            .subscribe(resultData =>{

              if(resultData.resultObject[0].value.succeeded){
                this.getCausales();
                  this.notificationsApiService.InsertarNotificaciones('Causal Id:' + parametro + ' Eliminado', 'El causal ha sido eliminado ', 'causal');
              }
              else{
                this.notificationsApiService.InsertarNotificaciones('Error al eliminar el Causal Id:' + parametro, 'Ha ocurrido un error, el causal no fue eliminado', 'causal');
              }

              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Causal eliminada!':'Algo no esta bien'),
                  text: resultData.resultObject[0].value.msg,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              )
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              {
                icon: 'error',
                title: "Cancelado",
                text: "Causal no eliminada",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            })
          }
        });
      },
     }
  ];

  columns: columns<any>[] = [
    {
      columnDef: 'item',
      header: 'Número',
      type: 'label',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.item}`
    },
    {
      columnDef: 'id',
      header: 'Id.',
      type: 'label',
      class: 'd-none col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.id}`
    },
    {
      columnDef: 'causal',
      header: 'Nombre del Causal',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.causal}`
    },
    {
      columnDef: 'descripcion',
      header: 'Descripción',
      class: 'col-sm-2',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.descripcion}`
    },
    {
      columnDef: 'impactoIndicador',
      header: 'Impacto al Indicador',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.impactoIndicador == null ? '' :  element.impactoIndicador }`
    },
    {
      columnDef: 'responsable',
      header: 'Responsable',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.responsable}`
    },
    {
      columnDef: 'asignadaPor',
      header: 'Asignado Por',
      type: 'label',
      show: true,
      cell: (element: any) => `${element.asignadaPor}`
    },
    // {
    //   columnDef: 'usuarioId',
    //   header: 'Usuario',
    //   type: 'label',
    //   cell: (element: any) => `${element.usuarioId}`
    // },
    {
      columnDef: 'fechaCreacion',
      header: 'Fecha de Creación',
      type: 'date',
      show: true,
      cell: (element: any) => `${formatDate(element.fechaCreacion,'dd-MM-yyyy','en-US')}`
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      type: 'status',
      show: true,
      cell: (element: any) => `${element.estado}`
    },
    {
      columnDef: 'menu',
      header: 'Acciónes',
      type: 'menu',
      class: 'col-sm-2',
      excludeFilter: true,
      menu: this.tableMenu,
      cell: (element: any) => `${element.menu}`
    }

  ]


  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private causalApiService: causalApiService,
  private snack: snackBar, private router: Router, public dialog: MatDialog, private userData:UserDataService, private notificationsApiService: notificationsApiService) {
    
      this.obtenerPermisos();
  }



  ngOnInit(): void {
    this.getCausales();
 
  }
  obtenerPermisos(){
    this.userData.getAccess$()
    .subscribe((data:any)=>{
    this.access = data;
    });
    return this.access;
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getCausales(id?:number, data?:any){
    let count = 1;
    let dialogSpiner: DialogSpinerComponent = new DialogSpinerComponent(this.dialog);
    dialogSpiner.openDialog();

    this.causalApiService.get(id, data)
    .subscribe(resultData =>{
    this.data = [...resultData.resultObject];
      this.data.forEach((element:data_element) => {
        element.item = count++;
        element.descripcion = element.descripcion == null ? '' : element.descripcion;
        element.menu = element.id;
      });
      dialogSpiner.closeDialog();

    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        dialogSpiner.closeDialog();
      });
    });

  }

  shouldBeBoldRow = (blodRow: any) => {
    return true;
  }

  filterAdv(event:any){
    event = JSON.stringify(event);
    let count = 1;
    this.causalApiService.getFilterCausal(event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
        this.data = [...resultData.resultObject];
          this.data.forEach((element:data_element) => {
            element.descripcion = element.descripcion == null ? '' : element.descripcion;
            element.item = count++;
            element.menu = element.id;
          });
        }else{
          this.data.splice(0, this.data.length);
        }
    });
  }

}

export interface data_element {
  item: number,
  id: string,
  causal: string,
  descripcion: string,
  impactoIndicador: string,
  responsable: string,
  asignadaPor: string,
  usuarioId:string,
  fechaCreacion: Date,
  menu:any
}



