import { auditModels } from './../../../models/audit/audit.module';
import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from './../../../dialog-spiner/dialog-spiner.component';
import { Component, OnInit } from '@angular/core';
import { locationApiService } from 'src/app/services/Provider/locationApiService.module';
import { snackBar } from 'src/app/Utils/snackBar';
import * as XLSX from 'xlsx';
import { UserDataService } from 'src/app/services/user-data.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  result: string = '';
  file: any;
  currentDate: Date = new Date();
  dataString:string = '';
  nameFile: string = "";
  dialogSpiner!: DialogSpinerComponent;
  access:number = 0;
  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private snack: snackBar, private locationApiService: locationApiService, private dialog: MatDialog, private userData:UserDataService, private router: Router,
  private notificationsApiService : notificationsApiService) {
    // this.userData.getAccess$()
    //   .subscribe((data:any)=>{
    //     this.access = data;
    //     if(!(this.access & 4096)){
    //       this.router.navigate(['/home']);
    //     }
    //   });
    this.userData.getAccess$()
      .pipe( takeUntil(this.ngUnsubscribe) )
      .subscribe((data:any)=>{
        this.access = data;
      });
      if(!(this.access & 4096)){
        this.router.navigate(['/home']);
      }
    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
 }

  ngOnInit(): void {
  }

  onFileChange(ev: any) {
    let workBook: any = null;
    let jsonData = null;
    const reader = new FileReader();
    this.result = "";

    // console.log('archivo:'+ev.target.files.length);
    if(ev.target.files.length > 0){
      this.file = ev.target.files[0];
      this.nameFile = this.file.name;

      if(this.nameFile.indexOf('Plantilla-CargueLocalizaciones') === 0){

        reader.onload = () => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          this.dataString = jsonData;
        }
        reader.readAsBinaryString(this.file);
      }else{
       
        this.result = "error: El nombre del archivo no es admitido";
      }

    }
    else{
      this.result = "error: debe seleccionar un archivo de formato tipo XLSX";
    }

  }

  send(){
    this.guardar(this.dataString);
  }

  guardar(json: any){
    if(this.nameFile == ""){

      Swal.fire(
        {
          icon: ('error'),
          title: 'Archivo Faltante',
          text:  'Debe seleccionar un archivo para cargar los productos al sistema',
          confirmButtonText: "OK",
          confirmButtonColor: "#00B398", 
        }
      );
      return;
    }

    Swal.fire({
      title: '¿Desea cargar masivamente estas localizaciones al sistema?',
      // text: 'Una vez realizada esta accion no se podra revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aceptar',
      cancelButtonText: 'No, olvidalo',
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
    if (result.isConfirmed) {
    
    this.dialogSpiner.openDialog();

    let auditModels: auditModels = { 
      archivoNombre: this.nameFile,
      tamanio: this.file.size,
      registros: 0,
      estado: '',
      cadenaId: 0,
      cadena: '',
      urls: '',
      logs: '',
      tipoCargue: '1',
      usuarioId: '',
      fechaArchivo: new Date(),
      fechaCreacion: new Date(),
      fechaModificacion: new Date()
    };
    let errMsg : string = '';
    this.locationApiService.postMasiveLocation(json, auditModels).subscribe((locationgueResult:any) =>{
      
      console.warn(locationgueResult);
      Swal.fire(
        {
          icon: (locationgueResult.resultObject[0] ? 'success':'error'),
          title: (locationgueResult.resultObject[0] ? 'Registros Cargados!':'Algo no esta bien'),
          text: locationgueResult.resultObject[0],
          confirmButtonText: "OK",
          confirmButtonColor: "#00B398", 
        }
      )

      if(locationgueResult.resultObject[0] != null){

        this.result = locationgueResult.resultObject[0].toString();
        this.notificationsApiService.InsertarNotificaciones('Carga Masiva de Localizaciones','El Archivo de Localizaciones '+this.nameFile+' ha sido cargado correctamente', 'provider');
      }
      else{
        this.result = locationgueResult.ResultMessage;
        this.snack.openSnackBar('Ha ocurrido un error al guardar la carga masiva de localizaciones, favor verificar.', 'OK!');
        this.notificationsApiService.InsertarNotificaciones('Error al cargar el archivo de Carga Masiva de Localizaciones','El Archivo de Localizaciones '+this.nameFile+' no ha sido cargado', 'provider');
      }
      
      this.dialogSpiner.closeDialog();

    },
    (error:HttpErrorResponse) => {
      if(error.status == 400){
        var errorArray = Object.values(error.error.errors);
          
        for (var key in errorArray) {
          //console.log("Ocurrieron los siguietes errores: " + errorArray[key]);
          errMsg += errorArray[key] + ', ';
        }

        Swal.fire(
          {
            icon: ('error'),
            title: 'Existe información errónea o Faltante',
            text:  errMsg+ ' por favor ajustarlo antes de volverlo a cargar',
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
          }
        );

        this.snack.openSnackBar('Existe información errónea o Faltante dentro del archivo, por favor ajustarlo antes de volverlo a cargar '+ errMsg, 'OK!');
      }else{
        this.snack.openSnackBar('Ha ocurrido un error al guardar la información.', 'OK!');
      }
      this.dialogSpiner.closeDialog();
      this.snack.snackBarRef.afterDismissed();
    });
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      {
        icon: 'error',
        title: "Cancelado",
        text: "Elementos no cargado",
        confirmButtonText: "OK",
        confirmButtonColor: "#00B398", 
      })
    }
  });
  this.nameFile = "";
  $("#fileCarga").val("");
  this.dataString = "";
  }

}
