import { Component, OnInit } from '@angular/core';
import { snackBar } from 'src/app/Utils/snackBar';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {
  

  constructor(private snack: snackBar) { }

  ngOnInit(): void {
  }

}
