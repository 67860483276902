<div class="container">
    <div class="row">
        <div class="col">
            <p></p>
            <mat-card>
                <mat-card-title>
                   Carga Masiva de Causal<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
                </mat-card-title>
                <app-bread-crumb></app-bread-crumb>
            </mat-card>
        </div>
        <p></p>
    </div>
    <div class="row">
        <div class="col">
            <mat-card>
                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="" class="mr-3">Descargar Archivo de Excel (.xlsx)</label>
                        <a href="assets/Plantillas-Archivos/Plantilla-CargueCausal.xlsx" download="Plantilla-CargueCausal{{currentDate | date:'yyyy-MM-dd hh_mm_a'}}.xlsx"> <i class="fa fa-download"></i> Archivo Cargue de Causales</a>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                        <!-- <label for="">Carga masiva</label>-->
                        <!-- <input class="form-control" id="" type="file" (change)="onFileChange($event)" /> -->
                        <!-- <input class="form-control" id="" type="file" (change)="this.handleFileLoad"/> -->

                        <label class="btn btn-primary">
                          Carga masiva <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  (change)="onFileChange($event)" id="fileCarga" hidden>
                      </label>

                      {{nameFile}}
                    </div>
                </div>
                <div class="form-row">
                   <p>{{result}}</p>
                </div>
                <div class="row">
                  <div class="col text-right">
                      <button mat-raised-button type="button" (click)="send()"  class="btn-primary">Guardar</button><span class="mx-2 text-muted">|</span>
                      <button type="button" routerLink="/causal" class="btn btn-default">Regresar</button>
                  </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
