<p></p>
<div class="container">
    <div class="row">
        <div class="col">
            <p></p>
            <mat-card>
                <mat-card-title>
                  {{title}} Roles<div id="help" class="float-right"><i class="fa fa-question-circle" aria-hidden="true"></i></div>
                </mat-card-title>
                <app-bread-crumb></app-bread-crumb>
            </mat-card>
        </div>
        <p></p>
    </div>
    <div class="row">
        <div class="col">
            <mat-card>
            <div class="form-row">
                <div class="form-group col">
                    <label for="">Nombre del Rol<span class="text-danger">*</span></label>
                    <input maxlength="255"  [disabled]="accion == 'view'" class="form-control" [(ngModel)]="this._roleModels.name" #name="ngModel" required pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1 _-]+$"/>
                    <span *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                        <span *ngIf="name.errors?.required">
                            * Campo es requerido.
                        </span>
                        <span *ngIf="name.errors?.pattern">
                            * El Nombre del Rol no tiene el formato correcto, solo admite letras,guión y acéntos , ejemplo: Administración - Rol Seguridad.
                        </span>
                    </span>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="">Descripción</label>
                    <textarea maxlength="1000"  [disabled]="accion == 'view'" class="form-control" [(ngModel)]="this._roleModels.normalizedName" #normalizedName="ngModel" cols="30" rows="3" pattern="^[0-9a-zA-ZÀ-ÿ\u00f1\u00d1,. _-]+$"></textarea>
                    <span *ngIf="normalizedName.invalid && (normalizedName.dirty || normalizedName.touched)" class="text-danger">
                        <!-- <span *ngIf="normalizedName.errors?.required">
                            * Campo es requerido.
                        </span> -->
                        <span *ngIf="normalizedName.errors?.pattern">
                            * La Descripción del Rol no tiene el formato correcto, solo letras permitidas, ejemplo: esta es una descripción de prueba.
                        </span>
                    </span>
                </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <ng-container *ngIf="!name.errors?.required && !name.errors?.pattern && !normalizedName.errors?.pattern && accion != 'view'; else elseTemplate">
                  <button type="button" class="btn btn-primary" (click)="guardar();" [disabled]="false">Guardar</button>
                </ng-container>
                <ng-template #elseTemplate>
                  <button type="button" class="btn btn-primary" [disabled]="true" >Guardar</button>
                </ng-template>
                <span class="text-muted mx-2">|</span>
                <button type="button" routerLink="/role" class="btn btn-default">Regresar</button>
              </div>
            </div>
        </mat-card>
        </div>

    </div>
</div>
