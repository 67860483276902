import { Component, Inject, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-dialog-spiner',
  templateUrl: './dialog-spiner.component.html',
  styleUrls: ['./dialog-spiner.component.css']
})
export class DialogSpinerComponent implements OnInit {

  color: ThemePalette = 'warn';
  mode: ProgressSpinnerMode = 'indeterminate';


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
  }

  openDialog(): void {
    this.dialog.open(DialogSpinerComponent, { disableClose: true, panelClass: 'custom-dialog' });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

}
