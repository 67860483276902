import { locationApiService } from 'src/app/services/Provider/locationApiService.module';
import { catalogueApiService } from './../../../services/Provider/catalogueApiService.module';
import { Router } from '@angular/router';
import { columns } from 'src/app/models/table-column';
import { Component, OnInit } from '@angular/core';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { snackBar } from 'src/app/Utils/snackBar';
import { MatDialog } from '@angular/material/dialog';
import { UserDataService } from 'src/app/services/user-data.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { menuOptions } from 'src/app/models/menu.interface';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
@Component({
  selector: 'app-catalogue-view',
  templateUrl: './catalogue-view.component.html',
  styleUrls: ['./catalogue-view.component.css']
})
export class CatalogueViewComponent implements OnInit {
  access:number = 0;
  dataProduct: any;
  dataLocation: any;
  tableProductId : string = "Product";
  tableLocationId : string = "Location";
  titletableProduct: string = 'Productos';
  titletableLocation: string = 'Localizaciones';
  eventFilterProduct : any;
  eventFilterLocation : any;
  dialogSpiner: DialogSpinerComponent = new DialogSpinerComponent(this.dialog);

    tableMenu: menuOptions[] = [
        {
      icon : 'change_circle',
      label: 'Estado',
      show: (this.obtenerPermisos() & 128 ? true:false),
      callback: (parametro:any, data?:any) => {
        // this.router.navigate(['/causal/'+parametro+'/view/'])
        //console.warn(parametro);
        
        let varEstado = 2;
            let codigo : number = 0;
            let titleNoti : string = '';
            if(data.estado == 'inactivo'){
              varEstado = 1;
            }
            
            if(parametro == 'Producto'){
              titleNoti = 'del Producto: '+data.gtin_Producto+' - '+data.producto;
              codigo = data.gtin_Producto;
              
            }
            else if(parametro == 'Localización'){
              titleNoti = 'la Localización: '+data.id+' - '+data.localizacion;
              codigo = data.id;
              //console.warn(data);
            }

            //console.warn(titleNoti);

        Swal.fire({
          title: 'Desea cambiar el estado de este elemento?',
          // text: 'Una vez realizada esta accion no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, cambiarle el estado',
          cancelButtonText: 'No, olvidalo',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result) => {
          if (result.isConfirmed) {
            
            

            this.catalogueApiService.status(parametro, codigo, varEstado)
            .subscribe(resultData =>{
              if(resultData.resultObject[0].value.succeeded){
               
                if(parametro == 'Producto'){
                  this.filterAdvProduct(this.eventFilterProduct);
                  //this.getProduct();
                }
                else if(parametro == 'Localización'){
                  this.filterAdvLocation(this.eventFilterLocation);
                  //this.getLocation();
                }
                  this.notificationsApiService.InsertarNotificaciones('Cambio de estado '+titleNoti, ' el registro ha cambiado de estado a '+ (varEstado == 1 ? 'activo' : 'inactivo'), parametro);
              }
              else{
                this.notificationsApiService.InsertarNotificaciones('Error en el Cambio de estado '+titleNoti, 'Ha ocurrido un error al cambiar el estado '+titleNoti+' '+ (varEstado == 1 ? 'activo' : 'inactivo'), parametro) + 'y no se actualizó';
              }
              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Estado cambiado!':'Algo no esta bien'),
                  text: resultData.resultObject[0].value.msg,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              );
              
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              {
                icon: 'error',
                title: "Cancelado",
                text: "Elemento no actualizado",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            })
          }
        });
     }
    },
    {
      icon : 'delete_forever',
      label: 'Eliminar',
      show: (this.obtenerPermisos() & 8 ? true:false),
      // disabled:true,
      callback: (parametro:any, data?:any) => {

        Swal.fire({
          title: 'Esta seguro que desea eliminar este elemento?',
          text: 'Una vez realizada esta acción no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo',
          cancelButtonText: 'No, mantenlo',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result) => {
          if (result.isConfirmed) {
            let varEstado = 2;
            let codigo : number = 0;
            let titleNoti : string = '';
            if(data.estado == 'inactivo'){
              varEstado = 1;
            }
            if(parametro == 'Producto'){
              titleNoti = 'del Producto: '+data.gtin_Producto+' - '+data.producto;
              codigo = data.gtin_Producto;
            }
            else if(parametro == 'Localización'){
              titleNoti = 'la Localización: '+data.id+' - '+data.localizacion;
              //console.warn(data);
              codigo = data.id;
            }

            this.catalogueApiService.delete(parametro, codigo )
            .subscribe(resultData =>{

              if(resultData.resultObject[0].value.succeeded){
                if(parametro == 'Producto'){
                  this.filterAdvProduct(this.eventFilterProduct);
                  //this.getProduct();
                }
                else if(parametro == 'Localización'){
                  this.filterAdvLocation(this.eventFilterLocation);
                  //this.getLocation();
                }
                this.notificationsApiService.InsertarNotificaciones('Cambio de estado '+titleNoti, ' el registro ha cambiado de estado a '+ (varEstado == 1 ? 'activo' : 'inactivo'), parametro);
              }
              else{
                this.notificationsApiService.InsertarNotificaciones('Error en el Cambio de estado '+titleNoti, 'Ha ocurrido un error al cambiar el estado '+titleNoti+' '+ (varEstado == 1 ? 'activo' : 'inactivo'), parametro) + 'y no se actualizó';
              }

              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Registro eliminado!':'Algo no esta bien'),
                  text: resultData.resultObject[0].value.msg,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              );
              this.filterAdvProduct(this.eventFilterProduct);
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              {
                icon: 'error',
                title: "Cancelado",
                text: "Registro no eliminado",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            })
          }
        });
      },
     }

    ];

    columnsProduct: columns<any>[] = [
      {
        columnDef: 'item',
        header: 'Número',
        type: 'label',
        class: 'col-sm-1',
        excludeFilter: true,
        cell: (element: any) => `${element.item}`
      },
      {
        columnDef: 'gTINProducto',
        header: 'GTIN Producto',
        type: 'label',
        class: 'col-sm-2',
        show: true,
        cell: (element: any) => `${element.gTINProducto}`
      },
      {
        columnDef: 'codigoInternoProducto',
        header: 'Código Interno',
        type: 'label',
        class: 'col-sm-3',
        show: false,
        cell: (element: any) => `${element.codigoInternoProducto}`
      },
      {
        columnDef: 'producto',
        header: 'Producto',
        type: 'label',
        class: 'col-sm-3',
        show: true,
        cell: (element: any) => `${element.producto}`
      },
      {
        columnDef: 'descripcionProducto',
        header: 'Descripción Producto',
        type: 'label',
        class: 'col-sm-3',
        show: false,
        cell: (element: any) => `${element.descripcionProducto}`
      },
      {
        columnDef: 'categoriaId1',
        header: 'Categoria 1',
        type: 'label',
        class: 'col-sm-1',
        show: true,
        cell: (element: any) => `${element.categoriaId1}`
      },
      {
        columnDef: 'categoriaId2',
        header: 'Categoria 2',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.categoriaId2}`
      },
      {
        columnDef: 'categoriaId3',
        header: 'Categoria 3',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.categoriaId3}`
      },
      {
        columnDef: 'categoriaId4',
        header: 'Categoria 4',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.categoriaId4}`
      },
      {
        columnDef: 'fabricante',
        header: 'Fabricante',
        type: 'label',
        class: 'col-sm-1',
        show: true,
        cell: (element: any) => `${element.fabricante}`
      },
      {
        columnDef: 'marca',
        header: 'Marca',
        type: 'label',
        class: 'col-sm-1',
        show: true,
        cell: (element: any) => `${element.marca}`
      },
      {
        columnDef: 'prioridad',
        header: 'Prioridad',
        type: 'label',
        class: 'col-sm-1',
        show: true,
        cell: (element: any) => `${element.prioridad}`
      },
      {
        columnDef: 'estado',
        header: 'Estado',
        type: 'status',
        class: 'col-sm-1',
        cell: (element: any) => `${element.estado}`
      },
      {
        columnDef: 'fechaCreacion',
        header: 'Creación',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.fechaCreacion}`
      },
      {
        columnDef: 'fechaModificacion',
        header: 'Actualización',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.fechaModificacion}`
      },
      // {.
      //   columnDef: 'icon',
      //   header: 'icono de fontIcon',
      //   type: 'mat-icon',
      //   class: 'col-sm-1 p-2',
      //   cell: (element: any) => `${element.icon}`
      // }
      {
        columnDef: 'menu',
        header: 'Acciónes',
        type: 'menu',
        class: 'col-sm-2',
        excludeFilter: true,
        menu: this.tableMenu,
        cell: (element: any) => `${element.menu}`
      }
    ];

    columnsLocation: columns<any>[] = [
      {
        columnDef: 'item',
        header: 'Número',
        type: 'label',
        class: 'col-sm-1',
        excludeFilter: true,
        cell: (element: any) => `${element.item}`
      },
      {
        columnDef: 'id',
        header: 'Gln Localización',
        type: 'label',
        class: 'col-sm-2',
        show: false,
        cell: (element: any) => `${element.id}`
      },
      {
        columnDef: 'localizacion',
        header: 'Localización',
        type: 'label',
        class: 'col-sm-3',
        show: false,
        cell: (element: any) => `${element.localizacion}`
      },
      {
        columnDef: 'cadenaId',
        header: 'Cadena Id',
        type: 'label',
        class: 'col-sm-3',
        show: true,
        cell: (element: any) => `${element.cadenaId}`
      },
      {
        columnDef: 'codigoInternoLocalizacion',
        header: 'Código Interno',
        type: 'label',
        class: 'col-sm-3',
        show: true,
        cell: (element: any) => `${element.codigoInternoLocalizacion}`
      },
      {
        columnDef: 'categorizacionNivel1',
        header: 'Categorización Nivel 1',
        type: 'label',
        class: 'col-sm-1',
        show: true,
        cell: (element: any) => `${element.categorizacionNivel1}`
      },
      {
        columnDef: 'categorizacionNivel2',
        header: 'Categorización Nivel 2',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.categorizacionNivel2}`
      },
      {
        columnDef: 'categorizacionNivel3',
        header: 'Categorización Nivel 3',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.categorizacionNivel3}`
      },
      {
        columnDef: 'categorizacionNivel4',
        header: 'Categorización Nivel 4',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.categorizacionNivel4}`
      },
      {
        columnDef: 'direccion',
        header: 'Dirección',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.direccion}`
      },
      {
        columnDef: 'formato',
        header: 'Formato',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.formato}`
      },
      {
        columnDef: 'region',
        header: 'Región',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.region}`
      },
      {
        columnDef: 'tipoLocalizacion',
        header: 'Tipo Localización',
        type: 'label',
        class: 'col-sm-1',
        show: true,
        cell: (element: any) => `${element.tipoLocalizacion}`
      },
      {
        columnDef: 'estado',
        header: 'Estado',
        type: 'status',
        class: 'col-sm-1',
        show: true,
        cell: (element: any) => `${element.estado}`
      },
      {
        columnDef: 'fechaCreacion',
        header: 'Creación',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.fechaCreacion}`
      },
      {
        columnDef: 'fechaModificacion',
        header: 'Actualización',
        type: 'label',
        class: 'col-sm-1',
        show: false,
        cell: (element: any) => `${element.fechaModificacion}`
      },
      // {
      //   columnDef: 'icon',
      //   header: 'icono de fontIcon',
      //   type: 'mat-icon',
      //   class: 'col-sm-1 p-2',
      //   cell: (element: any) => `${element.icon}`
      // }
      {
        columnDef: 'menu',
        header: 'Acciónes',
        type: 'menu',
        class: 'col-sm-2',
        excludeFilter: true,
        menu: this.tableMenu,
        cell: (element: any) => `${element.menu}`
      }
    ];



  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private router: Router,
    private snack: snackBar, public dialog: MatDialog, private catalogueApiService: catalogueApiService,  private locationApiService: locationApiService, private userData:UserDataService, private notificationsApiService: notificationsApiService) { 
      
      this.obtenerPermisos();
    }

    obtenerPermisos(){
      this.userData.getAccess$()
      .subscribe((data:any)=>{
      this.access = data;
      });
      return this.access;
    }

  ngOnInit(): void {
    Swal.fire(
      {
        icon: 'info',
        title: 'Filtrar Registros!',
        text: 'La ventana no mostrara registros al inicio o carga inicial, solo se muestra la información asociada al filtro que seleccione y aplique',
        confirmButtonText: "OK",
        confirmButtonColor: "#00B398", 
      });
    // this.getProduct();
    // this.getLocation();
  }

  getProduct(){
    let count = 1;

    this.dialogSpiner.openDialog();

    this.catalogueApiService.GetProduct().subscribe(resultData =>{
      console.warn(resultData);
      if(resultData.resultObject != undefined){
        this.dataProduct = [...resultData.resultObject];
        this.dataProduct.forEach((element:data_element) => {
          element.item = count++
          //element.menu = element.productoId;
          element.menu = 'Producto';
        });
      }
      
      this.dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {

        this.dialogSpiner.closeDialog();
      });
    });
  }
  getLocation(){
    let count = 1;
    
    this.dialogSpiner.openDialog();

    this.locationApiService.GetLocation().subscribe(resultData =>{
      console.warn(resultData);
      if(resultData.resultObject != undefined){
        this.dataLocation = [...resultData.resultObject];
        this.dataLocation.forEach((element:data_element) => {
          element.item = count++
          //element.menu = element.productoId;
          element.menu = 'Localización';
        });
      }
      
      this.dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {

        this.dialogSpiner.closeDialog();
      });
    });
  }


  filterAdvProduct(event:any){
    this.eventFilterProduct = event;
    this.dialogSpiner.openDialog();
    event = JSON.stringify(event);
    let count = 1;
    this.catalogueApiService.getFilter(event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
      this.dataProduct = [...resultData.resultObject];
        this.dataProduct.forEach((element:any) => {
          element.item = count++;
          element.menu = 'Producto';
        });
        this.dialogSpiner.closeDialog();
      }else{
        this.dataProduct.splice(0, this.dataProduct.length);
      }
    });
  }


  filterAdvLocation(event:any){
    this.eventFilterLocation = event;
    this.dialogSpiner.openDialog();
    event = JSON.stringify(event);
    let count = 1;
    this.locationApiService.getFilter(event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
        this.dataLocation = [...resultData.resultObject];
        this.dataLocation.forEach((element:any) => {
          element.item = count++;
          element.menu = 'Localización';
        });
        this.dialogSpiner.closeDialog();
      }else{
        this.dataLocation.splice(0, this.dataLocation.length);
      }
    });
  }

}

export interface data_element {
  item: number,
  productoId: bigint,
  producto: string,
  categoria: string,
  menu:any
}