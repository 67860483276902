import { CategoryCreateComponent } from './view/category-create/category-create.component';
import { CategoryComponent } from './view/category/category.component';
import { NotFoundComponent } from './view/not-found/not-found.component';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { InitializerService } from './services/initializer.service';
import { RoleCreateComponent } from './view/role/role-create/role-create.component';
import { RoleViewComponent } from './view/role/role-view/role-view.component';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { userViewComponent } from '../app/view/user/usersView/userView.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DataTablesModule} from 'angular-datatables';
import { UserCreateComponent } from '../app/view/user/user-create.component';
import { RoleClaimsComponent } from './view/role/role-claims/role-claims.component';
import { RoleUsersComponent } from './view/role/role-users/role-users.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { CausalViewComponent } from './view/causal/causal-view/causal-view.component';
import { CausalCreateComponent } from './view/causal/causal-create/causal-create.component';
import { CausalReadComponent } from './view/causal/causal-read/causal-read.component';
import {MatSelectModule} from '@angular/material/select';
import { SidenavbarComponent } from './sidenavbar/sidenavbar.component';
import {BreadcrumbModule} from 'angular-crumbs';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { UserComponent } from './view/user/user/user.component';
import { RoleComponent } from './view/role/role/role.component';
import { CausalComponent } from './view/causal/causal/causal.component';
import { CargaMasivaComponent } from './view/causal/carga-masiva/carga-masiva.component';
import { DatatableComponent } from './datatable/datatable.component';
import { MatTableModule } from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { JSTableComponent } from './js-table/js-table.component';
import { MatMenuModule } from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CdkTableModule} from '@angular/cdk/table';
import { getPaginatorIntl } from './datatable/paginator-intl';
import {MatDividerModule} from '@angular/material/divider';
import { FilterAvanceComponent } from './filter-avance/filter-avance.component';
import {DynamicComponentDirective} from './dynamic-component.directive';
import { ProductComponent } from './view/Product/product/product.component';
import { ProductViewComponent } from './view/Product/product-view/product-view.component';
import { ProductCausalapprovalComponent } from './view/Product/product-causalapproval/product-causalapproval.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DialogSpinerComponent } from './dialog-spiner/dialog-spiner.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HomeComponent } from './home/home.component';
import { LocationComponent } from './view/provider/location/location.component';
import { ProviderComponent } from './view/provider/provider/provider.component';
import { CatalogueComponent } from './view/provider/catalogue/catalogue.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { StatusPipe } from './pipes/status.pipe';
import { InconstrutionComponent } from './view/inconstrution/inconstrution.component';
import { NotificationComponent } from './notification/notification.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { NotificationListComponent } from './view/notification-list/notification-list.component';
import { CategoryCargarMasivaComponent } from './view/category-cargar-masiva/category-cargar-masiva.component';
import { AuditoriaViewComponent } from './view/auditoria/auditoria-view.component';
import { InformesGeneralComponent } from './view/informes-general/informes-general.component';
import { InformesConciliacionComponent } from './view/informes-conciliacion/informes-conciliacion.component';
import { AuditoriaVerComponent } from './view/auditoria/auditoria-ver/auditoria-ver.component';
import { AuditoriaComponent } from './view/auditoria/auditoria/auditoria.component';
import { CatalogueViewComponent } from './view/provider/catalogue-view/catalogue-view.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SearchDropDownComponent } from './search-drop-down/search-drop-down.component';
import { UserChangePassComponent } from './view/user/user-change-pass/user-change-pass.component';

export function AppInit(appInitService: InitializerService) {
  return (): Promise<any> => {
    return appInitService.Init();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ToolbarComponent,
    userViewComponent,
    UserCreateComponent,
    RoleCreateComponent,
    RoleViewComponent,
    RoleClaimsComponent,
    RoleUsersComponent,
    CausalViewComponent,
    CausalCreateComponent,
    CausalReadComponent,
    SidenavbarComponent,
    BreadCrumbComponent,
    UserComponent,
    RoleComponent,
    CausalComponent,
    CargaMasivaComponent,
    DatatableComponent,
    JSTableComponent,
    FilterAvanceComponent,
    DynamicComponentDirective,
    ProductComponent,
    ProductViewComponent,
    ProductCausalapprovalComponent,
    DialogSpinerComponent,
    HomeComponent,
    LocationComponent,
    ProviderComponent,
    CatalogueComponent,
    AppLayoutComponent,
    PasswordResetComponent,
    NotFoundComponent,
    CategoryComponent,
    CategoryCreateComponent,
    userViewComponent,
    StatusPipe,
    InconstrutionComponent,
    NotificationComponent,
    OrderByPipe,
    NotificationListComponent,
    CategoryCargarMasivaComponent,
    AuditoriaViewComponent,
    InformesGeneralComponent,
    InformesConciliacionComponent,
    AuditoriaVerComponent,
    AuditoriaComponent,
    CatalogueViewComponent,
    SearchDropDownComponent,
    UserChangePassComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    DataTablesModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    BreadcrumbModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatMenuModule,
    MatCheckboxModule,
    CdkTableModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
    InitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInit,
      multi: true,
      deps: [InitializerService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
  ],
  entryComponents: [FilterAvanceComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
