import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { auditModels } from 'src/app/models/audit/audit.module';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: "root"
})

export class auditoriaApiService {
  private base_url = '';
  private apiUrl =  '';
  httpOptions: any;

  constructor(private http: HttpClient, private storage: StorageService) { }

  public get(id?: string, data?:any): Observable<any> {
    this.base_url = environment.gnl_url;
    this.apiUrl = this.base_url + 'auditoria';
    // console.log(id);
    if (id != undefined) {
      this.apiUrl = this.apiUrl + "?id=" + id;
    }
    else if(data != undefined){
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Token': this.storage.getInSession('api_token')
        }),
        params: {strFiltro : data}};
      return this.http.get<any>(this.apiUrl, this.httpOptions);
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.get<any>(this.apiUrl, this.httpOptions);
  }

  getFilter(data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(environment.gnl_url+"auditoria", this.httpOptions);
  }

  public post(RequestPayload: auditModels): Observable<any> {
    this.base_url = environment.auth_url;
    this.apiUrl = this.base_url + 'auditoria';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.post<any>(this.apiUrl,  RequestPayload, this.httpOptions);
  }

  public delete(id:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.gnl_url+'causal/delete?id='+id, this.httpOptions);
  }

  public status(id:number, status:boolean): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    return this.http.delete(environment.gnl_url+'causal/inactive?id='+id+'&state='+status, this.httpOptions);
  }

}
