import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { UserDataService } from './../../../services/user-data.service';
import { menuOptions } from './../../../models/menu.interface';
import { Component, OnInit} from '@angular/core';
import { usersApiService } from '../../../services/users/usersApiService.module';
import { snackBar } from '../../../Utils/snackBar';
import { Router } from '@angular/router';
import { columns } from 'src/app/models/table-column';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import Swal from'sweetalert2';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-userView',
  templateUrl: './userView.component.html',
  styleUrls: ['./userView.component.css']
})
export class userViewComponent implements OnInit {

  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private usersApiService: usersApiService, private router: Router,
  private snack: snackBar, public dialog: MatDialog, private userData:UserDataService,
  private notificationsApiService: notificationsApiService) {
    
    this.obtenerPermisos();
    }

    obtenerPermisos(){
      this.userData.getAccess$()
      .subscribe((data:any)=>{
      this.access = data;
      });
      return this.access;
    }

  access:number = 0;
  data: data_element[] = [];

  titletable: string = 'Usuarios';

  tableMenu: menuOptions[] = [
    {
      icon : 'edit',
      label: 'Editar',
      show: (this.obtenerPermisos() & 2 ? true:false),
      callback: (parametro:any) => { this.router.navigate(['/user/'+parametro+'/edit/']) }
    },
    {
      icon : 'manage_search',
      label: 'Ver',
      show: (this.obtenerPermisos() & 4 ? true:false),
      callback: (parametro:any) => { this.router.navigate(['/user/'+parametro+'/view/']) }
    },
    {
      icon : 'change_circle',
      label: 'Estado',
      show: (this.obtenerPermisos() & 128 ? true:false),
      callback: (parametro:any, data?:any) => {
        Swal.fire({
          title: 'Desea cambiar el estado de este usuario?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, cambiar',
          cancelButtonText: 'No, olvidalo',
          buttonsStyling: true,
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result) => {
          if (result.isConfirmed) {
            this.usersApiService.status(parametro, data.lockoutEnabled ? 0: 1)
            .subscribe((resultData:any) =>{
              let estado = data.lockoutEnabled ? 'activado' :  'inactivado';
              if(resultData.resultObject[0].value.succeeded){
                this.getListUser();
                  this.notificationsApiService.InsertarNotificaciones('Usuario Nombre:' + data.nombreApellido + ' ' +estado, 'El usuario ha sido '+estado, 'user');
              }
              else{
                this.notificationsApiService.InsertarNotificaciones('Error al '+estado+' el usuario Nombre:' + data.nombreApellido, 'Ha ocurrido un error, el usuario no fue '+estado, 'user');
              }
              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Estado cambiado!':'Algo no esta bien'),
                  text: resultData.resultObject[0].value.msg,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              )
              this.getListUser();
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              {
                icon: 'error',
                title: "Cancelado",
                text: "Elemento no actualizado",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            }
            )
          }
        });
     }
    },
    {
      icon : 'delete_forever',
      label: 'Eliminar',
      show: (this.obtenerPermisos() & 8 ? true:false),
      callback: (parametro:any, data?:any) => {
        // this.router.navigate(['/user/'+parametro+'/view/']);
        Swal.fire({
          title: 'Esta seguro que desea eliminar este elemento?',
          text: 'Una vez realizada esta acción no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo',
          cancelButtonText: 'No, mantenerlo',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result:any) => {
          if (result.isConfirmed) {
            this.usersApiService.delete(parametro)
            .subscribe((resultData:any) =>{
              
              if(resultData.resultObject[0].value.succeeded){
                this.getListUser();
                  this.notificationsApiService.InsertarNotificaciones('Usuario Nombre:' + data.nombreApellido + ' Eliminado', 'El usuario ha sido eliminado ', 'user');
              }
              else{
                this.notificationsApiService.InsertarNotificaciones('Error al eliminar el usuario Nombre:' + data.nombreApellido, 'Ha ocurrido un error, el usuario no fue eliminado', 'user');
              }
              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Elemento ha sido eliminado!':'Algo no esta bien'),
                  text: resultData.resultObject[0].value.msg,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398",
                }
              )
              this.getListUser();
            });
            // });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire( {
              icon: 'error',
              title: "Cancelado",
              text: "Elemento no actualizado",
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            })
          }
        })
      },
     },
  ];

  columns: columns<any>[] = [
    {
      columnDef: 'item',
      header: 'ítem',
      type: 'label',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.item}`
    },
    {
      columnDef: 'id',
      header: 'Id',
      type: 'label',
      class: 'd-none',
      excludeFilter: true,
      cell: (element: any) => `${element.id}`
    },
    {
      columnDef: 'nombreApellido',
      header: 'Nombre y Apellido',
      type: 'label',
      show: true,
      class: 'col-sm-2',
      cell: (element: any) => `${element.nombreApellido}`
    },
    {
      columnDef: 'email',
      header: 'Correo',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.email}`
    },
    {
      columnDef: 'phoneNumber',
      header: 'Teléfono',
      type: 'phone',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.phoneNumber}`
    },
    {
      columnDef: 'empresa',
      header: 'Empresa o Cadena',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.empresa}`
    },
    {
      columnDef: 'lockoutEnabled',
      header: 'Estado',
      type: 'status',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.lockoutEnabled == false? 'activo': 'inactivo'}`
    },
    {
      columnDef: 'menu',
      header: 'Acciones',
      type: 'menu',
      class: 'col-sm-2',
      excludeFilter: true,
      menu: this.tableMenu,
      cell: (element: any) => `${element.menu}`
    }
  ];

  ngOnInit() {

    this.getListUser();

  }

  getListUser(){
    let count = 1;

    let dialogSpiner: DialogSpinerComponent = new DialogSpinerComponent(this.dialog);
    dialogSpiner.openDialog();

    this.usersApiService.get(undefined)
    .subscribe(resultData =>{
      this.data = [...resultData.resultObject];
      //console.warn(this.data);
      this.data.forEach((element:data_element) => {
        element.item = count++
        element.menu = element.id;
      });
      dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        dialogSpiner.closeDialog();
      });
    });
  }

  filterAdv(event:any){
    event = JSON.stringify(event);
    let count = 1;
    this.usersApiService.getFilter(event)
    .subscribe(resultData =>{
      console.warn(resultData.resultObject);
      if(resultData.resultObject != null){
        this.data = resultData.resultObject;
        this.data.forEach((element:data_element) => {
          element.item = count++;
          element.menu = element.id;
        });
      }else{
        this.data.splice(0, this.data.length);
      }
      
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}


export interface data_element {
  item: number,
  id: string,
  userName: string,
  normalizedUserName: string,
  phoneNumber: string,
  empresa: string,
  direccion: string,
  Nit: string,
  GLN: string,
  lockoutEnabled: boolean,
  menu:any,
}
