import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inconstrution',
  templateUrl: './inconstrution.component.html',
  styleUrls: ['./inconstrution.component.css']
})
export class InconstrutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
