import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { snackBar } from 'src/app/Utils/snackBar';
import { auditoriaApiService } from 'src/app/services/Auditoria/auditoriaApiService.module';
import { UserDataService } from 'src/app/services/user-data.service';
import { formatDate } from '@angular/common';
import { usersApiService } from 'src/app/services/users/usersApiService.module';
import { userModels } from 'src/app/models/usersModels/user.module';

@Component({
  selector: 'app-auditoria-ver',
  templateUrl: './auditoria-ver.component.html',
  styleUrls: ['./auditoria-ver.component.css']
})
export class AuditoriaVerComponent implements OnInit {
  data:any  = [];
  titletable: string = 'Roles';
  users: userModels[] = [];
  constructor(private router: Router, private ActivatedRoute: ActivatedRoute,
    private snack: snackBar, public dialog: MatDialog, private auditoriaApiService: auditoriaApiService, private userData: UserDataService, private usersApiService: usersApiService) {
      this.obtenerUsuario();
     }

  ngOnInit(): void {

    

 
    let dialogSpiner: DialogSpinerComponent = new DialogSpinerComponent(this.dialog);
    dialogSpiner.openDialog();

    this.auditoriaApiService.get(this.ActivatedRoute.snapshot.params.id.toString()).subscribe(resultData =>{
      this.data = [...resultData.resultObject];
      //console.warn(this.data);
      
      $("#nombre").html(this.data[0].archivoNombre); 
      $("#tamanio").html(this.data[0].tamanio); 
      $("#registros").html(this.data[0].registros); 
      $("#estado").html(this.data[0].estado); 

      $("#cadena").html(this.data[0].cadena); 
      $("#url").html(this.data[0].urls); 
      $("#tipoCargue").html(this.data[0].tipoCargue); 
      $("#logs").html(this.data[0].logs); 
      $("#fechaArchivo").html(formatDate(this.data[0].fechaArchivo,'dd-MM-yyyy','en-US')); 
      $("#creacion").html(formatDate(this.data[0].fechaCreacion,'dd-MM-yyyy','en-US')); 
      $("#modificacion").html(formatDate(this.data[0].fechaModificacion,'dd-MM-yyyy','en-US')); 
      //console.warn(this.data[0].usuarioId);
      var userResult = this.users.filter((item: any) => item.id == this.data[0].usuarioId);
      //console.warn(userResult[0]);
      if(userResult[0] !== undefined){
        $("#usuario").html(userResult[0].userName); 
      }
      else if(userResult[0] == null){
        $("#usuario").html('Usuario Eliminado')
      }
      else{
        $("#usuario").html('cargue LayOut');
      }

      dialogSpiner.closeDialog();
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {

        dialogSpiner.closeDialog();
      });
    });
  }

  obtenerUsuario(){
    this.usersApiService.get().subscribe((resultData: any) =>{
      this.users = [...resultData.resultObject];
      });
  }

}
