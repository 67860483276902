import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { snackBar } from 'src/app/Utils/snackBar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { usersApiService } from 'src/app/services/users/usersApiService.module';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserDataService } from 'src/app/services/user-data.service';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { userModels } from 'src/app/models/usersModels/user.module';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-user-change-pass',
  templateUrl: './user-change-pass.component.html',
  styleUrls: ['./user-change-pass.component.css']
})
export class UserChangePassComponent implements OnInit {
  access:number = 0;
  dialogSpiner!: DialogSpinerComponent;
  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  token: any;
  passwordHashConfirm: any;
  _userModels : userModels = {
    id: '',
    userName:'',
    nombreApellido: '',
    normalizedUserName: '',
    email: '',
    normalizedEmail: '',
    emailConfirmed: true,
    passwordHash: '',
    securityStamp: '',
    concurrencyStamp: '',
    phoneNumber: '',
    phoneNumberConfirmed: true,
    twoFactorEnabled: false,
    lockoutEnd: new Date(),
    lockoutEnabled: true,
    accessFailedCount: 0,
    empresa: '',
    direccion: '',
    nit: '',
    gln_cadena: 0,
    gln_proveedor: 0
  };

  constructor(private usersApiService: usersApiService,
    private router: Router, private snack: snackBar,
     private route: ActivatedRoute,
     public dialog: MatDialog, private userData:UserDataService,
     public notificationsApiService : notificationsApiService,
     ) {
      // this.userData.getAccess$()
      // .pipe( takeUntil(this.ngUnsubscribe) )
      // .subscribe((data:any)=>{
      //   this.access = data;
      // });
      // this.userData.getAccess$()
      // .pipe( takeUntil(this.ngUnsubscribe) )
      // .subscribe((data:any)=>{
      //   this.access = data;
      // });
      
    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
      this.route.params
      .subscribe((params:any) => {
        if(params.token){
          this.token = params.token;
        }
               
      });
    }

  ngOnInit(): void {
  }
  guardar(){

    
    Swal.fire({
      title: '¿Desea cambiar la Contraseña?',
      // text: 'Una vez realizada esta accion no se podra revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, aceptar',
      cancelButtonText: 'No, olvidalo',
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {
      this.dialogSpiner = new DialogSpinerComponent(this.dialog);
      this.dialogSpiner.openDialog();
      if(this.passwordHashConfirm != this._userModels.passwordHash){
        this.dialogSpiner.closeDialog();
        this.snack.openSnackBar('La contraseña no coincide, favor intentar de nuevo.', 'OK!');
      }
      else{
        this.dialogSpiner.openDialog();
       
        this.usersApiService.changePassword(this.token, this._userModels.passwordHash).subscribe(usersResult =>{
        
          console.log(usersResult);
          Swal.fire(
            {
              icon: (usersResult.resultObject[0].value.succeeded ? 'success':'error'),
              title: (usersResult.resultObject[0].value.succeeded ? 'Cambio de contraseña!':'Algo no esta bien'),
              text: usersResult.resultObject[0].value.msg,
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398",
            }
          )

          if(usersResult.resultObject[0]){
            this.dialogSpiner.closeDialog();
            this.notificationsApiService.InsertarNotificaciones('Cambio de Contraseña', 'El usuario ha sido  Correctamente', 'user');
            this.router.navigate(['/user']);
          }
          else{
            this.notificationsApiService.InsertarNotificaciones('Error al cambiar la contraseña ', 'Ha ocurrido un error al momento de cambiar la contraseña'+this._userModels.nombreApellido, 'user');
            this.snack.openSnackBar('Ha ocurrido un error al crear el usuario, favor verificar.', 'OK!');
            this.dialogSpiner.closeDialog();
          }
          this.dialogSpiner.closeDialog();
        },
        error => {
          console.warn(error);
          this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'OK!');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
          });

        });

      }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Contraseña no cambiada",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        })
      }
    });
  }
}
