import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(items:any, column:string, value:any ): any{
    if(!items) return [];
    if(!column) return items;
    if(column.length < 3) return items;

    column = column.toLowerCase();
    return items.filter( (it:any) => {
          return it[column].toLowerCase().includes(value);
        });
  }

}
