import { MatDialog } from '@angular/material/dialog';
import { DialogSpinerComponent } from './../../../dialog-spiner/dialog-spiner.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiAprovarService } from './../../../services/api-aprovar.service';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { columns } from 'src/app/models/table-column';
import { snackBar } from 'src/app/Utils/snackBar';
import { UserDataService } from 'src/app/services/user-data.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import Swal from'sweetalert2';
import { causalApiService } from 'src/app/services/Products/causalApiService.module';

@Component({
  selector: 'app-product-causalapproval',
  templateUrl: './product-causalapproval.component.html',
  styleUrls: ['./product-causalapproval.component.css']
})
export class ProductCausalapprovalComponent implements OnInit {
  data: any;
  //tablacolumns!: any;
  access:number = 0;
  approval: boolean = false;
  selectCausales:any = [];
  columns: columns<any>[] = [
    {
      columnDef: 'item',
      header: 'Número',
      type: 'label',
      class: 'col-1',
      excludeFilter: true,
      cell: (element: any) => `${element.item}`
    },
    {
      columnDef: 'check',
      header: 'Seleccione',
      type: 'check',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.check}`
    },
    {
      columnDef: 'orden',
      header: 'Número orden de compra',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.orden}`
    },
    // {
    //   columnDef: 'cadenaId',
    //   header: 'GLN Cadena',
    //   type: 'label',
    //   class: 'col-sm-2',
    //   show: false,
    //   cell: (element: any) => `${element.cadenaId}`
    // },
    // {
    //   columnDef: 'cadena',
    //   header: 'Cadena',
    //   type: 'label',
    //   class: 'col-sm-2',
    //   show: true,
    //   cell: (element: any) => `${element.cadena}`
    // },
    {
      columnDef: 'proveedorId',
      header: 'GLN Proveedor',
      type: 'label',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${element.proveedorId}`
    },
    {
      columnDef: 'proveedor',
      header: 'Nombre Proveedor',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.proveedor}`
    },
    {
      columnDef: 'localizacionId',
      header: 'GLN Localización Entrega',
      type: 'label',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${element.localizacionId}`
    },
    {
      columnDef: 'localizacion',
      header: 'Localización',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.localizacion}`
    },
    {
      columnDef: 'productoId',
      header: 'GTIN Producto',
      type: 'label',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${element.productoId}`
    },
    {
      columnDef: 'producto',
      header: 'Nombre del Producto',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.producto}`
    },
    {
      columnDef: 'causal',
      header: 'Causal',
      type: 'label',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${element.causal}`
    },
    {
      columnDef: 'observacionCausal',
      header: 'Observación Proveedor',
      type: 'label',
      class: 'col-sm-3 p-2',
      show: false,
      cell: (element: any) => `${element.observacionCausal ? element.observacionCausal:'' }`
    },
    {
      columnDef: 'responsable',
      header: 'Responsable',
      type: 'label',
      class: 'col-sm-3 p-2',
      show: false,
      cell: (element: any) => `${element.responsable}`
    },
    {
      columnDef: 'otif',
      header: 'Indicador OTIF',
      type: 'label',
      class: 'col-sm-1 p-2',
      show: true,
      cell: (element: any) => `${element.otif}`
    },
    {
      columnDef: 'fechaMaximaEntrega',
      header: 'Fecha de Vencimiento',
      type: 'date',
      class: 'col-sm-1 p-2',
      show: false,
      cell: (element: any) => `${formatDate(element.fechaMaximaEntrega,'dd-MM-yyyy','en-US')}`
    }

  ];

    titletable: string = 'Productos';
    products:any = [];
    causalForm:FormGroup;
    dialogSpiner!: DialogSpinerComponent;
    dataCheckODCAlert: string = '';
    filterEvent: any;
  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private request:ApiAprovarService,
    private requestCausal :causalApiService,
    private causalApiService: causalApiService,
    private router: Router,
    private fb:FormBuilder,
    public dialog: MatDialog,
    public snack: snackBar,
    private userData:UserDataService,
    private notificationsApiService : notificationsApiService) {
      this.userData.getAccess$()
      .subscribe((data:any)=>{
        this.access = data;
      });
      if(!(this.access & 32)){
        this.router.navigate(['/home']);
      }
    this.dialogSpiner = new DialogSpinerComponent(this.dialog);
    this.causalForm = this.fb.group({
      aprobarRechazar: ['', [
        // Validators.required,
      ]],
      detalleOrdenesCompra: ['', [
        Validators.required,
      ]],
      observacion: ['', []]
    });
    this.causalApiService.get()
    .subscribe((data:any) => {
      this.selectCausales = data.resultObject.filter(function(element:any){
        return element.estadoId == 1;
      });
    });
  }

  ngOnInit(): void {
    //this.getCausal();
    Swal.fire(
      {
        icon: 'info',
        title: 'Filtrar Registros!',
        text: 'La ventana no mostrara registros al inicio o carga inicial, solo se muestra la información asociada al filtro que seleccione y aplique',
        confirmButtonText: "OK",
        confirmButtonColor: "#00B398", 
      });
      
    this.CheckODCAlert();
  }
  aprovar(status:boolean){
    this.approval = status;
  }

  submit(){

    var buttonName = document.activeElement!.getAttribute("Name");
      
      Swal.fire({
        title: '¿Esta seguro que desea '+buttonName+' este causal a este o estos productos?',
        text: 'Una vez realizada el o los producto quedara '+buttonName,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, '+buttonName+' el causal',
        cancelButtonText: 'No '+buttonName+' el causal',
        confirmButtonColor: "#00B398", 
        cancelButtonColor: "#FC4C02"
      }).then((result) => {
        if (result.isConfirmed) {
  
    if(!this.causalForm.invalid){
      //console.warn(this.causalForm.value);
      
      this.dialogSpiner.openDialog();
      if(buttonName == "Aprobar"){
        this.approval = true;
      }
      else if(buttonName == "Rechazar"){
        this.approval = false;
      }
      this.causalForm.controls['aprobarRechazar'].setValue(this.approval); 
      
      this.request.post(this.causalForm.value)
      .subscribe((data:any)=>{
        //console.warn(data);
        this.dialogSpiner.closeDialog();
        Swal.fire(
          {
            icon: (data.logycaStatus == 0 ? 'success':'error'),
            title: (data.logycaStatus == 0  ? 'Causal '+buttonName+'!':'Algo no esta bien'),
            text: data.resultMessage,
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
          }
        )

        
        if(data.logycaStatus == 0){
          this.notificationsApiService.InsertarNotificaciones('Asignación del Causal','La Asignación del causal '+this.causalForm.controls['causalId'].value()+' Ha sido '+this.causalForm.controls['aprobarRechazar'].value, 'product');
        }
        else{
          this.snack.openSnackBar('Ha ocurrido un error al asignar el causal a los productos, favor verificar.', 'OK!');
          this.notificationsApiService.InsertarNotificaciones('Error al '+this.causalForm.controls['aprobarRechazar'].value+' el causal a los productos','La '+this.causalForm.controls['aprobarRechazar'].value+' del causal no puede ser realizada', 'product');
        }

        this.causalForm.reset();
        window.location.reload();
        //this.filterAdv(this.filterEvent);

        }, error => {
          this.snack.openSnackBar('Ha ocurrido un error al guardar la información.', 'OK!');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
          });
        
        }); 
      }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Causal no "+buttonName,
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        })
      }
    });
    
  }
  isValid() {
    return this.causalForm.controls;
  }
  getCausal(){
    let count = 1;
    this.dialogSpiner.openDialog();
    this.request.get()
    .subscribe((data:any)=>{
      this.dialogSpiner.closeDialog();
      this.data = [...data.resultObject];
      this.data.forEach((element:data_element) => {
        element.item = count++;
      });
    }, error => {
      this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'OK!');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
      });
    });
  }

  itemsSelected(event:any){
    // console.log('items: ', event);
    this.causalForm.controls['detalleOrdenesCompra'].setValue(event);
  }
  
  filterAdv(event:any){
    this.filterEvent = event;
    this.dialogSpiner.openDialog();
    event = JSON.stringify(event);
    let count = 1;
    this.request.getFilter(event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
        this.data = [...resultData.resultObject];
        //console.warn(resultData.resultObject);
          this.data.forEach((element:any) => {
            element.item = count++;
            element.menu = element.id;
          });
          this.dialogSpiner.closeDialog();
        }else{
          this.data.splice(0, this.data.length);
        }
    });
  }

  CheckODCAlert(){
    var dict: any = [];
    const today2 = new Date();
    today2.setDate(today2.getDate());
    this.requestCausal.getAlertEarly('{"items":[ {"column":"causalId","criteria":"is not null","value":""},{"column":"fechaCausal","criteria":"=","value":"'+formatDate(today2,'yyyy-MM-dd','en-US')+'"}],"condition":"And"}', 'aprobarCausal')
    .subscribe((data:any)=>{
      console.warn(data);

      if(data.resultObject.length > 0){
 
        data.resultObject.forEach((element:any) => {
          this.dataCheckODCAlert += '<br/><b>Orden de Compra No.: </b>' + element.orden + ' productos: '+ element.productoId+', ';
        });

        var n=this.dataCheckODCAlert.lastIndexOf(",");
        this.dataCheckODCAlert =this.dataCheckODCAlert.substring(0,n) 

        Swal.fire(
          {
            icon: 'warning',
            title: "Ordenes de Compra con causal asignados pendiente para su avaluación",
            html: this.dataCheckODCAlert,
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        });

      }
       
    });
  }

}

export interface data_element {
  item: number,
  ordenCompraId: number,
  productoId: number,
  otif: number,
  observacionCausal: string,
  responsable: string,
  fechaCreacion: Date
}


