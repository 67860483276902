export const environment = {
  production: true,

  EnviromentTest:"Produccion",
  auth_url: "https://app-msapigateway-prod.azurewebsites.net/api/",
  gnl_url: "https://app-msapigateway-prod.azurewebsites.net/apiGeneral/",
  odc_url: "https://app-msapigateway-prod.azurewebsites.net/apiOrdenesCompra/",
  prov_url: "https://app-msapigateway-prod.azurewebsites.net/apiProveedores/",
  netWork_url: "https://app-msapigateway-prod.azurewebsites.net/apiCadenas/",
  reportToken: "https://testpowerbiapi.azurewebsites.net/api/User/",
  mail_url: "",
  
  PowerBiUrl: "PowerBI?WorkspaceId=00eabe69-ddaf-429d-ab07-dcabea3c8657&ReportId=",
  PowerBiGeneralCadena: "0152682e-5fd4-474d-822b-947152589c7e",
  PowerBiConciliacionCadena: "0152682e-5fd4-474d-822b-947152589c7e",
  PowerBiBenchMarkingCadena: "4f3d6a0e-d807-4c06-9024-aa4c934f00af",
  PowerBiBenchMarkingProveedores: "95595a7a-db9d-4fd1-bbae-a65e6e58ea97",
  PowerBiGeneralProveedoresPremium: "da8fd2cc-5873-484d-be4e-81f21654a172",
  PowerBiConciliacionProveedoresPremium: "e038efe1-246a-462b-8ec3-b28688fa2994",
  PowerBiConciliacionProveedoresFreemium: "eaa30d74-980c-4e16-9028-4bbc348af9b0",
  PowerBiGeneralProveedoresFreemium: "9d94af12-abe4-4ad5-93c7-a8d26fb9ee95",

  claveUsuario: "AppLogyca2020*",
  nombreUsuario:  "aplicaciones@logyca.com",
  correoCopia: "jsandoval@inovait.co",
  esHtml: true,
  correoOrigen: "aplicaciones@logyca.com",
  id_Aplicacion: 13,
  servidorSmtp: "smtp.office365.com",
  numeroPuerto: 587,
  usarSSL: true
};
