import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import { UserDataService } from './../../../services/user-data.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { menuOptions } from 'src/app/models/menu.interface';
import { columns } from 'src/app/models/table-column';
import { rolesApiService } from 'src/app/services/users/rolesApiService.module';
import { snackBar } from 'src/app/Utils/snackBar';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.css']
})
export class RoleViewComponent implements OnInit {

  data: data_element[] = [];
  titletable: string = 'Roles';
  access:number = 0;

  tableMenu: menuOptions[] = [
    {
      icon : 'edit',
      label: 'Editar',
      show: (this.obtenerPermisos() & 2 ? true:false),
      callback: (parametro:any) => { this.router.navigate(['/role/'+parametro+'/edit/']) }
    },
    {
      icon : 'manage_search',
      label: 'Ver',
      show: (this.obtenerPermisos() & 4 ? true:false),
      callback: (parametro:any) => { this.router.navigate(['/role/'+parametro+'/view/']) }
    },
    {
      icon : 'change_circle',
      label: 'Estado',
      show: (this.obtenerPermisos() & 128 ? true:false),
      callback: (parametro:any, data?:any) => {
        // this.router.navigate(['/causal/'+parametro+'/view/'])

        Swal.fire({
          title: 'Desea cambiar el estado de este rol?',
          // text: 'Una vez realizada esta accion no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, cambiar',
          cancelButtonText: 'No, olvidalo',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result) => {
          if (result.isConfirmed) {
            this.rolesApiService.status(parametro, data.status ? 0: 1)
            .subscribe((resultData:any) =>{
              if(resultData.resultObject[0].value.succeeded){
                this.getRoles();
                this.notificationsApiService.InsertarNotificaciones('Cambio de estado del rol Nombre: '+data.name, 'El rol ha cambiado de estado a '+ (data.status == 0 ? 'activo' : 'inactivo'), 'role');
              }
              else{
                this.notificationsApiService.InsertarNotificaciones('Error en el Cambio de estado del rol nombre: ' + data.name, 'Ha ocurrido un error al cambiar el estado del causal a '+ (data.status == 0 ? 'activo' : 'inactivo'), 'causal') + 'y no fue actualizado';
              }
              
              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Estado cambiado!':'Algo no esta bien'),
                  text: resultData.resultObject[0].value.msg,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              )
              this.getRoles();
            });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              {
                icon: 'error',
                title: "Cancelado",
                text: "Elemento no actualizado",
                confirmButtonText: "OK",
                confirmButtonColor: "#00B398", 
            })
          }
        });
     }
    },
    {
      icon : 'delete_forever',
      label: 'Eliminar',
      show: (this.obtenerPermisos() & 8 ? true:false),
      // disabled:true,
      callback: (parametro:any, data?: any) => {
        // this.router.navigate(['/user/'+parametro+'/view/']);
        Swal.fire({
          title: 'Esta seguro que desea eliminar este elemento?',
          text: 'Una vez realizada esta accion no se podra revertir',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo',
          cancelButtonText: 'No, mantenerlo',
          confirmButtonColor: "#00B398", 
          cancelButtonColor: "#FC4C02"
        }).then((result:any) => {
          if (result.isConfirmed) {

            this.rolesApiService.delete(parametro)
            .subscribe((resultData:any) =>{
              
              if(resultData.resultObject[0].value.succeeded){
                this.getRoles();
                this.notificationsApiService.InsertarNotificaciones('Eliminar rol Nombre: '+data.name, 'El rol '+data.name+' ha sido eliminado', 'role');
              }
              else{
                this.notificationsApiService.InsertarNotificaciones('Error al eliminar el rol nombre: ' + data.name, 'Ha ocurrido un error al eliminar el rol ' + data.name, 'role');
              }
              Swal.fire(
                {
                  icon: (resultData.resultObject[0].value.succeeded ? 'success':'error'),
                  title: (resultData.resultObject[0].value.succeeded ? 'Rol eliminado!':'Algo no esta bien'),
                  text: resultData.resultObject[0].value.msg,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#00B398", 
                }
              )
              
             });

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire( {
              icon: 'error',
              title: "Cancelado",
              text: "Elemento no actualizado",
              confirmButtonText: "OK",
              confirmButtonColor: "#00B398", 
            }
            )
          }
        })
      },
     },
     {
      icon : 'assignment_turned_in',
      label: 'Asignar Permisos y Usuarios',
      show: (this.obtenerPermisos() & 256 ? true:false),
      callback: (parametro:any) => { this.router.navigate(['/role/rol/assign/'+parametro]); },
     },
  ];

  columns: columns<any>[] = [
    {
      columnDef: 'item',
      header: 'Número',
      type: 'label',
      class: 'col-sm-1',
      excludeFilter: true,
      cell: (element: any) => `${element.item}`
    },
    {
      columnDef: 'id',
      header: 'Id',
      type: 'label',
      class: 'd-none',
      excludeFilter: true,
      cell: (element: any) => `${element.id}`
    },
    {
      columnDef: 'name',
      header: 'Nombre del Rol',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${element.name}`
    },
    {
      columnDef: 'normalizedName',
      header: 'Descripción',
      type: 'label',
      class: 'col-sm-3',
      show: true,
      cell: (element: any) => `${element.normalizedName}`
    },
    {
      columnDef: 'create',
      header: 'Fecha Creación',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${formatDate(element.create,'dd-MM-yyyy hh:mm:ss','en-US')}`
    },
    {
      columnDef: 'update',
      header: 'Fecha Modificación',
      type: 'label',
      class: 'col-sm-2',
      show: true,
      cell: (element: any) => `${formatDate(element.update,'dd-MM-yyyy hh:mm:ss','en-US')}`
    },
    {
      columnDef: 'status',
      header: 'Estado',
      type: 'status',
      class: 'col-sm-1',
      show: true,
      cell: (element: any) => `${element.status ? 'activo': 'inactivo'}`
    },
    {
      columnDef: 'inactiveDate',
      header: 'Fecha de Inactivación',
      type: 'date',
      class: 'col-sm-2',
      show: false,
      cell: (element: any) => `${element.inactiveDate == null ? '' : formatDate(element.inactiveDate,'dd-MM-yyyy','en-US')}`
    },
    {
      columnDef: 'menu',
      header: 'Acciones',
      type: 'menu',
      class: 'col-sm-2',
      excludeFilter: true,
      menu: this.tableMenu,
      cell: (element: any) => `${element.menu}`
    }
  ];

  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private rolesApiService: rolesApiService, private router: Router,
     private snack: snackBar, public dialog: MatDialog, private userData:UserDataService,
     private notificationsApiService: notificationsApiService) {
     this.obtenerPermisos();
  }
  obtenerPermisos(){
    this.userData.getAccess$()
    .subscribe((data:any)=>{
    this.access = data;
    });
    return this.access;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  filterAdv(event:any){
    
    event = JSON.stringify(event);
    let count = 1;
    this.rolesApiService.getFilter(event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
        this.data = [...resultData.resultObject];
          this.data.forEach((element:data_element) => {
            element.item = count++;
            element.menu = element.id;
          });
      }else{
        this.data.splice(0, this.data.length);
      }
    });
  }

  ngOnInit(): void {

    this.getRoles();
    
  }
  getRoles(){
    let count = 1;
    let dialogSpiner: DialogSpinerComponent = new DialogSpinerComponent(this.dialog);
    dialogSpiner.openDialog();
    this.rolesApiService.get().subscribe(resultData =>{
      this.data = [...resultData.resultObject];
      this.data.forEach((element:data_element) => {
        element.item = count++
        element.menu = element.id;
      });
      dialogSpiner.closeDialog();
      //console.warn(this.data);
    },
    error => {
      this.snack.openSnackBar('ha ocurrido un error al mostrar la información, favor consultar con el administrador del sistema.', 'OK');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {

        dialogSpiner.closeDialog();
      });
    });
  }
}

export interface data_element {
  item: number,
  id: string,
  name: string,
  normalizedName: string,
  create: Date,
  update: Date,
  menu:any
}
