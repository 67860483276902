import { causalsModels } from '../../models/productsModels/causals.module';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { auditModels } from 'src/app/models/audit/audit.module';
import { UserDataService } from '../user-data.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: "root"
})

export class catalogueApiService {
  private base_url = environment.prov_url;
  userId:string = '';
  gln_cadena : number = 0;
  gln_proveedor : number = 0;
  httpOptions: any;
  constructor(private http: HttpClient, private userData : UserDataService, private storage: StorageService) {
    this.userData.getData$()
    .subscribe((data:any)=>{
      this.userId = data.userId;
      this.gln_cadena = data.gln_cadena;
      this.gln_proveedor = data.gln_proveedor;
    });
 
  }

  public PostMasiveProduct(RequestPayload: any, audit: auditModels): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    let userId = this.userId; 
    audit.cadenaId = this.gln_cadena;
    audit.usuarioId = userId;

    return this.http.post<string>(this.base_url+'CargaMasiva/PostMasiveProduct/'+userId, {  RequestPayload, audit}, this.httpOptions);
  }

  public GetProduct(): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    this.base_url = environment.prov_url+ 'CargaMasiva/GetProductView';
    if(this.gln_proveedor != 0){
      return this.http.get<any>(this.base_url + "?gln_proveedor="+this.gln_proveedor, this.httpOptions);
    }
    else{
      return this.http.get<any>(this.base_url, this.httpOptions);
    }
    
  }

  getFilter(data:any): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
    this.base_url = environment.prov_url+ 'CargaMasiva/GetProductView';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      }),
      params: {strFiltro : data}};
    return this.http.get<any>(this.base_url+"?gln_proveedor="+this.gln_proveedor, this.httpOptions );
  }

  public delete(parametro: string, codigo: number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};
      console.warn(parametro);
      console.warn(codigo);
      console.warn(this.gln_proveedor);
      let parametrosUrl : string = '';
      if(parametro == 'Producto'){
         parametrosUrl = '&gln_producto='+codigo;
      }
      else if(parametro == 'Localización'){
         parametrosUrl = '&gln_localizacion='+codigo;
      }
      
    return this.http.delete(environment.prov_url+'CargaMasiva/delete?gln_proveedor='+this.gln_proveedor+parametrosUrl, this.httpOptions);
  }

  public status(parametro: string, codigo: number, status:number): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Token': this.storage.getInSession('api_token')
      })};

      let parametrosUrl: string = '';
      if(parametro == 'Producto'){
        parametrosUrl = '/'+codigo+'/0/';
      }
      else if(parametro == 'Localización'){
        parametrosUrl = '/0/'+codigo+'/';
      }
    
    return this.http.delete(environment.prov_url+'CargaMasiva/inactive'+parametrosUrl+status, this.httpOptions);
  }


}
