import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-causal-read',
  templateUrl: './causal-read.component.html',
  styleUrls: ['./causal-read.component.css']
})
export class CausalReadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
