import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private isLogged:boolean = false;
  private data:Object = {};
  private access:Object = {};

  private status$ = new BehaviorSubject<boolean>(this.isLogged);
  private data$   = new BehaviorSubject<Object>(this.data);
  private access$   = new BehaviorSubject<Object>(this.access);

  constructor(private storage:StorageService) { }

  setData(data:Object) {
    this.data = data;
    this.data$.next(this.data);
  }

  getData$(): Observable<Object> {
    return this.data$.asObservable();
  }

  getDataValue():Object {
    return this.data$.value;
  }

  setIsLoggedIn(data:boolean) {
    this.isLogged = data;
    this.status$.next(this.isLogged);
  }

  isLoggedIn$(): Observable<boolean> {
    return this.status$.asObservable();
  }

  public isLoggedInValue():boolean {
    return this.status$.value;
  }

  logOut(){
    this.setData({});
    this.setIsLoggedIn(false);
    this.storage.clearSession();
    setTimeout(()=>{
      location.reload();
    }, 1000);

  }

  setAccess(data:Object) {
    this.access = data;
    this.access$.next(this.access);
  }

  getAccess$(): Observable<Object> {
    return this.access$.asObservable();
  }

  getAccessValue():Object {
    return this.access$.value;
  }
}
