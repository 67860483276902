import { Router } from '@angular/router';
import { menuOptions } from './../../../models/menu.interface';
import { causalApiService } from 'src/app/services/Products/causalApiService.module';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Attribute, Component, OnInit } from '@angular/core';
import { columns } from 'src/app/models/table-column';
import Swal from'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { snackBar } from 'src/app/Utils/snackBar';
import { DialogSpinerComponent } from 'src/app/dialog-spiner/dialog-spiner.component';
import { UserDataService } from 'src/app/services/user-data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { notificationsApiService } from 'src/app/services/Notificaciones/notificationsApiService.module';
import * as $ from 'jquery';
import * as bootstrap from 'bootstrap';
import { causalModels } from 'src/app/models/causalesModels/causal.module';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})
export class ProductViewComponent implements OnInit {

    titletable: string = 'Productos';
    causalForm:FormGroup;
    selectCausales : any;
    itemsSelectRow:any = [];
    data: any;
    dataCheckODCAlert: string = '';
    dialogSpiner!: DialogSpinerComponent;
    access:number = 0;
    responsable: string = '';
    filterEvent: any;
    // tableMenu: menuOptions[] = [
    //   // {
    //   //   icon : 'edit',
    //   //   label: 'Editar',
    //   //   callback: (parametro:any) => { this.router.navigate(['/user/'+parametro+'/edit/']) }
    //   // },
    //   // {
    //   //   icon : 'manage_search',
    //   //   label: 'Ver',
    //   //   callback: (parametro:any) => { this.router.navigate(['/user/'+parametro+'/view/']) }
    //   // },
    //   {
    //     icon : 'delete_forever',
    //     label: 'Eliminar',
    //     // disabled:true,
    //     callback: (parametro:any) => {
    //       // this.router.navigate(['/user/'+parametro+'/view/']);
    //       Swal.fire({
    //         title: 'Esta seguro que desea eliminar este elemento?',
    //         text: 'Una vez realizada esta accion no se podra revertir',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Si, eliminalo',
    //         cancelButtonText: 'No, mantenlo'
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           Swal.fire(
    //             'Eliminado!',
    //             'Elemento ha sido eliminado.',
    //             'success'
    //           )

    //         } else if (result.dismiss === Swal.DismissReason.cancel) {
    //           Swal.fire(
    //             'Cancelado',
    //             'elemento a salvo :)',
    //             'error'
    //           )
    //         }
    //       })
    //     },
    //    },

    // ];

    columns: columns<any>[] = [
      {
        columnDef: 'item',
        header: 'Número',
        type: 'label',
        class: 'col-1',
        excludeFilter: true,
        cell: (element: any) => `${element.item}`
      },
      {
        columnDef: 'check',
        header: 'Seleccione',
        type: 'check',
        class: 'col-sm-1',
        excludeFilter: true,
        cell: (element: any) => `${element.check}`
      },
      {
        columnDef: 'orden',
        header: 'Número Orden de Compra',
        type: 'label',
        class: 'col-sm-2',
        show: true,
        cell: (element: any) => `${element.orden}`
      },
      {
        columnDef: 'cadenaId',
        header: 'GLN Cadena',
        type: 'label',
        class: 'col-sm-2',
        show: false,
        cell: (element: any) => `${element.cadenaId}`
      },
      {
        columnDef: 'cadena',
        header: 'Nombre Cadena',
        type: 'label',
        class: 'col-sm-2',
        show: true,
        cell: (element: any) => `${element.cadena}`
      },
      {
        columnDef: 'localizacionId',
        header: 'GLN Localización Entrega',
        type: 'label',
        class: 'col-sm-2',
        show: false,
        cell: (element: any) => `${element.localizacionId}`
      },
      {
        columnDef: 'localizacion',
        header: 'Localización Entrega',
        type: 'label',
        class: 'col-sm-2',
        show: true,
        cell: (element: any) => `${element.localizacion}`
      },
      {
        columnDef: 'productoId',
        header: 'GTIN Producto',
        type: 'label',
        class: 'col-sm-2',
        show: false,
        cell: (element: any) => `${element.productoId}`
      },
      {
        columnDef: 'producto',
        header: 'Nombre Producto',
        type: 'label',
        class: 'col-sm-2',
        show: true,
        cell: (element: any) => `${element.producto}`
      },
      {
        columnDef: 'causal',
        header: 'Causal',
        type: 'label',
        class: 'col-sm-2',
        show: false,
        cell: (element: any) => `${element.causal}`
      },
      {
        columnDef: 'observacionCausal',
        header: 'Observación del Causal',
        type: 'label',
        class: 'col-sm-3 p-2',
        show: false,
        cell: (element: any) => `${element.observacionCausal ? element.observacionCausal:'' }`
      },
      {
        columnDef: 'otif',
        header: 'Indicador OTIF',
        type: 'label',
        class: 'col-sm-1 p-2',
        show: true,
        cell: (element: any) => `${element.otif}`
      },
      {
        columnDef: 'aprobadoRechazado',
        header: 'Estado',
        type: 'status',
        class: 'col-sm-1 p-2',
        show: true,
        cell: (element: any) => `${element.aprobadoRechazado == 0 ? 'rechazado' : 'pendiente'}`
      },
      {
        columnDef: 'fechaMaximaEntrega',
        header: 'Fecha de Vencimiento',
        type: 'date',
        class: 'col-sm-1 p-2',
        show: false,
        cell: (element: any) => `${formatDate(element.fechaMaximaEntrega,'dd-MM-yyyy','en-US')}`
      }

    ];

    $: any;

  protected ngUnsubscribe:Subject<void> = new Subject<void>();
  constructor(private fb:FormBuilder,
    private request:causalApiService,
    private router: Router,
    public dialog: MatDialog,
    public snack: snackBar, private userData:UserDataService,
    public notificationsApiService : notificationsApiService) {
      this.obtenerPermisos();
      if(!(this.access & 256 )){
        this.router.navigate(['/home']);
      }
      this.dialogSpiner = new DialogSpinerComponent(this.dialog);
      this.causalForm = this.fb.group({
      causalId: ['', [
        Validators.required,
      ]],
      detalleOrdenesCompra: ['', [
        Validators.required,
      ]],
      observacion: ['', []]
    });



    this.request.getCausales()
    .toPromise().then((data:any) => {

      if(data.resultObject.length > 0){
        
        let datafilter: any = data.resultObject.filter(function(element:any){
          return element.estadoId == 1;
        });
     
        this.selectCausales = [...datafilter];
        
        this.selectCausales.forEach((element:causalModels) => {
          element.value = element.id;
          element.name = element.causal
        });

      }

      
    });

    this.CheckODCAlert();

  }

  ngOnInit(): void {
    
    //this.getProducts();
    Swal.fire(
      {
        icon: 'info',
        title: 'Filtrar Registros!',
        text: 'La ventana no mostrara registros al inicio o carga inicial, solo se muestra la información asociada al filtro que seleccione y aplique',
        confirmButtonText: "OK",
        confirmButtonColor: "#00B398", 
      }).then((result) => {
        // if (result.isConfirmed) {
        //   console.log('click ok!');
        // }
      });
      

    }
  obtenerPermisos(){
    this.userData.getAccess$()
    .subscribe((data:any)=>{
    this.access = data;
    });
    return this.access;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getProducts(){
    let count = 1;
    this.dialogSpiner.openDialog();
    this.request.getProduct()
    .subscribe((data:any)=>{
      this.data = [...data.resultObject];
      this.data.forEach((element:data_element) => {
        element.item = count++;
        element.menu = element.ordenCompraId;
      });
      //console.warn(this.data);
      this.dialogSpiner.closeDialog();
    }, error => {
      this.snack.openSnackBar('Ha ocurrido un error al mostrar la información.', 'OK!');
      this.snack.snackBarRef.afterDismissed().subscribe(() => {
        this.dialogSpiner.closeDialog();
        //console.log('::::::::::Error crear causal'); // KYBC.Borrar.
      });
    });
  }

  isValid() {
    return this.causalForm.controls;
  }

  submit(){
    Swal.fire({
      title: '¿Esta seguro que desea asignar este causal a este o estos productos?',
      text: 'Una vez realizada el o los producto quedara pendiente para su aprobación',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, asignar el causal',
      cancelButtonText: 'No asignar el causal',
      confirmButtonColor: "#00B398", 
      cancelButtonColor: "#FC4C02"
    }).then((result) => {
      if (result.isConfirmed) {

      this.dialogSpiner.openDialog();
      let data = this.causalForm.value;
      //console.warn(this.causalForm.controls['causalId'].value);
      
      this.request.save(data)
      .subscribe((response:any) => {
        //console.warn(response);
        //this.getProducts();


        
        Swal.fire(
          {
            icon: (response.logycaStatus == 0 ? 'success':'error'),
            title: (response.logycaStatus == 0  ? 'Causal asignada!':'Algo no esta bien'),
            text: response.resultMessage,
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
          }
        )

          if(response.logycaStatus == 0){
            this.notificationsApiService.InsertarNotificaciones('Asginación de Causal','el causal ha sido asginado correctamente', 'product/asigns');
          }
          else{
            this.snack.openSnackBar('Ha ocurrido un error al asignar el causal a los productos, favor verificar.', 'OK!');
            this.notificationsApiService.InsertarNotificaciones('Error al asignar el causal a los productos','La asignación del causal no puede ser realizada', 'product/asigns');
          }
          this.causalForm.reset();
          window.location.reload();
          //this.filterAdv(this.filterEvent);

        }, error => {
          this.snack.openSnackBar('Ha ocurrido un error al guardar la información.', 'OK!');
          this.snack.snackBarRef.afterDismissed().subscribe(() => {
            this.dialogSpiner.closeDialog();
          });
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          {
            icon: 'error',
            title: "Cancelado",
            text: "Causal no asignada",
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        })
      }
      
    });
  }

  filterAdv(event:any){
    this.filterEvent = event;
    this.dialogSpiner.openDialog();
    event = JSON.stringify(event);
    //console.log(event);
    let count = 1;
    this.request.getFilter(event)
    .subscribe(resultData =>{
      if(resultData.resultObject != null){
        this.data = [...resultData.resultObject];
          this.data.forEach((element:data_element) => {
            element.item = count++;
          });
          this.dialogSpiner.closeDialog();
        }else{
          this.dialogSpiner.closeDialog();
        }
    });
  }

  itemsSelected(event:any){
    this.itemsSelectRow = event;
    this.causalForm.controls['detalleOrdenesCompra'].setValue(event);
  }

  selectionChanged(values: any) {
    this.causalForm.controls['causalId'].setValue(values);
    this.selectCausales.forEach((element:any) => {
      if(element.id == values){
        this.responsable = element.responsable;
      }
    });

  }

  CheckODCAlert(){
    let count = 1;
    const today = new Date();
    today.setDate(today.getDate() + 5);
    this.request.getAlertEarly('{"items":[{"column":"fechaMaximaEntrega","criteria":"=","value":"'+formatDate(today,'yyyy-MM-dd','en-US')+'"}, {"column":"fechaCausal","criteria":"is null","value":""}],"condition":"And"}', 'asignarCausal')
    .subscribe((data:any)=>{
      if(data.resultObject.length > 0){
        data.resultObject.forEach((element:any) => {
            this.dataCheckODCAlert += '<br/><b>Orden de Compra No.: </b>' + element.orden + ' productos: '+ element.productoId+', ';
        });

        var n=this.dataCheckODCAlert.lastIndexOf(",");
        this.dataCheckODCAlert =this.dataCheckODCAlert.substring(0,n) 

        Swal.fire(
          {
            icon: 'warning',
            title: "Ordenes de Compra por Vencerse en 5 Dias",
            html: this.dataCheckODCAlert,
            confirmButtonText: "OK",
            confirmButtonColor: "#00B398", 
        });
      }
    });
  }

}

export interface data_element {
  item: number,
  ordenCompraId: number,
  productoId: number,
  otif: number,
  observacionCausal: string,
  fechaCreacion: Date
  menu:any
}


