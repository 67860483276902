import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-search-drop-down',
  templateUrl: './search-drop-down.component.html',
  styleUrls: ['./search-drop-down.component.css']
})
export class SearchDropDownComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: any;


  @Output() selectedText = new EventEmitter<any>();

  @Output() selectionChange = new EventEmitter<any>();

  dataSource: any;

  public dataCtrl: FormControl = new FormControl();

  public dataFilterCtrl: FormControl = new FormControl();

  public filteredData: ReplaySubject<data_element_select[]> = new ReplaySubject<data_element_select[]>(1);

  @ViewChild('singleSelect') singleSelect!: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor() {

  }

  ngOnInit() {
  }

  cargarDatos(values: any) {
    if (values != undefined) {
      this.dataSource = [...values];


      this.dataCtrl.setValue(this.dataSource[0]);

      this.filteredData.next(this.dataSource.slice());
      this.dataFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterData();
        });
    }

  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes) {
      this.cargarDatos(changes.data.currentValue);
    }
  }

  onChange(event: any) {
    this.selectionChange.emit(event);
  }

  protected setInitialValue() {
  }

  protected filterData() {
    if (!this.dataSource) {
      return;
    }
    let search = this.dataFilterCtrl.value;
    if (!search) {
      this.filteredData.next(this.dataSource.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredData.next(
      this.dataSource.filter((item: any) => item.name.toLowerCase().indexOf(search) > -1)
    );

  }

}

export interface data_element_select {
  value: number,
  name: string
}

